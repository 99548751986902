@import "scss-variables";

@import "../node_modules/@solana/wallet-adapter-react-ui/styles.css";
@import "../node_modules/react-toastify/dist/ReactToastify.css";

@font-face {
  font-family: "Riffic";
  src: url("https://cdn.piratesquadnft.com/hub/fonts/riffic/RifficFree-Bold.ttf");
}

@font-face {
  font-family: "Baloo Thambi";
  src: url("https://cdn.piratesquadnft.com/hub/fonts/baloo-thambi/BalooThambi2-VariableFont_wght.ttf");
}

html {
  scroll-behavior: smooth;
}

body {
  display: flex;
  justify-content: center;
  margin: 0;
  background-color: $main-bg-color;
  color: $text-color;
}

#react {
  width: 100vw;
  height: 100vh;
}

:root {
  --sm-breakpoint: 768px;
}

:disabled {
  cursor: not-allowed;
}

* {
  scrollbar-width: thin;
  scrollbar-color: #d4d4d4 transparent;
}

/* Works on Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

*::-webkit-scrollbar-track {
  background: transparent;
}

*::-webkit-scrollbar-thumb {
  background-color: #d4d4d4;
  border-radius: 6px;
}

.show-element {
  animation: pulse 1s;
  animation-iteration-count: 3;
}

@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 rgba(white, 0.7);
  }

  100% {
    box-shadow: 0 0 0 10px rgba(white, 0);
  }
}

.overflow-hidden {
  overflow: hidden;
}

.phone-without-padding {
  @media screen and (max-width: $sm-breakpoint) {
    padding: 0 !important;
  }
}

.text-info {
  color: $color-info;
}
.text-danger {
  color: $color-danger;
}
.text-success {
  color: $color-success;
}
.text-warning {
  color: $color-warning;
}

// Flip

.tick-flip-panel {
  background-color: $text-color !important;
  color: $color-gray !important;
}

.tick-group {
  margin: 0 0.5em;
  text-align: center;
}

.transition {
  &-enter {
    opacity: 0;
  }
  &-enter-active {
    opacity: 1;
    transition: opacity 200ms;
  }
  &-exit {
    opacity: 1;
  }
  &-exit-active {
    opacity: 0;
    transition: opacity 200ms;
  }
}
