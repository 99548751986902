@import "https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&display=swap";
.wallet-adapter-button {
  color: #fff;
  cursor: pointer;
  height: 48px;
  background-color: #0000;
  border: none;
  border-radius: 4px;
  align-items: center;
  padding: 0 24px;
  font-family: DM Sans, Roboto, Helvetica Neue, Helvetica, Arial, sans-serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 48px;
  display: flex;
}

.wallet-adapter-button-trigger {
  background-color: #512da8;
}

.wallet-adapter-button:not([disabled]):focus-visible {
  outline-color: #fff;
}

.wallet-adapter-button:not([disabled]):hover {
  background-color: #1a1f2e;
}

.wallet-adapter-button[disabled] {
  color: #999;
  cursor: not-allowed;
  background: #404144;
}

.wallet-adapter-button-end-icon, .wallet-adapter-button-start-icon, .wallet-adapter-button-end-icon img, .wallet-adapter-button-start-icon img {
  width: 24px;
  height: 24px;
  justify-content: center;
  align-items: center;
  display: flex;
}

.wallet-adapter-button-end-icon {
  margin-left: 12px;
}

.wallet-adapter-button-start-icon {
  margin-right: 12px;
}

.wallet-adapter-collapse {
  width: 100%;
}

.wallet-adapter-dropdown {
  display: inline-block;
  position: relative;
}

.wallet-adapter-dropdown-list {
  z-index: 99;
  grid-row-gap: 10px;
  opacity: 0;
  visibility: hidden;
  background: #2c2d30;
  border-radius: 10px;
  grid-template-rows: 1fr;
  margin: 0;
  padding: 10px;
  font-family: DM Sans, Roboto, Helvetica Neue, Helvetica, Arial, sans-serif;
  list-style: none;
  transition: opacity .2s, transform .2s, visibility .2s;
  display: grid;
  position: absolute;
  top: 100%;
  right: 0;
  box-shadow: 0 8px 20px #0009;
}

.wallet-adapter-dropdown-list-active {
  opacity: 1;
  visibility: visible;
  transform: translateY(10px);
}

.wallet-adapter-dropdown-list-item {
  cursor: pointer;
  white-space: nowrap;
  box-sizing: border-box;
  width: 100%;
  height: 37px;
  color: #fff;
  border: none;
  border-radius: 6px;
  outline: none;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0 20px;
  font-size: 14px;
  font-weight: 600;
  display: flex;
}

.wallet-adapter-dropdown-list-item:not([disabled]):hover {
  background-color: #1a1f2e;
}

.wallet-adapter-modal-collapse-button svg {
  fill: #999;
  align-self: center;
}

.wallet-adapter-modal-collapse-button.wallet-adapter-modal-collapse-button-active svg {
  transition: transform .15s ease-in;
  transform: rotate(180deg);
}

.wallet-adapter-modal {
  opacity: 0;
  z-index: 1040;
  background: #00000080;
  transition: opacity .15s linear;
  position: fixed;
  inset: 0;
  overflow-y: auto;
}

.wallet-adapter-modal.wallet-adapter-modal-fade-in {
  opacity: 1;
}

.wallet-adapter-modal-button-close {
  cursor: pointer;
  background: #1a1f2e;
  border: none;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  padding: 12px;
  display: flex;
  position: absolute;
  top: 18px;
  right: 18px;
}

.wallet-adapter-modal-button-close:focus-visible {
  outline-color: #fff;
}

.wallet-adapter-modal-button-close svg {
  fill: #777;
  transition: fill .2s;
}

.wallet-adapter-modal-button-close:hover svg {
  fill: #fff;
}

.wallet-adapter-modal-overlay {
  background: #00000080;
  position: fixed;
  inset: 0;
}

.wallet-adapter-modal-container {
  min-height: calc(100vh - 6rem);
  justify-content: center;
  align-items: center;
  margin: 3rem;
  display: flex;
}

@media (max-width: 480px) {
  .wallet-adapter-modal-container {
    min-height: calc(100vh - 2rem);
    margin: 1rem;
  }
}

.wallet-adapter-modal-wrapper {
  box-sizing: border-box;
  z-index: 1050;
  max-width: 400px;
  background: #10141f;
  border-radius: 10px;
  flex-direction: column;
  flex: 1;
  align-items: center;
  font-family: DM Sans, Roboto, Helvetica Neue, Helvetica, Arial, sans-serif;
  display: flex;
  position: relative;
  box-shadow: 0 8px 20px #0009;
}

.wallet-adapter-modal-wrapper .wallet-adapter-button {
  width: 100%;
}

.wallet-adapter-modal-title {
  text-align: center;
  color: #fff;
  margin: 0;
  padding: 64px 48px 48px;
  font-size: 24px;
  font-weight: 500;
  line-height: 36px;
}

@media (max-width: 374px) {
  .wallet-adapter-modal-title {
    font-size: 18px;
  }
}

.wallet-adapter-modal-list {
  width: 100%;
  margin: 0 0 12px;
  padding: 0;
  list-style: none;
}

.wallet-adapter-modal-list .wallet-adapter-button {
  border-radius: 0;
  font-size: 18px;
  font-weight: 400;
}

.wallet-adapter-modal-list .wallet-adapter-button-end-icon, .wallet-adapter-modal-list .wallet-adapter-button-start-icon, .wallet-adapter-modal-list .wallet-adapter-button-end-icon img, .wallet-adapter-modal-list .wallet-adapter-button-start-icon img {
  width: 28px;
  height: 28px;
}

.wallet-adapter-modal-list .wallet-adapter-button span {
  opacity: .6;
  margin-left: auto;
  font-size: 14px;
}

.wallet-adapter-modal-list-more {
  cursor: pointer;
  color: #fff;
  background-color: #0000;
  border: none;
  align-self: flex-end;
  align-items: center;
  padding: 12px 24px 24px 12px;
  display: flex;
}

.wallet-adapter-modal-list-more svg {
  fill: #fff;
  margin-left: .5rem;
  transition: all .1s;
}

.wallet-adapter-modal-list-more-icon-rotate {
  transform: rotate(180deg);
}

.wallet-adapter-modal-middle {
  width: 100%;
  box-sizing: border-box;
  flex-direction: column;
  align-items: center;
  padding: 0 24px 24px;
  display: flex;
}

.wallet-adapter-modal-middle-button {
  cursor: pointer;
  width: 100%;
  color: #fff;
  background-color: #512da8;
  border: none;
  border-radius: 8px;
  margin-top: 48px;
  padding: 12px;
  font-size: 18px;
  display: block;
}

:root {
  --toastify-color-light: #fff;
  --toastify-color-dark: #121212;
  --toastify-color-info: #3498db;
  --toastify-color-success: #07bc0c;
  --toastify-color-warning: #f1c40f;
  --toastify-color-error: #e74c3c;
  --toastify-color-transparent: #ffffffb3;
  --toastify-icon-color-info: var(--toastify-color-info);
  --toastify-icon-color-success: var(--toastify-color-success);
  --toastify-icon-color-warning: var(--toastify-color-warning);
  --toastify-icon-color-error: var(--toastify-color-error);
  --toastify-toast-width: 320px;
  --toastify-toast-background: #fff;
  --toastify-toast-min-height: 64px;
  --toastify-toast-max-height: 800px;
  --toastify-font-family: sans-serif;
  --toastify-z-index: 9999;
  --toastify-text-color-light: #757575;
  --toastify-text-color-dark: #fff;
  --toastify-text-color-info: #fff;
  --toastify-text-color-success: #fff;
  --toastify-text-color-warning: #fff;
  --toastify-text-color-error: #fff;
  --toastify-spinner-color: #616161;
  --toastify-spinner-color-empty-area: #e0e0e0;
  --toastify-color-progress-light: linear-gradient(to right, #4cd964, #5ac8fa, #007aff, #34aadc, #5856d6, #ff2d55);
  --toastify-color-progress-dark: #bb86fc;
  --toastify-color-progress-info: var(--toastify-color-info);
  --toastify-color-progress-success: var(--toastify-color-success);
  --toastify-color-progress-warning: var(--toastify-color-warning);
  --toastify-color-progress-error: var(--toastify-color-error);
}

.Toastify__toast-container {
  z-index: var(--toastify-z-index);
  -webkit-transform: translate3d(0, 0, var(--toastify-z-index) px);
  width: var(--toastify-toast-width);
  box-sizing: border-box;
  color: #fff;
  padding: 4px;
  position: fixed;
}

.Toastify__toast-container--top-left {
  top: 1em;
  left: 1em;
}

.Toastify__toast-container--top-center {
  top: 1em;
  left: 50%;
  transform: translateX(-50%);
}

.Toastify__toast-container--top-right {
  top: 1em;
  right: 1em;
}

.Toastify__toast-container--bottom-left {
  bottom: 1em;
  left: 1em;
}

.Toastify__toast-container--bottom-center {
  bottom: 1em;
  left: 50%;
  transform: translateX(-50%);
}

.Toastify__toast-container--bottom-right {
  bottom: 1em;
  right: 1em;
}

@media only screen and (max-width: 480px) {
  .Toastify__toast-container {
    width: 100vw;
    margin: 0;
    padding: 0;
    left: 0;
  }

  .Toastify__toast-container--top-left, .Toastify__toast-container--top-center, .Toastify__toast-container--top-right {
    top: 0;
    transform: translateX(0);
  }

  .Toastify__toast-container--bottom-left, .Toastify__toast-container--bottom-center, .Toastify__toast-container--bottom-right {
    bottom: 0;
    transform: translateX(0);
  }

  .Toastify__toast-container--rtl {
    right: 0;
    left: initial;
  }
}

.Toastify__toast {
  min-height: var(--toastify-toast-min-height);
  box-sizing: border-box;
  max-height: var(--toastify-toast-max-height);
  font-family: var(--toastify-font-family);
  cursor: default;
  direction: ltr;
  z-index: 0;
  border-radius: 4px;
  justify-content: space-between;
  margin-bottom: 1rem;
  padding: 8px;
  display: flex;
  position: relative;
  overflow: hidden;
  box-shadow: 0 1px 10px #0000001a, 0 2px 15px #0000000d;
}

.Toastify__toast--rtl {
  direction: rtl;
}

.Toastify__toast--close-on-click {
  cursor: pointer;
}

.Toastify__toast-body {
  flex: auto;
  align-items: center;
  margin: auto 0;
  padding: 6px;
  display: flex;
}

.Toastify__toast-body > div:last-child {
  word-break: break-word;
  flex: 1;
}

.Toastify__toast-icon {
  -webkit-margin-end: 10px;
  width: 20px;
  flex-shrink: 0;
  margin-inline-end: 10px;
  display: flex;
}

.Toastify--animate {
  animation-duration: .7s;
  animation-fill-mode: both;
}

.Toastify--animate-icon {
  animation-duration: .3s;
  animation-fill-mode: both;
}

@media only screen and (max-width: 480px) {
  .Toastify__toast {
    border-radius: 0;
    margin-bottom: 0;
  }
}

.Toastify__toast-theme--dark {
  background: var(--toastify-color-dark);
  color: var(--toastify-text-color-dark);
}

.Toastify__toast-theme--light, .Toastify__toast-theme--colored.Toastify__toast--default {
  background: var(--toastify-color-light);
  color: var(--toastify-text-color-light);
}

.Toastify__toast-theme--colored.Toastify__toast--info {
  color: var(--toastify-text-color-info);
  background: var(--toastify-color-info);
}

.Toastify__toast-theme--colored.Toastify__toast--success {
  color: var(--toastify-text-color-success);
  background: var(--toastify-color-success);
}

.Toastify__toast-theme--colored.Toastify__toast--warning {
  color: var(--toastify-text-color-warning);
  background: var(--toastify-color-warning);
}

.Toastify__toast-theme--colored.Toastify__toast--error {
  color: var(--toastify-text-color-error);
  background: var(--toastify-color-error);
}

.Toastify__progress-bar-theme--light {
  background: var(--toastify-color-progress-light);
}

.Toastify__progress-bar-theme--dark {
  background: var(--toastify-color-progress-dark);
}

.Toastify__progress-bar--info {
  background: var(--toastify-color-progress-info);
}

.Toastify__progress-bar--success {
  background: var(--toastify-color-progress-success);
}

.Toastify__progress-bar--warning {
  background: var(--toastify-color-progress-warning);
}

.Toastify__progress-bar--error {
  background: var(--toastify-color-progress-error);
}

.Toastify__progress-bar-theme--colored.Toastify__progress-bar--info, .Toastify__progress-bar-theme--colored.Toastify__progress-bar--success, .Toastify__progress-bar-theme--colored.Toastify__progress-bar--warning, .Toastify__progress-bar-theme--colored.Toastify__progress-bar--error {
  background: var(--toastify-color-transparent);
}

.Toastify__close-button {
  color: #fff;
  cursor: pointer;
  opacity: .7;
  background: none;
  border: none;
  outline: none;
  align-self: flex-start;
  padding: 0;
  transition: all .3s;
}

.Toastify__close-button--light {
  color: #000;
  opacity: .3;
}

.Toastify__close-button > svg {
  fill: currentColor;
  height: 16px;
  width: 14px;
}

.Toastify__close-button:hover, .Toastify__close-button:focus {
  opacity: 1;
}

@keyframes Toastify__trackProgress {
  0% {
    transform: scaleX(1);
  }

  100% {
    transform: scaleX(0);
  }
}

.Toastify__progress-bar {
  width: 100%;
  height: 5px;
  z-index: var(--toastify-z-index);
  opacity: .7;
  transform-origin: 0;
  position: absolute;
  bottom: 0;
  left: 0;
}

.Toastify__progress-bar--animated {
  animation: linear forwards Toastify__trackProgress;
}

.Toastify__progress-bar--controlled {
  transition: transform .2s;
}

.Toastify__progress-bar--rtl {
  right: 0;
  left: initial;
  transform-origin: 100%;
}

.Toastify__spinner {
  width: 20px;
  height: 20px;
  box-sizing: border-box;
  border: 2px solid;
  border-color: var(--toastify-spinner-color-empty-area);
  border-right-color: var(--toastify-spinner-color);
  border-radius: 100%;
  animation: .65s linear infinite Toastify__spin;
}

@keyframes Toastify__bounceInRight {
  from, 60%, 75%, 90%, to {
    animation-timing-function: cubic-bezier(.215, .61, .355, 1);
  }

  from {
    opacity: 0;
    transform: translate3d(3000px, 0, 0);
  }

  60% {
    opacity: 1;
    transform: translate3d(-25px, 0, 0);
  }

  75% {
    transform: translate3d(10px, 0, 0);
  }

  90% {
    transform: translate3d(-5px, 0, 0);
  }

  to {
    transform: none;
  }
}

@keyframes Toastify__bounceOutRight {
  20% {
    opacity: 1;
    transform: translate3d(-20px, 0, 0);
  }

  to {
    opacity: 0;
    transform: translate3d(2000px, 0, 0);
  }
}

@keyframes Toastify__bounceInLeft {
  from, 60%, 75%, 90%, to {
    animation-timing-function: cubic-bezier(.215, .61, .355, 1);
  }

  0% {
    opacity: 0;
    transform: translate3d(-3000px, 0, 0);
  }

  60% {
    opacity: 1;
    transform: translate3d(25px, 0, 0);
  }

  75% {
    transform: translate3d(-10px, 0, 0);
  }

  90% {
    transform: translate3d(5px, 0, 0);
  }

  to {
    transform: none;
  }
}

@keyframes Toastify__bounceOutLeft {
  20% {
    opacity: 1;
    transform: translate3d(20px, 0, 0);
  }

  to {
    opacity: 0;
    transform: translate3d(-2000px, 0, 0);
  }
}

@keyframes Toastify__bounceInUp {
  from, 60%, 75%, 90%, to {
    animation-timing-function: cubic-bezier(.215, .61, .355, 1);
  }

  from {
    opacity: 0;
    transform: translate3d(0, 3000px, 0);
  }

  60% {
    opacity: 1;
    transform: translate3d(0, -20px, 0);
  }

  75% {
    transform: translate3d(0, 10px, 0);
  }

  90% {
    transform: translate3d(0, -5px, 0);
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}

@keyframes Toastify__bounceOutUp {
  20% {
    transform: translate3d(0, -10px, 0);
  }

  40%, 45% {
    opacity: 1;
    transform: translate3d(0, 20px, 0);
  }

  to {
    opacity: 0;
    transform: translate3d(0, -2000px, 0);
  }
}

@keyframes Toastify__bounceInDown {
  from, 60%, 75%, 90%, to {
    animation-timing-function: cubic-bezier(.215, .61, .355, 1);
  }

  0% {
    opacity: 0;
    transform: translate3d(0, -3000px, 0);
  }

  60% {
    opacity: 1;
    transform: translate3d(0, 25px, 0);
  }

  75% {
    transform: translate3d(0, -10px, 0);
  }

  90% {
    transform: translate3d(0, 5px, 0);
  }

  to {
    transform: none;
  }
}

@keyframes Toastify__bounceOutDown {
  20% {
    transform: translate3d(0, 10px, 0);
  }

  40%, 45% {
    opacity: 1;
    transform: translate3d(0, -20px, 0);
  }

  to {
    opacity: 0;
    transform: translate3d(0, 2000px, 0);
  }
}

.Toastify__bounce-enter--top-left, .Toastify__bounce-enter--bottom-left {
  animation-name: Toastify__bounceInLeft;
}

.Toastify__bounce-enter--top-right, .Toastify__bounce-enter--bottom-right {
  animation-name: Toastify__bounceInRight;
}

.Toastify__bounce-enter--top-center {
  animation-name: Toastify__bounceInDown;
}

.Toastify__bounce-enter--bottom-center {
  animation-name: Toastify__bounceInUp;
}

.Toastify__bounce-exit--top-left, .Toastify__bounce-exit--bottom-left {
  animation-name: Toastify__bounceOutLeft;
}

.Toastify__bounce-exit--top-right, .Toastify__bounce-exit--bottom-right {
  animation-name: Toastify__bounceOutRight;
}

.Toastify__bounce-exit--top-center {
  animation-name: Toastify__bounceOutUp;
}

.Toastify__bounce-exit--bottom-center {
  animation-name: Toastify__bounceOutDown;
}

@keyframes Toastify__zoomIn {
  from {
    opacity: 0;
    transform: scale3d(.3, .3, .3);
  }

  50% {
    opacity: 1;
  }
}

@keyframes Toastify__zoomOut {
  from {
    opacity: 1;
  }

  50% {
    opacity: 0;
    transform: scale3d(.3, .3, .3);
  }

  to {
    opacity: 0;
  }
}

.Toastify__zoom-enter {
  animation-name: Toastify__zoomIn;
}

.Toastify__zoom-exit {
  animation-name: Toastify__zoomOut;
}

@keyframes Toastify__flipIn {
  from {
    opacity: 0;
    animation-timing-function: ease-in;
    transform: perspective(400px)rotate3d(1, 0, 0, 90deg);
  }

  40% {
    animation-timing-function: ease-in;
    transform: perspective(400px)rotate3d(1, 0, 0, -20deg);
  }

  60% {
    opacity: 1;
    transform: perspective(400px)rotate3d(1, 0, 0, 10deg);
  }

  80% {
    transform: perspective(400px)rotate3d(1, 0, 0, -5deg);
  }

  to {
    transform: perspective(400px);
  }
}

@keyframes Toastify__flipOut {
  from {
    transform: perspective(400px);
  }

  30% {
    opacity: 1;
    transform: perspective(400px)rotate3d(1, 0, 0, -20deg);
  }

  to {
    opacity: 0;
    transform: perspective(400px)rotate3d(1, 0, 0, 90deg);
  }
}

.Toastify__flip-enter {
  animation-name: Toastify__flipIn;
}

.Toastify__flip-exit {
  animation-name: Toastify__flipOut;
}

@keyframes Toastify__slideInRight {
  from {
    visibility: visible;
    transform: translate3d(110%, 0, 0);
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}

@keyframes Toastify__slideInLeft {
  from {
    visibility: visible;
    transform: translate3d(-110%, 0, 0);
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}

@keyframes Toastify__slideInUp {
  from {
    visibility: visible;
    transform: translate3d(0, 110%, 0);
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}

@keyframes Toastify__slideInDown {
  from {
    visibility: visible;
    transform: translate3d(0, -110%, 0);
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}

@keyframes Toastify__slideOutRight {
  from {
    transform: translate3d(0, 0, 0);
  }

  to {
    visibility: hidden;
    transform: translate3d(110%, 0, 0);
  }
}

@keyframes Toastify__slideOutLeft {
  from {
    transform: translate3d(0, 0, 0);
  }

  to {
    visibility: hidden;
    transform: translate3d(-110%, 0, 0);
  }
}

@keyframes Toastify__slideOutDown {
  from {
    transform: translate3d(0, 0, 0);
  }

  to {
    visibility: hidden;
    transform: translate3d(0, 500px, 0);
  }
}

@keyframes Toastify__slideOutUp {
  from {
    transform: translate3d(0, 0, 0);
  }

  to {
    visibility: hidden;
    transform: translate3d(0, -500px, 0);
  }
}

.Toastify__slide-enter--top-left, .Toastify__slide-enter--bottom-left {
  animation-name: Toastify__slideInLeft;
}

.Toastify__slide-enter--top-right, .Toastify__slide-enter--bottom-right {
  animation-name: Toastify__slideInRight;
}

.Toastify__slide-enter--top-center {
  animation-name: Toastify__slideInDown;
}

.Toastify__slide-enter--bottom-center {
  animation-name: Toastify__slideInUp;
}

.Toastify__slide-exit--top-left, .Toastify__slide-exit--bottom-left {
  animation-name: Toastify__slideOutLeft;
}

.Toastify__slide-exit--top-right, .Toastify__slide-exit--bottom-right {
  animation-name: Toastify__slideOutRight;
}

.Toastify__slide-exit--top-center {
  animation-name: Toastify__slideOutUp;
}

.Toastify__slide-exit--bottom-center {
  animation-name: Toastify__slideOutDown;
}

@keyframes Toastify__spin {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@font-face {
  font-family: Riffic;
  src: url("https://cdn.piratesquadnft.com/hub/fonts/riffic/RifficFree-Bold.ttf");
}

@font-face {
  font-family: Baloo Thambi;
  src: url("https://cdn.piratesquadnft.com/hub/fonts/baloo-thambi/BalooThambi2-VariableFont_wght.ttf");
}

html {
  scroll-behavior: smooth;
}

body {
  color: #e8e8e8;
  background-color: #1f1f1f;
  justify-content: center;
  margin: 0;
  display: flex;
}

#react {
  width: 100vw;
  height: 100vh;
}

:root {
  --sm-breakpoint: 768px;
}

:disabled {
  cursor: not-allowed;
}

* {
  scrollbar-width: thin;
  scrollbar-color: #d4d4d4 transparent;
}

::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

::-webkit-scrollbar-track {
  background: none;
}

::-webkit-scrollbar-thumb {
  background-color: #d4d4d4;
  border-radius: 6px;
}

.show-element {
  animation: 1s 3 pulse;
}

@keyframes pulse {
  0% {
    box-shadow: 0 0 #ffffffb3;
  }

  100% {
    box-shadow: 0 0 0 10px #fff0;
  }
}

.overflow-hidden {
  overflow: hidden;
}

@media screen and (max-width: 768px) {
  .phone-without-padding {
    padding: 0 !important;
  }
}

.text-info {
  color: #3bb8fa;
}

.text-danger {
  color: #ff4e5f;
}

.text-success {
  color: #2ed573;
}

.text-warning {
  color: #ce8605;
}

.tick-flip-panel {
  color: #2f2f2f !important;
  background-color: #e8e8e8 !important;
}

.tick-group {
  text-align: center;
  margin: 0 .5em;
}

.transition-enter {
  opacity: 0;
}

.transition-enter-active {
  opacity: 1;
  transition: opacity .2s;
}

.transition-exit {
  opacity: 1;
}

.transition-exit-active {
  opacity: 0;
  transition: opacity .2s;
}

.FtH_eW_button {
  color: #fff;
  height: 2.5rem;
  background-color: #1e90ff;
  border: none;
  border-radius: .5rem;
  justify-content: center;
  align-items: center;
  padding: .5rem 1rem;
  font-family: Baloo Thambi;
  font-size: 1rem;
  font-weight: 500;
  display: flex;
  position: relative;
}

.FtH_eW_button:hover:not(:disabled), .FtH_eW_button.FtH_eW_selected {
  cursor: pointer;
  background-color: #0077ea;
}

.FtH_eW_button:disabled {
  cursor: not-allowed;
  color: #fffa;
  background-color: #999a;
}

.FtH_eW_button:disabled svg {
  color: #3333;
}

.FtH_eW_button.FtH_eW_loading {
  cursor: not-allowed;
  pointer-events: none;
}

.FtH_eW_button.FtH_eW_gray {
  color: #fff;
  background-color: #666;
}

.FtH_eW_button.FtH_eW_gray:hover:not(:disabled), .FtH_eW_button.FtH_eW_gray.FtH_eW_selected {
  background-color: #4d4d4d;
}

.FtH_eW_button.FtH_eW_success {
  color: #fff;
  background-color: #2ed573;
}

.FtH_eW_button.FtH_eW_success:hover:not(:disabled), .FtH_eW_button.FtH_eW_success.FtH_eW_selected {
  background-color: #23ad5c;
}

.FtH_eW_button.FtH_eW_warning {
  color: #fff;
  background-color: #ce8605;
}

.FtH_eW_button.FtH_eW_warning:hover:not(:disabled), .FtH_eW_button.FtH_eW_warning.FtH_eW_selected {
  background-color: #9c6604;
}

.FtH_eW_button.FtH_eW_no-color {
  color: #e8e8e8;
  background-color: #0000;
}

.FtH_eW_button.FtH_eW_no-color:hover:not(:disabled), .FtH_eW_button.FtH_eW_no-color.FtH_eW_selected {
  background-color: #343434;
}

.FtH_eW_button.FtH_eW_no-color:disabled {
  cursor: not-allowed;
  color: #aaa !important;
}

.FtH_eW_button.FtH_eW_no-color:disabled svg {
  color: #aaa !important;
}

.FtH_eW_button.FtH_eW_no-padding {
  padding: 0;
}

.FtH_eW_button.FtH_eW_icon {
  width: auto;
  gap: 1rem;
}

.FtH_eW_button .FtH_eW_content {
  align-items: center;
  gap: .5rem;
  display: flex;
}

.FtH_eW_button .FtH_eW_content.FtH_eW_floating svg {
  margin: auto;
  position: absolute;
  inset: 0;
}

.FtH_eW_button .FtH_eW_content.FtH_eW_floating .FtH_eW_children {
  visibility: hidden;
}

.Cqapla_input {
  box-sizing: border-box;
  outline-offset: -1px;
  color: #e8e8e8;
  background-color: #3f3f3f;
  border: none;
  border-radius: .5rem;
  justify-content: center;
  align-items: center;
  padding: .5rem 1rem;
  font-family: Baloo Thambi;
  font-size: 1rem;
  display: flex;
}

.Cqapla_input::placeholder {
  font-size: .9em;
}

.Cqapla_input:focus {
  outline: 1px solid #1e90ff;
}

.B9uqDG_modal {
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  z-index: 500;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2rem;
  font-family: Riffic;
  font-size: 24px;
  display: flex;
  position: fixed;
  inset: 0;
}

.B9uqDG_modal .B9uqDG_background {
  z-index: 21;
  position: absolute;
  inset: 0;
}

.B9uqDG_modal .B9uqDG_container {
  z-index: 22;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  display: flex;
}

.B9uqDG_modal .B9uqDG_container.B9uqDG_full-size {
  width: 100%;
  height: 100%;
}

.B9uqDG_modal .B9uqDG_container.B9uqDG_image img {
  width: 50%;
  max-width: 300px;
}

.B9uqDG_modal.B9uqDG_no-blur {
  -webkit-backdrop-filter: none;
  backdrop-filter: none;
}

@media screen and (min-width: 768px) {
  .B9uqDG_modal {
    font-size: 32px;
  }
}

.B9uqDG_modal.B9uqDG_loading-modal {
  z-index: 19;
}

.B9uqDG_modal .B9uqDG_loading-content {
  color: #fff;
  text-shadow: 4px 3px #444;
  text-align: center;
  z-index: 20;
}

.B9uqDG_modal .B9uqDG_content {
  width: 80%;
  height: -moz-fit-content;
  height: fit-content;
  max-height: 400px;
  max-width: 480px;
  word-wrap: break-word;
  background-color: #2f2f2f;
  border-radius: 1rem;
  flex-direction: column;
  font-family: Baloo Thambi;
  font-size: 22px;
  display: flex;
  box-shadow: 0 8px 24px #000;
}

.B9uqDG_modal .B9uqDG_content .B9uqDG_title {
  text-align: center;
  border-bottom: 1px solid #444;
  padding: 1rem;
}

.B9uqDG_modal .B9uqDG_content .B9uqDG_title img, .B9uqDG_modal .B9uqDG_content .B9uqDG_title svg {
  height: 12px;
  margin: 0 .2rem;
}

.B9uqDG_modal .B9uqDG_content .B9uqDG_text {
  flex: 1;
  padding: 1rem;
  font-size: 1rem;
}

.B9uqDG_modal .B9uqDG_content .B9uqDG_text .B9uqDG_input {
  width: 100%;
}

.B9uqDG_modal .B9uqDG_content .B9uqDG_text img, .B9uqDG_modal .B9uqDG_content .B9uqDG_text svg {
  height: 10px;
  margin: 0 .2rem;
}

.B9uqDG_modal .B9uqDG_content .B9uqDG_footer {
  padding: 1rem;
  display: flex;
}

.B9uqDG_modal .B9uqDG_content .B9uqDG_footer button {
  width: 100%;
}

.B9uqDG_modal .B9uqDG_content .B9uqDG_footer button:first-child:nth-last-child(2):first-child, .B9uqDG_modal .B9uqDG_content .B9uqDG_footer button:first-child:nth-last-child(2) ~ button:first-child {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.B9uqDG_modal .B9uqDG_content .B9uqDG_footer button:first-child:nth-last-child(2):last-child, .B9uqDG_modal .B9uqDG_content .B9uqDG_footer button:first-child:nth-last-child(2) ~ button:last-child {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

._2K0JEG_hub-layout {
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  margin: auto;
  font-family: Riffic;
  display: flex;
  position: relative;
}

._2K0JEG_hub-layout ._2K0JEG_container {
  width: 100%;
  height: 100%;
  flex-direction: column;
  align-items: center;
  display: flex;
}

._2K0JEG_hub-layout ._2K0JEG_buttons {
  color: #333;
  text-align: center;
  max-width: 300px;
  flex-direction: column;
  gap: 1rem;
  display: flex;
}

._2K0JEG_hub-layout ._2K0JEG_buttons p {
  font-size: 32px;
}

._2K0JEG_hub-layout ._2K0JEG_buttons p ._2K0JEG_purple {
  color: #512da8;
}

._2K0JEG_hub-layout ._2K0JEG_buttons small {
  font-size: 18px;
}

._2K0JEG_hub-layout ._2K0JEG_custom-toast img {
  width: 10px;
  height: 10px;
}

._05yC3W_aptos-connect ._05yC3W_buttons {
  flex-direction: column;
  gap: 1rem;
  display: flex;
}

._05yC3W_aptos-connect ._05yC3W_modal {
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  color: #333;
  justify-content: center;
  align-items: center;
  display: flex;
  position: absolute;
  inset: 0;
}

._05yC3W_aptos-connect ._05yC3W_modal ._05yC3W_content {
  width: 80%;
  max-width: 480px;
  word-wrap: break-word;
  background-color: #fff;
  border-radius: 1rem;
  flex-direction: column;
  font-family: Baloo Thambi;
  font-size: 22px;
  display: flex;
  overflow: hidden;
  box-shadow: 0 8px 24px #959da533;
}

._05yC3W_aptos-connect ._05yC3W_modal ._05yC3W_content button {
  border-radius: 0;
}

._05yC3W_aptos-connect ._05yC3W_modal ._05yC3W_content button:not(:last-child) {
  border-bottom: 1px solid #fff;
}

.JdUu9W_wallet {
  height: 100%;
  justify-content: center;
  align-items: center;
  font-size: 32px;
  display: flex;
}

.JdUu9W_wallet .JdUu9W_form {
  max-width: 300px;
  text-align: center;
  flex-direction: column;
  gap: 1rem;
  margin: 4rem;
  display: flex;
}

.JdUu9W_wallet .JdUu9W_form .JdUu9W_purple {
  color: #1e90ff;
}

.JdUu9W_wallet .JdUu9W_form button {
  width: 100%;
  text-align: center;
  justify-content: center;
  font-family: Baloo Thambi;
  font-size: 18px;
  font-weight: 400;
}

.JdUu9W_wallet .JdUu9W_form .JdUu9W_change-button {
  margin-top: 1rem;
}

.JdUu9W_wallet .JdUu9W_form .JdUu9W_solana-button {
  background-color: #1e90ff;
  border-radius: .5rem;
}

.VjagbG_wallet-layout {
  width: 100%;
  height: 100%;
}

.Dwzn_W_popover-help .Dwzn_W_popover-icon {
  cursor: pointer;
  opacity: .4;
  margin-left: .25rem;
  font-size: 1rem;
}

.Dwzn_W_popover-help .Dwzn_W_popover-icon:hover {
  opacity: 1;
}

.Dwzn_W_popover-container {
  z-index: 1000;
  max-width: 300px;
}

.Dwzn_W_popover-content {
  color: #fff;
  max-width: 300px;
  background-color: #3f3f3f;
  border-radius: 1rem;
  padding: 1rem;
  font-family: Baloo Thambi;
  font-size: 14px;
  font-weight: 500;
}

.Dwzn_W_popover-content ul {
  margin: 0;
  padding: 0 1rem;
}

.Dwzn_W_popover-content img {
  height: 1em;
}

.KW2K-a_toggle {
  width: 2em;
  height: 1em;
  display: inline-block;
  position: relative;
}

.KW2K-a_toggle .KW2K-a_switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.KW2K-a_toggle .KW2K-a_switch input:checked + .KW2K-a_slider {
  background-color: #1e90ff;
}

.KW2K-a_toggle .KW2K-a_switch input:focus + .KW2K-a_slider {
  box-shadow: 0 0 1px #1e90ff;
}

.KW2K-a_toggle .KW2K-a_switch input:checked + .KW2K-a_slider:before {
  transform: translateX(1em);
}

.KW2K-a_toggle .KW2K-a_switch .KW2K-a_slider {
  cursor: pointer;
  background-color: #ccc;
  transition: all .4s;
  position: absolute;
  inset: 0;
}

.KW2K-a_toggle .KW2K-a_switch .KW2K-a_slider.KW2K-a_round {
  border-radius: 1em;
}

.KW2K-a_toggle .KW2K-a_switch .KW2K-a_slider.KW2K-a_round:before {
  border-radius: 50%;
}

.KW2K-a_toggle .KW2K-a_switch .KW2K-a_slider:before {
  content: "";
  height: .8em;
  width: .8em;
  background-color: #fff;
  transition: all .4s;
  position: absolute;
  bottom: .1em;
  left: .1em;
}

.NcH85G_abc {
  max-width: 1200px;
  -ms-overflow-style: none;
  scrollbar-width: none;
  flex-direction: column;
  flex: 1;
  gap: 1rem;
  padding: .5rem;
  display: flex;
  position: relative;
  overflow-y: auto;
}

.NcH85G_abc::-webkit-scrollbar {
  display: none;
}

@media screen and (min-width: 768px) {
  .NcH85G_abc {
    padding: 0;
  }
}

.NcH85G_abc .NcH85G_game-container {
  opacity: .9;
  background-image: url("https://cdn.piratesquadnft.com/games/abc/sky.png");
  background-position: center;
  background-size: cover;
  border-radius: .5rem;
  flex-direction: column;
  flex: none;
  padding: 1rem;
  display: flex;
  position: relative;
  overflow: hidden;
}

.NcH85G_abc .NcH85G_game-container .NcH85G_game {
  z-index: 2;
  position: relative;
}

.NcH85G_abc .NcH85G_game-container .NcH85G_game .NcH85G_loading {
  -webkit-backdrop-filter: blur(.5rem);
  backdrop-filter: blur(.5rem);
}

.NcH85G_abc .NcH85G_game-container .NcH85G_scene {
  z-index: 1;
  height: 8rem;
  transform-origin: 50% 105%;
  transition: transform 2s .5s;
  position: relative;
}

@media screen and (min-width: 480px) {
  .NcH85G_abc .NcH85G_game-container .NcH85G_scene {
    height: 12rem;
  }
}

@media screen and (min-width: 768px) {
  .NcH85G_abc .NcH85G_game-container .NcH85G_scene {
    height: 15rem;
  }
}

@media screen and (min-width: 1024px) {
  .NcH85G_abc .NcH85G_game-container .NcH85G_scene {
    height: 20rem;
  }
}

.NcH85G_abc .NcH85G_game-container .NcH85G_scene * {
  pointer-events: none;
}

.NcH85G_abc .NcH85G_game-container .NcH85G_scene.NcH85G_running-game {
  transform: scale(3)translateZ(0);
}

@media screen and (min-width: 480px) {
  .NcH85G_abc .NcH85G_game-container .NcH85G_scene.NcH85G_running-game {
    transform: scale(2.5)translateZ(0);
  }
}

@media screen and (min-width: 768px) {
  .NcH85G_abc .NcH85G_game-container .NcH85G_scene.NcH85G_running-game {
    transform: scale(2)translateZ(0);
  }
}

@media screen and (min-width: 1024px) {
  .NcH85G_abc .NcH85G_game-container .NcH85G_scene.NcH85G_running-game {
    transform: scale(1.6)translateZ(0);
  }
}

@media screen and (min-width: 1200px) {
  .NcH85G_abc .NcH85G_game-container .NcH85G_scene.NcH85G_running-game {
    transform: scale(1.4)translateZ(0);
  }
}

.NcH85G_abc .NcH85G_game-container .NcH85G_scene.NcH85G_running-game .NcH85G_pirate, .NcH85G_abc .NcH85G_game-container .NcH85G_scene.NcH85G_running-game .NcH85G_baddie, .NcH85G_abc .NcH85G_game-container .NcH85G_scene.NcH85G_running-game .NcH85G_skeleton, .NcH85G_abc .NcH85G_game-container .NcH85G_scene.NcH85G_running-game .NcH85G_moon, .NcH85G_abc .NcH85G_game-container .NcH85G_scene.NcH85G_running-game .NcH85G_boat, .NcH85G_abc .NcH85G_game-container .NcH85G_scene.NcH85G_running-game .NcH85G_whale, .NcH85G_abc .NcH85G_game-container .NcH85G_scene.NcH85G_running-game .NcH85G_wave {
  opacity: 0;
}

.NcH85G_abc .NcH85G_game-container .NcH85G_scene.NcH85G_running-game .NcH85G_letter:not(.NcH85G_selected) {
  filter: grayscale();
}

.NcH85G_abc .NcH85G_game-container .NcH85G_scene .NcH85G_whale {
  z-index: 0;
  max-height: 40%;
  max-width: 20%;
  transform-origin: center;
  transition: all 1s;
  animation: 3s infinite alternate NcH85G_whale;
  position: absolute;
  bottom: 34%;
  left: 0;
}

@keyframes NcH85G_whale {
  from {
    transform: rotateZ(-5deg);
  }

  to {
    transform: rotateZ(5deg);
  }
}

.NcH85G_abc .NcH85G_game-container .NcH85G_scene .NcH85G_wave {
  z-index: 0;
  max-width: 25%;
  max-height: 60%;
  transform-origin: center;
  transition: all 1s;
  animation: 4s infinite alternate NcH85G_wave;
  position: absolute;
  bottom: 34%;
  right: 0;
}

@keyframes NcH85G_wave {
  from {
    transform: translateY(-2%);
  }

  to {
    transform: translateY(2%);
  }
}

.NcH85G_abc .NcH85G_game-container .NcH85G_scene .NcH85G_water {
  z-index: 1;
  width: calc(100% + 18rem);
  height: calc(50% + 1rem);
  transform-origin: center;
  animation: 10s infinite alternate NcH85G_water;
  position: absolute;
  bottom: -1rem;
  left: -14rem;
}

@keyframes NcH85G_water {
  from {
    transform: translateX(-1.5%);
  }

  to {
    transform: translateX(1.5%);
  }
}

.NcH85G_abc .NcH85G_game-container .NcH85G_scene .NcH85G_boat {
  z-index: 2;
  max-width: 30%;
  max-height: 100%;
  transform-origin: 75% 75%;
  transition: all 1s;
  animation: 5s infinite alternate NcH85G_boat;
  position: absolute;
  bottom: 15%;
  right: 20%;
}

@keyframes NcH85G_boat {
  from {
    transform: rotateZ(-2.5deg);
  }

  to {
    transform: rotateZ(2.5deg);
  }
}

.NcH85G_abc .NcH85G_game-container .NcH85G_scene .NcH85G_island-absolute {
  z-index: 3;
  width: 100%;
  height: 100%;
  margin: auto;
  position: absolute;
  bottom: -1rem;
  left: 0;
  right: 0;
}

.NcH85G_abc .NcH85G_game-container .NcH85G_scene .NcH85G_island-absolute .NcH85G_island-relative {
  height: 100%;
  width: 100%;
  position: relative;
}

.NcH85G_abc .NcH85G_game-container .NcH85G_scene .NcH85G_island-absolute .NcH85G_island-relative .NcH85G_island, .NcH85G_abc .NcH85G_game-container .NcH85G_scene .NcH85G_island-absolute .NcH85G_island-relative .NcH85G_moon, .NcH85G_abc .NcH85G_game-container .NcH85G_scene .NcH85G_island-absolute .NcH85G_island-relative .NcH85G_skeleton, .NcH85G_abc .NcH85G_game-container .NcH85G_scene .NcH85G_island-absolute .NcH85G_island-relative .NcH85G_pirate, .NcH85G_abc .NcH85G_game-container .NcH85G_scene .NcH85G_island-absolute .NcH85G_island-relative .NcH85G_baddie {
  max-width: 100%;
  max-height: 100%;
  margin: auto;
  transition: all 1s;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}

.NcH85G_abc .NcH85G_game-container .NcH85G_scene .NcH85G_island-absolute .NcH85G_island-relative .NcH85G_moon {
  transform-origin: 8%;
  animation: 4s infinite NcH85G_swing;
}

@keyframes NcH85G_swing {
  50% {
    transform: rotate(0);
  }

  60% {
    transform: rotate(3deg);
  }

  70% {
    transform: rotate(-3deg);
  }

  80% {
    transform: rotate(1deg);
  }

  90% {
    transform: rotate(-1deg);
  }

  100% {
    transform: rotate(0);
  }
}

.NcH85G_abc .NcH85G_game-container .NcH85G_scene .NcH85G_island-absolute .NcH85G_island-relative .NcH85G_letters-absolute {
  height: 35%;
  width: 17%;
  margin: auto;
  position: absolute;
  bottom: 20%;
  left: 0;
  right: 0;
}

.NcH85G_abc .NcH85G_game-container .NcH85G_scene .NcH85G_island-absolute .NcH85G_island-relative .NcH85G_letters-absolute .NcH85G_letters-relative {
  height: 100%;
  justify-content: center;
  gap: 5%;
  display: flex;
  position: relative;
}

.NcH85G_abc .NcH85G_game-container .NcH85G_scene .NcH85G_island-absolute .NcH85G_island-relative .NcH85G_letters-absolute .NcH85G_letters-relative .NcH85G_letter-a, .NcH85G_abc .NcH85G_game-container .NcH85G_scene .NcH85G_island-absolute .NcH85G_island-relative .NcH85G_letters-absolute .NcH85G_letters-relative .NcH85G_letter-b, .NcH85G_abc .NcH85G_game-container .NcH85G_scene .NcH85G_island-absolute .NcH85G_island-relative .NcH85G_letters-absolute .NcH85G_letters-relative .NcH85G_letter-c {
  max-width: 33%;
  height: 100%;
  flex-direction: column;
  justify-content: end;
  gap: 10%;
  display: flex;
  position: relative;
}

.NcH85G_abc .NcH85G_game-container .NcH85G_scene .NcH85G_island-absolute .NcH85G_island-relative .NcH85G_letters-absolute .NcH85G_letters-relative .NcH85G_letter-a .NcH85G_letter-container, .NcH85G_abc .NcH85G_game-container .NcH85G_scene .NcH85G_island-absolute .NcH85G_island-relative .NcH85G_letters-absolute .NcH85G_letters-relative .NcH85G_letter-b .NcH85G_letter-container, .NcH85G_abc .NcH85G_game-container .NcH85G_scene .NcH85G_island-absolute .NcH85G_island-relative .NcH85G_letters-absolute .NcH85G_letters-relative .NcH85G_letter-c .NcH85G_letter-container {
  z-index: 1;
  transition: transform 1s 1.5s;
  display: flex;
}

.NcH85G_abc .NcH85G_game-container .NcH85G_scene .NcH85G_island-absolute .NcH85G_island-relative .NcH85G_letters-absolute .NcH85G_letters-relative .NcH85G_letter-a .NcH85G_letter-container.NcH85G_winner, .NcH85G_abc .NcH85G_game-container .NcH85G_scene .NcH85G_island-absolute .NcH85G_island-relative .NcH85G_letters-absolute .NcH85G_letters-relative .NcH85G_letter-b .NcH85G_letter-container.NcH85G_winner, .NcH85G_abc .NcH85G_game-container .NcH85G_scene .NcH85G_island-absolute .NcH85G_island-relative .NcH85G_letters-absolute .NcH85G_letters-relative .NcH85G_letter-c .NcH85G_letter-container.NcH85G_winner {
  transform: translateY(-50%);
}

.NcH85G_abc .NcH85G_game-container .NcH85G_scene .NcH85G_island-absolute .NcH85G_island-relative .NcH85G_letters-absolute .NcH85G_letters-relative .NcH85G_letter-a .NcH85G_letter-container.NcH85G_winner .NcH85G_letter, .NcH85G_abc .NcH85G_game-container .NcH85G_scene .NcH85G_island-absolute .NcH85G_island-relative .NcH85G_letters-absolute .NcH85G_letters-relative .NcH85G_letter-b .NcH85G_letter-container.NcH85G_winner .NcH85G_letter, .NcH85G_abc .NcH85G_game-container .NcH85G_scene .NcH85G_island-absolute .NcH85G_island-relative .NcH85G_letters-absolute .NcH85G_letters-relative .NcH85G_letter-c .NcH85G_letter-container.NcH85G_winner .NcH85G_letter {
  filter: none;
}

.NcH85G_abc .NcH85G_game-container .NcH85G_scene .NcH85G_island-absolute .NcH85G_island-relative .NcH85G_letters-absolute .NcH85G_letters-relative .NcH85G_letter-a .NcH85G_letter-container .NcH85G_letter, .NcH85G_abc .NcH85G_game-container .NcH85G_scene .NcH85G_island-absolute .NcH85G_island-relative .NcH85G_letters-absolute .NcH85G_letters-relative .NcH85G_letter-b .NcH85G_letter-container .NcH85G_letter, .NcH85G_abc .NcH85G_game-container .NcH85G_scene .NcH85G_island-absolute .NcH85G_island-relative .NcH85G_letters-absolute .NcH85G_letters-relative .NcH85G_letter-c .NcH85G_letter-container .NcH85G_letter {
  width: 100%;
  transition: scale 1s, filter 1s 1s;
  animation: 1s infinite NcH85G_letter;
}

.NcH85G_abc .NcH85G_game-container .NcH85G_scene .NcH85G_island-absolute .NcH85G_island-relative .NcH85G_letters-absolute .NcH85G_letters-relative .NcH85G_letter-a .NcH85G_coin, .NcH85G_abc .NcH85G_game-container .NcH85G_scene .NcH85G_island-absolute .NcH85G_island-relative .NcH85G_letters-absolute .NcH85G_letters-relative .NcH85G_letter-b .NcH85G_coin, .NcH85G_abc .NcH85G_game-container .NcH85G_scene .NcH85G_island-absolute .NcH85G_island-relative .NcH85G_letters-absolute .NcH85G_letters-relative .NcH85G_letter-c .NcH85G_coin {
  width: .1rem;
  opacity: 0;
  margin: auto;
  transition: all 1s 1s;
  position: absolute;
  bottom: 20%;
  left: 0;
  right: 0;
}

@media screen and (min-width: 480px) {
  .NcH85G_abc .NcH85G_game-container .NcH85G_scene .NcH85G_island-absolute .NcH85G_island-relative .NcH85G_letters-absolute .NcH85G_letters-relative .NcH85G_letter-a .NcH85G_coin, .NcH85G_abc .NcH85G_game-container .NcH85G_scene .NcH85G_island-absolute .NcH85G_island-relative .NcH85G_letters-absolute .NcH85G_letters-relative .NcH85G_letter-b .NcH85G_coin, .NcH85G_abc .NcH85G_game-container .NcH85G_scene .NcH85G_island-absolute .NcH85G_island-relative .NcH85G_letters-absolute .NcH85G_letters-relative .NcH85G_letter-c .NcH85G_coin {
    width: .2rem;
  }
}

@media screen and (min-width: 768px) {
  .NcH85G_abc .NcH85G_game-container .NcH85G_scene .NcH85G_island-absolute .NcH85G_island-relative .NcH85G_letters-absolute .NcH85G_letters-relative .NcH85G_letter-a .NcH85G_coin, .NcH85G_abc .NcH85G_game-container .NcH85G_scene .NcH85G_island-absolute .NcH85G_island-relative .NcH85G_letters-absolute .NcH85G_letters-relative .NcH85G_letter-b .NcH85G_coin, .NcH85G_abc .NcH85G_game-container .NcH85G_scene .NcH85G_island-absolute .NcH85G_island-relative .NcH85G_letters-absolute .NcH85G_letters-relative .NcH85G_letter-c .NcH85G_coin {
    width: .3rem;
  }
}

@media screen and (min-width: 1024px) {
  .NcH85G_abc .NcH85G_game-container .NcH85G_scene .NcH85G_island-absolute .NcH85G_island-relative .NcH85G_letters-absolute .NcH85G_letters-relative .NcH85G_letter-a .NcH85G_coin, .NcH85G_abc .NcH85G_game-container .NcH85G_scene .NcH85G_island-absolute .NcH85G_island-relative .NcH85G_letters-absolute .NcH85G_letters-relative .NcH85G_letter-b .NcH85G_coin, .NcH85G_abc .NcH85G_game-container .NcH85G_scene .NcH85G_island-absolute .NcH85G_island-relative .NcH85G_letters-absolute .NcH85G_letters-relative .NcH85G_letter-c .NcH85G_coin {
    width: .4rem;
  }
}

@media screen and (min-width: 1200px) {
  .NcH85G_abc .NcH85G_game-container .NcH85G_scene .NcH85G_island-absolute .NcH85G_island-relative .NcH85G_letters-absolute .NcH85G_letters-relative .NcH85G_letter-a .NcH85G_coin, .NcH85G_abc .NcH85G_game-container .NcH85G_scene .NcH85G_island-absolute .NcH85G_island-relative .NcH85G_letters-absolute .NcH85G_letters-relative .NcH85G_letter-b .NcH85G_coin, .NcH85G_abc .NcH85G_game-container .NcH85G_scene .NcH85G_island-absolute .NcH85G_island-relative .NcH85G_letters-absolute .NcH85G_letters-relative .NcH85G_letter-c .NcH85G_coin {
    width: .5rem;
  }
}

.NcH85G_abc .NcH85G_game-container .NcH85G_scene .NcH85G_island-absolute .NcH85G_island-relative .NcH85G_letters-absolute .NcH85G_letters-relative .NcH85G_letter-a .NcH85G_coin.NcH85G_winner, .NcH85G_abc .NcH85G_game-container .NcH85G_scene .NcH85G_island-absolute .NcH85G_island-relative .NcH85G_letters-absolute .NcH85G_letters-relative .NcH85G_letter-b .NcH85G_coin.NcH85G_winner, .NcH85G_abc .NcH85G_game-container .NcH85G_scene .NcH85G_island-absolute .NcH85G_island-relative .NcH85G_letters-absolute .NcH85G_letters-relative .NcH85G_letter-c .NcH85G_coin.NcH85G_winner {
  opacity: 1;
  filter: drop-shadow(0 .25rem .75rem #e9820c);
  transform: scale(2);
}

.NcH85G_abc .NcH85G_game-container .NcH85G_scene .NcH85G_island-absolute .NcH85G_island-relative .NcH85G_letters-absolute .NcH85G_letters-relative .NcH85G_letter-a .NcH85G_letter-container .NcH85G_letter {
  animation-duration: 3.2s;
  animation-delay: .3s;
}

.NcH85G_abc .NcH85G_game-container .NcH85G_scene .NcH85G_island-absolute .NcH85G_island-relative .NcH85G_letters-absolute .NcH85G_letters-relative .NcH85G_letter-b {
  max-width: 28%;
}

.NcH85G_abc .NcH85G_game-container .NcH85G_scene .NcH85G_island-absolute .NcH85G_island-relative .NcH85G_letters-absolute .NcH85G_letters-relative .NcH85G_letter-b .NcH85G_letter-container .NcH85G_letter {
  animation-duration: 3.5s;
  animation-delay: .5s;
}

.NcH85G_abc .NcH85G_game-container .NcH85G_scene .NcH85G_island-absolute .NcH85G_island-relative .NcH85G_letters-absolute .NcH85G_letters-relative .NcH85G_letter-c .NcH85G_letter-container .NcH85G_letter {
  animation-duration: 2.9s;
}

@keyframes NcH85G_letter {
  from, to, 20% {
    transform: scale(1);
  }

  40% {
    transform: scale(.9, 1.1);
  }

  60% {
    transform: scale(1.1, .9);
  }

  80% {
    transform: scale(.95, 1.05);
  }
}

.NcH85G_abc .NcH85G_game-container .NcH85G_users {
  color: #666;
  -webkit-backdrop-filter: blur(.5rem);
  backdrop-filter: blur(.5rem);
  box-sizing: border-box;
  z-index: 1;
  width: 100%;
  opacity: 0;
  background-color: #fffd;
  grid-template-columns: auto 1fr;
  align-items: center;
  gap: .5rem;
  padding: .5rem;
  font-size: .75rem;
  font-weight: 500;
  line-height: .75rem;
  transition: all 1s;
  display: grid;
  position: absolute;
  bottom: 0;
  left: 0;
}

@media screen and (min-width: 480px) {
  .NcH85G_abc .NcH85G_game-container .NcH85G_users {
    flex-direction: row;
    grid-template-columns: repeat(6, 1fr);
    font-size: 1rem;
    line-height: 1rem;
  }

  .NcH85G_abc .NcH85G_game-container .NcH85G_users svg {
    height: 1rem;
  }
}

.NcH85G_abc .NcH85G_game-container .NcH85G_users.NcH85G_visible {
  opacity: 1;
}

.NcH85G_abc .NcH85G_game-container .NcH85G_users svg {
  height: .75rem;
  margin: auto;
}

.NcH85G_abc .NcH85G_settings {
  border-radius: .5rem;
  grid-template-columns: 1fr;
  justify-content: space-between;
  gap: .5rem;
  padding: .5rem;
  display: grid;
}

@media screen and (min-width: 1024px) {
  .NcH85G_abc .NcH85G_settings {
    grid-template-columns: 1fr 1fr 1fr;
    gap: 1rem;
    padding: 1rem;
  }
}

.NcH85G_abc .NcH85G_settings .NcH85G_setting {
  flex-direction: column;
  gap: .5rem;
  display: flex;
}

@media screen and (min-width: 480px) {
  .NcH85G_abc .NcH85G_settings .NcH85G_setting {
    gap: 1rem;
  }
}

.NcH85G_abc .NcH85G_settings .NcH85G_setting .NcH85G_title {
  color: #666;
  font-size: 1rem;
  font-weight: 700;
}

.NcH85G_abc .NcH85G_settings .NcH85G_setting .NcH85G_content {
  flex: 1;
  align-items: end;
  display: flex;
}

.NcH85G_abc .NcH85G_settings .NcH85G_setting .NcH85G_content.NcH85G_tokens {
  justify-content: space-between;
  display: flex;
}

.NcH85G_abc .NcH85G_settings .NcH85G_setting .NcH85G_content.NcH85G_tokens .NcH85G_token {
  height: 3rem;
  box-sizing: border-box;
  max-width: 5rem;
  opacity: .4;
  border-radius: .5rem;
  flex-direction: column;
  flex: 1;
  align-items: center;
  gap: .25rem;
  padding: .5rem 1rem;
  font-size: 14px;
  font-weight: 600;
  transition: all .3s;
  display: flex;
}

.NcH85G_abc .NcH85G_settings .NcH85G_setting .NcH85G_content.NcH85G_tokens .NcH85G_token svg {
  width: 100%;
  height: 100%;
  transition: all .3s;
}

.NcH85G_abc .NcH85G_settings .NcH85G_setting .NcH85G_content.NcH85G_tokens .NcH85G_token.NcH85G_selected {
  opacity: 1;
  background-color: #fff;
}

.NcH85G_abc .NcH85G_settings .NcH85G_setting .NcH85G_content.NcH85G_tokens .NcH85G_token.NcH85G_selected svg {
  fill: #52baf0;
}

.NcH85G_abc .NcH85G_settings .NcH85G_setting .NcH85G_content.NcH85G_tokens .NcH85G_token:hover {
  cursor: pointer;
}

.NcH85G_abc .NcH85G_settings .NcH85G_setting .NcH85G_content.NcH85G_tokens .NcH85G_token:hover:not(.NcH85G_selected) {
  background-color: #f0f4fd;
}

.NcH85G_abc .NcH85G_settings .NcH85G_setting .NcH85G_content.NcH85G_parameters {
  gap: .5rem;
  display: flex;
}

.NcH85G_abc .NcH85G_settings .NcH85G_setting .NcH85G_content.NcH85G_parameters > div {
  flex-direction: column;
  justify-content: center;
  display: flex;
}

.NcH85G_abc .NcH85G_settings .NcH85G_setting .NcH85G_content.NcH85G_parameters .NcH85G_separator {
  padding-top: 1rem;
}

.NcH85G_abc .NcH85G_settings .NcH85G_setting .NcH85G_content.NcH85G_parameters .NcH85G_parameter {
  flex: 1;
}

.NcH85G_abc .NcH85G_settings .NcH85G_setting .NcH85G_content.NcH85G_parameters .NcH85G_parameter .NcH85G_name {
  color: #0009;
  justify-content: space-between;
  font-size: 14px;
  font-weight: 500;
  display: flex;
}

.NcH85G_abc .NcH85G_settings .NcH85G_setting .NcH85G_content.NcH85G_parameters .NcH85G_parameter .NcH85G_name .NcH85G_all-in {
  color: #0009;
  cursor: pointer;
  text-decoration: underline;
}

.NcH85G_abc .NcH85G_settings .NcH85G_setting .NcH85G_content.NcH85G_parameters .NcH85G_parameter .NcH85G_input {
  box-sizing: border-box;
  height: 2.5rem;
  background-color: #fff;
  border-radius: .5rem;
  align-items: center;
  gap: .5rem;
  padding: .5rem;
  display: flex;
}

@media screen and (min-width: 768px) {
  .NcH85G_abc .NcH85G_settings .NcH85G_setting .NcH85G_content.NcH85G_parameters .NcH85G_parameter .NcH85G_input {
    height: 3rem;
  }
}

.NcH85G_abc .NcH85G_settings .NcH85G_setting .NcH85G_content.NcH85G_parameters .NcH85G_parameter .NcH85G_input img, .NcH85G_abc .NcH85G_settings .NcH85G_setting .NcH85G_content.NcH85G_parameters .NcH85G_parameter .NcH85G_input svg {
  height: 1rem;
  opacity: .7;
}

.NcH85G_abc .NcH85G_settings .NcH85G_setting .NcH85G_content.NcH85G_parameters .NcH85G_parameter .NcH85G_input input {
  width: 100%;
  color: #666;
  background-color: #0000;
  outline: 0;
  flex: 1;
  padding: 0;
  font-size: 1rem;
  font-weight: 500;
}

.NcH85G_abc .NcH85G_settings .NcH85G_setting .NcH85G_content.NcH85G_parameters .NcH85G_parameter .NcH85G_input input[type="number"] {
  -moz-appearance: textfield;
}

.NcH85G_abc .NcH85G_settings .NcH85G_setting .NcH85G_content.NcH85G_parameters .NcH85G_parameter .NcH85G_input input[type="number"]::-webkit-outer-spin-button, .NcH85G_abc .NcH85G_settings .NcH85G_setting .NcH85G_content.NcH85G_parameters .NcH85G_parameter .NcH85G_input input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.NcH85G_abc .NcH85G_settings .NcH85G_setting .NcH85G_content.NcH85G_parameters .NcH85G_parameter .NcH85G_input .NcH85G_increment {
  border-radius: .25rem;
  flex-direction: row-reverse;
  display: flex;
  overflow: hidden;
}

@media screen and (min-width: 768px) {
  .NcH85G_abc .NcH85G_settings .NcH85G_setting .NcH85G_content.NcH85G_parameters .NcH85G_parameter .NcH85G_input .NcH85G_increment {
    flex-direction: column;
  }
}

.NcH85G_abc .NcH85G_settings .NcH85G_setting .NcH85G_content.NcH85G_parameters .NcH85G_parameter .NcH85G_input .NcH85G_increment button {
  height: 100%;
  width: 1rem;
  background-color: #d3dfff;
  border-radius: 0;
  flex: 1;
  padding: 0;
  font-weight: 600;
}

.NcH85G_abc .NcH85G_settings .NcH85G_setting .NcH85G_content.NcH85G_parameters .NcH85G_parameter .NcH85G_input .NcH85G_increment button:hover {
  background-color: #b8cbff;
}

@media screen and (min-width: 768px) {
  .NcH85G_abc .NcH85G_settings .NcH85G_setting .NcH85G_content.NcH85G_parameters .NcH85G_parameter .NcH85G_input .NcH85G_increment button {
    line-height: 1rem;
  }
}

@media screen and (min-width: 1024px) {
  .NcH85G_abc .NcH85G_settings .NcH85G_setting.NcH85G_create {
    grid-column: 2 / 3;
  }
}

.NcH85G_abc .NcH85G_settings .NcH85G_setting.NcH85G_create button {
  height: 2.5rem;
  background-color: #52baf0;
}

@media screen and (min-width: 768px) {
  .NcH85G_abc .NcH85G_settings .NcH85G_setting.NcH85G_create button {
    height: 3rem;
  }
}

.NcH85G_abc .NcH85G_settings .NcH85G_setting.NcH85G_create button:hover {
  background-color: #3e9fd3;
}

.NcH85G_abc .NcH85G_your-match {
  background-color: #fff;
  border-radius: .5rem;
  flex-direction: column;
  gap: .5rem;
  padding: .5rem;
  display: flex;
}

@media screen and (min-width: 480px) {
  .NcH85G_abc .NcH85G_your-match {
    gap: 1rem;
    padding: 1rem;
  }
}

.NcH85G_abc .NcH85G_your-match .NcH85G_title {
  font-size: 1rem;
  font-weight: 700;
}

.NcH85G_abc .NcH85G_your-match .NcH85G_header {
  height: 3rem;
  box-sizing: border-box;
  border: 1px solid #0000001a;
  border-radius: .5rem;
  grid-template-rows: 100%;
  grid-template-columns: 1fr auto 1fr;
  align-items: center;
  padding: .5rem;
  font-size: .9rem;
  font-weight: 600;
  display: grid;
}

.NcH85G_abc .NcH85G_your-match .NcH85G_header .NcH85G_participant {
  height: 100%;
  align-items: center;
  gap: .5rem;
  display: flex;
}

.NcH85G_abc .NcH85G_your-match .NcH85G_header .NcH85G_participant img {
  height: 100%;
}

.NcH85G_abc .NcH85G_your-match .NcH85G_header .NcH85G_participant:last-child {
  justify-content: end;
}

.NcH85G_abc .NcH85G_your-match .NcH85G_image {
  aspect-ratio: 1 / 1;
  max-width: 300px;
  min-height: 300px;
  max-height: 300px;
  background-color: #e3eafe;
  border-radius: .5rem;
  flex: 1;
  justify-content: center;
  align-self: center;
  align-items: center;
  margin-top: 1.5rem;
  animation-name: NcH85G_show;
  animation-duration: 1s;
  display: flex;
  position: relative;
}

.NcH85G_abc .NcH85G_your-match .NcH85G_image > img {
  width: 100%;
  border-radius: .5rem;
}

.NcH85G_abc .NcH85G_your-match .NcH85G_image .NcH85G_unknown .NcH85G_countdown {
  width: 100%;
  height: 100%;
  opacity: .7;
  justify-content: center;
  align-items: center;
  font-size: 48px;
  font-weight: 700;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
}

.NcH85G_abc .NcH85G_your-match .NcH85G_image .NcH85G_unknown > img {
  opacity: .15;
}

.NcH85G_abc .NcH85G_your-match .NcH85G_results {
  box-sizing: border-box;
  height: 3rem;
  background-color: #e3eafe;
  border-radius: .5rem;
  justify-content: center;
  align-items: center;
  padding: .5rem;
  font-size: 14px;
  font-weight: 600;
  display: flex;
}

.NcH85G_abc .NcH85G_your-match .NcH85G_results img {
  max-height: 1.5rem;
  height: 100%;
  margin: 0 .5rem;
}

.NcH85G_abc .NcH85G_active-games {
  grid-template-columns: 1fr;
  gap: .5rem;
  display: grid;
}

@media screen and (min-width: 480px) {
  .NcH85G_abc .NcH85G_active-games {
    gap: 1rem;
  }
}

.NcH85G_abc .NcH85G_active-games .NcH85G_participants > div {
  align-items: center;
  gap: .5rem;
  display: flex;
}

.NcH85G_abc .NcH85G_active-games .NcH85G_participants > div.NcH85G_waiting {
  opacity: .4;
}

.NcH85G_abc .NcH85G_active-games .NcH85G_participants > div svg {
  width: 1rem;
  height: 1rem;
}

.NcH85G_abc .NcH85G_active-games .NcH85G_title {
  align-items: center;
  gap: 1rem;
  font-size: 22px;
  font-weight: 700;
  display: flex;
}

.NcH85G_abc .NcH85G_active-games .NcH85G_title .NcH85G_active-games-toggle {
  -webkit-user-select: none;
  user-select: none;
  cursor: pointer;
  align-items: center;
  gap: .5rem;
  font-size: 14px;
  font-weight: normal;
  display: flex;
}

.NcH85G_abc .NcH85G_active-games .NcH85G_title .NcH85G_active-games-toggle .NcH85G_toggle {
  pointer-events: none;
}

.NcH85G_abc .NcH85G_active-games .NcH85G_active-game {
  box-sizing: border-box;
  background-color: #2f2f2f;
  border: 1px solid #0000001a;
  border-radius: .5rem;
  grid: "NcH85G_a NcH85G_c" 1fr
        "NcH85G_b NcH85G_b" 1fr
        / 1fr 1fr;
  align-items: center;
  gap: .5rem;
  padding: .5rem;
  font-size: .9rem;
  font-weight: 600;
  animation-name: NcH85G_show;
  animation-duration: 1s;
  display: grid;
}

@media screen and (min-width: 480px) {
  .NcH85G_abc .NcH85G_active-games .NcH85G_active-game {
    grid: "NcH85G_a NcH85G_b NcH85G_c" 100%
    / 1fr auto 1fr;
    gap: 1rem;
    padding: 1rem;
  }

  .NcH85G_abc .NcH85G_active-games .NcH85G_active-game img {
    max-height: 3rem;
  }
}

.NcH85G_abc .NcH85G_active-games .NcH85G_active-game img {
  max-height: 2rem;
}

.NcH85G_abc .NcH85G_active-games .NcH85G_active-game .NcH85G_middle {
  justify-content: center;
  align-items: center;
  display: flex;
}

.NcH85G_abc .NcH85G_active-games .NcH85G_active-game .NcH85G_middle .NcH85G_join-options {
  gap: 1rem;
  display: flex;
}

.NcH85G_abc .NcH85G_active-games .NcH85G_active-game .NcH85G_middle .NcH85G_join-button, .NcH85G_abc .NcH85G_active-games .NcH85G_active-game .NcH85G_middle .NcH85G_cancel-button {
  height: 2rem;
  border: 1px solid #2aacd6;
}

.NcH85G_abc .NcH85G_active-games .NcH85G_active-game .NcH85G_middle .NcH85G_join-button svg, .NcH85G_abc .NcH85G_active-games .NcH85G_active-game .NcH85G_middle .NcH85G_join-button img, .NcH85G_abc .NcH85G_active-games .NcH85G_active-game .NcH85G_middle .NcH85G_cancel-button svg, .NcH85G_abc .NcH85G_active-games .NcH85G_active-game .NcH85G_middle .NcH85G_cancel-button img {
  max-height: 1.5rem;
  height: 100%;
  margin: 0 .25rem;
}

@media screen and (min-width: 480px) {
  .NcH85G_abc .NcH85G_active-games .NcH85G_active-game .NcH85G_middle .NcH85G_join-button svg, .NcH85G_abc .NcH85G_active-games .NcH85G_active-game .NcH85G_middle .NcH85G_join-button img, .NcH85G_abc .NcH85G_active-games .NcH85G_active-game .NcH85G_middle .NcH85G_cancel-button svg, .NcH85G_abc .NcH85G_active-games .NcH85G_active-game .NcH85G_middle .NcH85G_cancel-button img {
    margin: 0 .5rem;
  }
}

.NcH85G_abc .NcH85G_active-games .NcH85G_active-game .NcH85G_middle .NcH85G_join-button.NcH85G_cancel-button, .NcH85G_abc .NcH85G_active-games .NcH85G_active-game .NcH85G_middle .NcH85G_cancel-button.NcH85G_cancel-button {
  color: #ce8605;
  border: 1px solid #ce8605;
}

.NcH85G_abc .NcH85G_active-games .NcH85G_active-game .NcH85G_a {
  grid-area: NcH85G_a;
}

.NcH85G_abc .NcH85G_active-games .NcH85G_active-game .NcH85G_b {
  grid-area: NcH85G_b;
  display: flex;
}

.NcH85G_abc .NcH85G_active-games .NcH85G_active-game .NcH85G_b button {
  width: 100%;
}

.NcH85G_abc .NcH85G_active-games .NcH85G_active-game .NcH85G_c {
  grid-area: NcH85G_c;
}

.NcH85G_abc .NcH85G_active-games .NcH85G_active-game .NcH85G_total {
  justify-content: end;
  align-items: center;
  gap: .5rem;
  display: flex;
}

.NcH85G_abc .NcH85G_active-games .NcH85G_active-game .NcH85G_total img {
  height: 1rem;
}

.NcH85G_abc .NcH85G_history-games {
  flex-direction: column;
  gap: .5rem;
  display: flex;
}

@media screen and (min-width: 480px) {
  .NcH85G_abc .NcH85G_history-games {
    gap: 1rem;
  }
}

.NcH85G_abc .NcH85G_history-games .NcH85G_title {
  align-items: center;
  gap: 1rem;
  font-size: 22px;
  font-weight: 700;
  display: flex;
}

.NcH85G_abc .NcH85G_history-games .NcH85G_title .NcH85G_my-games-toggle {
  -webkit-user-select: none;
  user-select: none;
  cursor: pointer;
  align-items: center;
  gap: .5rem;
  font-size: 14px;
  font-weight: normal;
  display: flex;
}

.NcH85G_abc .NcH85G_history-games .NcH85G_title .NcH85G_my-games-toggle .NcH85G_toggle {
  pointer-events: none;
}

.NcH85G_abc .NcH85G_history-games .NcH85G_list {
  grid-template-columns: 1fr;
  gap: .5rem;
  display: grid;
}

@media screen and (min-width: 480px) {
  .NcH85G_abc .NcH85G_history-games .NcH85G_list {
    grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
    gap: 1rem;
  }
}

.NcH85G_abc .NcH85G_history-games .NcH85G_list .NcH85G_title {
  font-size: 22px;
  font-weight: 700;
}

.NcH85G_abc .NcH85G_history-games .NcH85G_list .NcH85G_history {
  background-color: #2f2f2f;
  border-radius: .5rem;
  flex-direction: column;
  justify-content: space-between;
  gap: 1rem;
  padding: .5rem;
  animation-name: NcH85G_show;
  animation-duration: 1s;
  display: flex;
  position: relative;
  overflow: hidden;
}

@media screen and (min-width: 480px) {
  .NcH85G_abc .NcH85G_history-games .NcH85G_list .NcH85G_history {
    padding: 1rem;
  }
}

.NcH85G_abc .NcH85G_history-games .NcH85G_list .NcH85G_history .NcH85G_info {
  height: 100%;
  flex-direction: column;
  justify-content: space-between;
  gap: 1rem;
  font-size: 14px;
  font-weight: 600;
  display: flex;
}

.NcH85G_abc .NcH85G_history-games .NcH85G_list .NcH85G_history .NcH85G_info .NcH85G_username {
  width: -moz-fit-content;
  width: fit-content;
  align-items: center;
  gap: .5rem;
  font-size: 16px;
  font-weight: 600;
  display: flex;
}

.NcH85G_abc .NcH85G_history-games .NcH85G_list .NcH85G_history .NcH85G_info .NcH85G_username.NcH85G_won, .NcH85G_abc .NcH85G_history-games .NcH85G_list .NcH85G_history .NcH85G_info .NcH85G_username.NcH85G_lost {
  color: #fff;
  border-radius: .5rem;
  padding: .1rem .4rem;
}

.NcH85G_abc .NcH85G_history-games .NcH85G_list .NcH85G_history .NcH85G_info .NcH85G_username.NcH85G_won {
  background-color: #2ed57399;
}

.NcH85G_abc .NcH85G_history-games .NcH85G_list .NcH85G_history .NcH85G_info .NcH85G_username.NcH85G_lost {
  background-color: #ff4e5f99;
}

.NcH85G_abc .NcH85G_history-games .NcH85G_list .NcH85G_history .NcH85G_info .NcH85G_username img {
  height: 1rem;
}

.NcH85G_abc .NcH85G_history-games .NcH85G_list .NcH85G_history .NcH85G_badge {
  width: 3rem;
  height: 2rem;
  justify-content: center;
  align-items: center;
  display: flex;
  position: absolute;
  top: .5rem;
  right: .5rem;
}

@media screen and (min-width: 480px) {
  .NcH85G_abc .NcH85G_history-games .NcH85G_list .NcH85G_history .NcH85G_badge {
    top: 1rem;
    right: 1rem;
  }
}

.NcH85G_abc .NcH85G_history-games .NcH85G_list .NcH85G_history .NcH85G_badge img {
  height: 2rem;
}

.NcH85G_abc .NcH85G_history-games .NcH85G_list .NcH85G_history .NcH85G_time-ago {
  width: 3rem;
  opacity: .6;
  justify-content: center;
  font-size: 11px;
  display: flex;
  position: absolute;
  bottom: .5rem;
  right: .5rem;
}

@media screen and (min-width: 480px) {
  .NcH85G_abc .NcH85G_history-games .NcH85G_list .NcH85G_history .NcH85G_time-ago {
    bottom: 1rem;
    right: 1rem;
  }
}

.NcH85G_abc .NcH85G_profile {
  cursor: pointer;
}

.NcH85G_abc .NcH85G_profile:hover {
  text-decoration: underline;
}

.NcH85G_abc .NcH85G_multi-button {
  width: 100%;
  box-sizing: border-box;
  height: 2.5rem;
  background-color: #fff;
  border-radius: .5rem;
  gap: .5rem;
  padding: .3rem;
  display: flex;
}

@media screen and (min-width: 768px) {
  .NcH85G_abc .NcH85G_multi-button {
    height: 3rem;
  }
}

.NcH85G_abc .NcH85G_multi-button > button {
  height: 100%;
  color: #00000080;
  flex: 1;
  transition: all .3s;
}

.NcH85G_abc .NcH85G_multi-button > button img {
  height: 100%;
}

.NcH85G_abc .NcH85G_multi-button > button.NcH85G_selected {
  color: #fff;
  background-color: #52baf0;
  box-shadow: 0 4px 4px #bbc4dcb3;
}

.NcH85G_abc .NcH85G_multi-button > button:hover {
  background-color: #52baf0 !important;
}

.NcH85G_abc .NcH85G_multi-button > button:hover:not(.NcH85G_selected) {
  background-color: #fff7 !important;
}

.NcH85G_confirm-join {
  align-items: center;
  display: flex;
}

.NcH85G_confirm-join img {
  margin: 0 .5rem;
  width: 1rem !important;
}

@keyframes NcH85G_show {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.NcH85G_svg-token {
  fill: #999;
}

.rIJrVa_baddie-beast {
  max-width: 1200px;
  -ms-overflow-style: none;
  scrollbar-width: none;
  flex-direction: column;
  flex: 1;
  gap: 1rem;
  padding: .5rem;
  display: flex;
  position: relative;
  overflow-y: auto;
}

.rIJrVa_baddie-beast::-webkit-scrollbar {
  display: none;
}

@media screen and (min-width: 768px) {
  .rIJrVa_baddie-beast {
    padding: 0;
  }
}

.rIJrVa_baddie-beast .rIJrVa_marketing {
  min-height: 100px;
  border-radius: .5rem;
  justify-content: center;
  align-items: center;
  display: flex;
  position: relative;
  overflow: hidden;
}

@media screen and (min-width: 480px) {
  .rIJrVa_baddie-beast .rIJrVa_marketing {
    gap: 1rem;
  }
}

@media screen and (min-width: 768px) {
  .rIJrVa_baddie-beast .rIJrVa_marketing {
    min-height: 125px;
  }
}

@media screen and (min-width: 1024px) {
  .rIJrVa_baddie-beast .rIJrVa_marketing {
    min-height: 150px;
  }
}

.rIJrVa_baddie-beast .rIJrVa_marketing img {
  width: 100%;
  position: absolute;
}

.rIJrVa_baddie-beast .rIJrVa_marketing .rIJrVa_offer {
  z-index: 1;
}

.rIJrVa_baddie-beast .rIJrVa_marketing .rIJrVa_offer .rIJrVa_countdown {
  color: #fff;
  text-align: center;
  background-color: #0000;
  font-weight: 700;
}

.rIJrVa_baddie-beast .rIJrVa_game {
  grid-template-columns: 1fr;
  gap: 1rem;
  display: grid;
  position: relative;
}

@media screen and (min-width: 1200px) {
  .rIJrVa_baddie-beast .rIJrVa_game {
    grid-template-columns: repeat(auto-fit, minmax(500px, 1fr));
  }
}

.rIJrVa_baddie-beast .rIJrVa_game .rIJrVa_loading {
  -webkit-backdrop-filter: blur(.5rem);
  backdrop-filter: blur(.5rem);
}

.rIJrVa_baddie-beast .rIJrVa_settings {
  background-color: #242424;
  border-radius: .5rem;
  grid-template-columns: 1fr;
  justify-content: space-between;
  gap: .5rem;
  padding: .5rem;
  display: grid;
}

@media screen and (min-width: 1024px) {
  .rIJrVa_baddie-beast .rIJrVa_settings {
    grid-template-columns: 1fr 1fr 1fr;
    gap: 1rem;
    padding: 1rem;
  }
}

.rIJrVa_baddie-beast .rIJrVa_settings .rIJrVa_setting {
  flex-direction: column;
  gap: .5rem;
  display: flex;
}

@media screen and (min-width: 480px) {
  .rIJrVa_baddie-beast .rIJrVa_settings .rIJrVa_setting {
    gap: 1rem;
  }
}

.rIJrVa_baddie-beast .rIJrVa_settings .rIJrVa_setting .rIJrVa_title {
  font-size: 1rem;
  font-weight: 700;
}

.rIJrVa_baddie-beast .rIJrVa_settings .rIJrVa_setting .rIJrVa_content {
  flex: 1;
  align-items: center;
  display: flex;
}

.rIJrVa_baddie-beast .rIJrVa_settings .rIJrVa_setting .rIJrVa_content.rIJrVa_tokens {
  justify-content: space-between;
  display: flex;
}

.rIJrVa_baddie-beast .rIJrVa_settings .rIJrVa_setting .rIJrVa_content.rIJrVa_tokens .rIJrVa_token {
  max-width: 5rem;
  opacity: .5;
  border-radius: .5rem;
  flex-direction: column;
  flex: 1;
  align-items: center;
  gap: .25rem;
  padding: .5rem 1rem;
  font-size: 14px;
  font-weight: 600;
  transition: all .3s;
  display: flex;
}

.rIJrVa_baddie-beast .rIJrVa_settings .rIJrVa_setting .rIJrVa_content.rIJrVa_tokens .rIJrVa_token img {
  transition: all .3s;
}

.rIJrVa_baddie-beast .rIJrVa_settings .rIJrVa_setting .rIJrVa_content.rIJrVa_tokens .rIJrVa_token.rIJrVa_selected {
  opacity: 1;
  background-color: #2f2f2f;
}

.rIJrVa_baddie-beast .rIJrVa_settings .rIJrVa_setting .rIJrVa_content.rIJrVa_tokens .rIJrVa_token.rIJrVa_selected.rIJrVa_beast > img {
  filter: drop-shadow(0 .25rem .75rem #55c5e399);
}

.rIJrVa_baddie-beast .rIJrVa_settings .rIJrVa_setting .rIJrVa_content.rIJrVa_tokens .rIJrVa_token.rIJrVa_selected.rIJrVa_baddie > img {
  filter: drop-shadow(0 .25rem .75rem #da44ff80);
}

.rIJrVa_baddie-beast .rIJrVa_settings .rIJrVa_setting .rIJrVa_content.rIJrVa_tokens .rIJrVa_token.rIJrVa_selected.rIJrVa_random > img {
  filter: drop-shadow(0 .25rem .75rem #ffd83499);
}

.rIJrVa_baddie-beast .rIJrVa_settings .rIJrVa_setting .rIJrVa_content.rIJrVa_tokens .rIJrVa_token:hover {
  cursor: pointer;
}

.rIJrVa_baddie-beast .rIJrVa_settings .rIJrVa_setting .rIJrVa_content.rIJrVa_tokens .rIJrVa_token:hover:not(.rIJrVa_selected) {
  background-color: #343434;
}

.rIJrVa_baddie-beast .rIJrVa_settings .rIJrVa_setting .rIJrVa_content.rIJrVa_parameters {
  gap: .5rem;
  display: flex;
}

.rIJrVa_baddie-beast .rIJrVa_settings .rIJrVa_setting .rIJrVa_content.rIJrVa_parameters > div {
  flex-direction: column;
  justify-content: center;
  display: flex;
}

.rIJrVa_baddie-beast .rIJrVa_settings .rIJrVa_setting .rIJrVa_content.rIJrVa_parameters .rIJrVa_separator {
  padding-top: 1rem;
}

.rIJrVa_baddie-beast .rIJrVa_settings .rIJrVa_setting .rIJrVa_content.rIJrVa_parameters .rIJrVa_parameter {
  flex: 1;
}

.rIJrVa_baddie-beast .rIJrVa_settings .rIJrVa_setting .rIJrVa_content.rIJrVa_parameters .rIJrVa_parameter .rIJrVa_name {
  justify-content: space-between;
  font-size: 14px;
  font-weight: 500;
  display: flex;
}

.rIJrVa_baddie-beast .rIJrVa_settings .rIJrVa_setting .rIJrVa_content.rIJrVa_parameters .rIJrVa_parameter .rIJrVa_name .rIJrVa_all-in {
  cursor: pointer;
  text-decoration: underline;
}

.rIJrVa_baddie-beast .rIJrVa_settings .rIJrVa_setting .rIJrVa_content.rIJrVa_parameters .rIJrVa_parameter .rIJrVa_input {
  box-sizing: border-box;
  height: 2.5rem;
  background-color: #2f2f2f;
  border-radius: .5rem;
  align-items: center;
  gap: .5rem;
  padding: .5rem;
  display: flex;
}

@media screen and (min-width: 768px) {
  .rIJrVa_baddie-beast .rIJrVa_settings .rIJrVa_setting .rIJrVa_content.rIJrVa_parameters .rIJrVa_parameter .rIJrVa_input {
    height: 3rem;
  }
}

.rIJrVa_baddie-beast .rIJrVa_settings .rIJrVa_setting .rIJrVa_content.rIJrVa_parameters .rIJrVa_parameter .rIJrVa_input.rIJrVa_disabled {
  opacity: .7;
  cursor: not-allowed;
}

.rIJrVa_baddie-beast .rIJrVa_settings .rIJrVa_setting .rIJrVa_content.rIJrVa_parameters .rIJrVa_parameter .rIJrVa_input img, .rIJrVa_baddie-beast .rIJrVa_settings .rIJrVa_setting .rIJrVa_content.rIJrVa_parameters .rIJrVa_parameter .rIJrVa_input svg {
  height: 1rem;
  opacity: .7;
}

.rIJrVa_baddie-beast .rIJrVa_settings .rIJrVa_setting .rIJrVa_content.rIJrVa_parameters .rIJrVa_parameter .rIJrVa_input input {
  width: 100%;
  background-color: #0000;
  outline: 0;
  flex: 1;
  padding: 0;
  font-size: 1rem;
  font-weight: 500;
}

.rIJrVa_baddie-beast .rIJrVa_settings .rIJrVa_setting .rIJrVa_content.rIJrVa_parameters .rIJrVa_parameter .rIJrVa_input input[type="number"] {
  -moz-appearance: textfield;
}

.rIJrVa_baddie-beast .rIJrVa_settings .rIJrVa_setting .rIJrVa_content.rIJrVa_parameters .rIJrVa_parameter .rIJrVa_input input[type="number"]::-webkit-outer-spin-button, .rIJrVa_baddie-beast .rIJrVa_settings .rIJrVa_setting .rIJrVa_content.rIJrVa_parameters .rIJrVa_parameter .rIJrVa_input input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.rIJrVa_baddie-beast .rIJrVa_settings .rIJrVa_setting .rIJrVa_content.rIJrVa_parameters .rIJrVa_parameter .rIJrVa_input .rIJrVa_increment {
  border-radius: .25rem;
  flex-direction: row-reverse;
  display: flex;
  overflow: hidden;
}

@media screen and (min-width: 768px) {
  .rIJrVa_baddie-beast .rIJrVa_settings .rIJrVa_setting .rIJrVa_content.rIJrVa_parameters .rIJrVa_parameter .rIJrVa_input .rIJrVa_increment {
    flex-direction: column;
  }
}

.rIJrVa_baddie-beast .rIJrVa_settings .rIJrVa_setting .rIJrVa_content.rIJrVa_parameters .rIJrVa_parameter .rIJrVa_input .rIJrVa_increment button {
  height: 100%;
  width: 1rem;
  color: #333;
  background: #fff;
  border-radius: 0;
  flex: 1;
  padding: 0;
  font-weight: 600;
}

.rIJrVa_baddie-beast .rIJrVa_settings .rIJrVa_setting .rIJrVa_content.rIJrVa_parameters .rIJrVa_parameter .rIJrVa_input .rIJrVa_increment button:hover {
  background-color: #fffd;
}

@media screen and (min-width: 768px) {
  .rIJrVa_baddie-beast .rIJrVa_settings .rIJrVa_setting .rIJrVa_content.rIJrVa_parameters .rIJrVa_parameter .rIJrVa_input .rIJrVa_increment button {
    line-height: 1rem;
  }
}

.rIJrVa_baddie-beast .rIJrVa_settings .rIJrVa_setting.rIJrVa_create {
  position: relative;
}

@media screen and (min-width: 1024px) {
  .rIJrVa_baddie-beast .rIJrVa_settings .rIJrVa_setting.rIJrVa_create {
    grid-column: 2 / 3;
  }
}

.rIJrVa_baddie-beast .rIJrVa_settings .rIJrVa_setting.rIJrVa_create button {
  height: 2.5rem;
}

@media screen and (min-width: 768px) {
  .rIJrVa_baddie-beast .rIJrVa_settings .rIJrVa_setting.rIJrVa_create button {
    height: 3rem;
  }
}

.rIJrVa_baddie-beast .rIJrVa_settings .rIJrVa_setting.rIJrVa_create .rIJrVa_confetti {
  pointer-events: none;
  justify-content: center;
  align-items: center;
  margin: auto;
  display: flex;
  position: absolute;
  inset: 0;
}

.rIJrVa_baddie-beast .rIJrVa_your-match {
  background-color: #fff;
  border-radius: .5rem;
  flex-direction: column;
  gap: .5rem;
  padding: .5rem;
  display: flex;
}

@media screen and (min-width: 480px) {
  .rIJrVa_baddie-beast .rIJrVa_your-match {
    gap: 1rem;
    padding: 1rem;
  }
}

.rIJrVa_baddie-beast .rIJrVa_your-match .rIJrVa_title {
  font-size: 1rem;
  font-weight: 700;
}

.rIJrVa_baddie-beast .rIJrVa_your-match .rIJrVa_header {
  height: 3rem;
  box-sizing: border-box;
  border: 1px solid #0000001a;
  border-radius: .5rem;
  grid-template-rows: 100%;
  grid-template-columns: 1fr auto 1fr;
  align-items: center;
  padding: .5rem;
  font-size: .9rem;
  font-weight: 600;
  display: grid;
}

.rIJrVa_baddie-beast .rIJrVa_your-match .rIJrVa_header .rIJrVa_participant {
  height: 100%;
  align-items: center;
  gap: .5rem;
  display: flex;
}

.rIJrVa_baddie-beast .rIJrVa_your-match .rIJrVa_header .rIJrVa_participant img {
  height: 100%;
}

.rIJrVa_baddie-beast .rIJrVa_your-match .rIJrVa_header .rIJrVa_participant:last-child {
  justify-content: end;
}

.rIJrVa_baddie-beast .rIJrVa_your-match .rIJrVa_image {
  aspect-ratio: 1 / 1;
  max-width: 300px;
  min-height: 300px;
  max-height: 300px;
  background-color: #e3eafe;
  border-radius: .5rem;
  flex: 1;
  justify-content: center;
  align-self: center;
  align-items: center;
  margin-top: 1.5rem;
  animation-name: rIJrVa_show;
  animation-duration: 1s;
  display: flex;
  position: relative;
}

.rIJrVa_baddie-beast .rIJrVa_your-match .rIJrVa_image .rIJrVa_badge {
  height: 4rem;
  width: 4rem;
  box-sizing: border-box;
  background-color: #fff;
  border: .25rem solid #fff;
  border-radius: 4rem;
  position: absolute;
  top: -2rem;
}

.rIJrVa_baddie-beast .rIJrVa_your-match .rIJrVa_image > img {
  width: 100%;
  border-radius: .5rem;
}

.rIJrVa_baddie-beast .rIJrVa_your-match .rIJrVa_image .rIJrVa_unknown .rIJrVa_countdown {
  width: 100%;
  height: 100%;
  opacity: .7;
  justify-content: center;
  align-items: center;
  font-size: 48px;
  font-weight: 700;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
}

.rIJrVa_baddie-beast .rIJrVa_your-match .rIJrVa_image .rIJrVa_unknown > img {
  opacity: .15;
}

.rIJrVa_baddie-beast .rIJrVa_your-match .rIJrVa_results {
  box-sizing: border-box;
  height: 3rem;
  background-color: #e3eafe;
  border-radius: .5rem;
  justify-content: center;
  align-items: center;
  padding: .5rem;
  font-size: 14px;
  font-weight: 600;
  display: flex;
}

.rIJrVa_baddie-beast .rIJrVa_your-match .rIJrVa_results img {
  max-height: 1.5rem;
  height: 100%;
  margin: 0 .5rem;
}

.rIJrVa_baddie-beast .rIJrVa_active-games {
  grid-template-columns: 1fr;
  gap: .5rem;
  display: grid;
}

@media screen and (min-width: 480px) {
  .rIJrVa_baddie-beast .rIJrVa_active-games {
    gap: 1rem;
  }
}

.rIJrVa_baddie-beast .rIJrVa_active-games .rIJrVa_title {
  align-items: center;
  gap: 1rem;
  font-size: 22px;
  font-weight: 700;
  display: flex;
}

.rIJrVa_baddie-beast .rIJrVa_active-games .rIJrVa_title .rIJrVa_active-games-toggle {
  -webkit-user-select: none;
  user-select: none;
  cursor: pointer;
  align-items: center;
  gap: .5rem;
  font-size: 14px;
  font-weight: normal;
  display: flex;
}

.rIJrVa_baddie-beast .rIJrVa_active-games .rIJrVa_title .rIJrVa_active-games-toggle .rIJrVa_toggle {
  pointer-events: none;
}

.rIJrVa_baddie-beast .rIJrVa_active-games .rIJrVa_active-game {
  box-sizing: border-box;
  background: linear-gradient(270deg, #fff1 0%, #fff0 50%, #fff1 100%);
  border: 1px solid #0000001a;
  border-radius: .5rem;
  grid: "rIJrVa_a rIJrVa_c" 1fr
        "rIJrVa_b rIJrVa_b" 1fr
        / 1fr 1fr;
  align-items: center;
  gap: .5rem;
  padding: .5rem;
  font-size: .9rem;
  font-weight: 600;
  animation-name: rIJrVa_show;
  animation-duration: 1s;
  display: grid;
}

@media screen and (min-width: 480px) {
  .rIJrVa_baddie-beast .rIJrVa_active-games .rIJrVa_active-game {
    grid: "rIJrVa_a rIJrVa_b rIJrVa_c" 100%
    / 1fr auto 1fr;
    gap: 1rem;
    padding: 1rem;
  }

  .rIJrVa_baddie-beast .rIJrVa_active-games .rIJrVa_active-game img {
    max-height: 3rem;
  }
}

.rIJrVa_baddie-beast .rIJrVa_active-games .rIJrVa_active-game img {
  max-height: 2rem;
}

.rIJrVa_baddie-beast .rIJrVa_active-games .rIJrVa_active-game .rIJrVa_middle {
  height: 100%;
  min-width: 2rem;
  justify-content: center;
  align-items: center;
  display: flex;
}

.rIJrVa_baddie-beast .rIJrVa_active-games .rIJrVa_active-game .rIJrVa_middle .rIJrVa_join-button, .rIJrVa_baddie-beast .rIJrVa_active-games .rIJrVa_active-game .rIJrVa_middle .rIJrVa_cancel-button {
  height: 2rem;
  border: 1px solid #1e90ff;
}

.rIJrVa_baddie-beast .rIJrVa_active-games .rIJrVa_active-game .rIJrVa_middle .rIJrVa_join-button img, .rIJrVa_baddie-beast .rIJrVa_active-games .rIJrVa_active-game .rIJrVa_middle .rIJrVa_cancel-button img {
  max-height: 1.5rem;
  height: 100%;
  margin: 0 .5rem;
}

.rIJrVa_baddie-beast .rIJrVa_active-games .rIJrVa_active-game .rIJrVa_middle .rIJrVa_join-button.rIJrVa_cancel-button, .rIJrVa_baddie-beast .rIJrVa_active-games .rIJrVa_active-game .rIJrVa_middle .rIJrVa_cancel-button.rIJrVa_cancel-button {
  color: #ce8605;
  border: 1px solid #ce8605;
}

.rIJrVa_baddie-beast .rIJrVa_active-games .rIJrVa_active-game .rIJrVa_middle .rIJrVa_flip {
  width: 100%;
  height: 100%;
  transform-style: preserve-3d;
  position: relative;
}

.rIJrVa_baddie-beast .rIJrVa_active-games .rIJrVa_active-game .rIJrVa_middle .rIJrVa_flip.rIJrVa_beast {
  animation: 3s forwards rIJrVa_flip-beast;
}

.rIJrVa_baddie-beast .rIJrVa_active-games .rIJrVa_active-game .rIJrVa_middle .rIJrVa_flip.rIJrVa_baddie {
  animation: 3s forwards rIJrVa_flip-baddie;
}

.rIJrVa_baddie-beast .rIJrVa_active-games .rIJrVa_active-game .rIJrVa_middle .rIJrVa_flip .rIJrVa_beast-side, .rIJrVa_baddie-beast .rIJrVa_active-games .rIJrVa_active-game .rIJrVa_middle .rIJrVa_flip .rIJrVa_baddie-side {
  width: 2rem;
  height: 2rem;
  backface-visibility: hidden;
  position: absolute;
}

.rIJrVa_baddie-beast .rIJrVa_active-games .rIJrVa_active-game .rIJrVa_middle .rIJrVa_flip .rIJrVa_beast-backside, .rIJrVa_baddie-beast .rIJrVa_active-games .rIJrVa_active-game .rIJrVa_middle .rIJrVa_flip .rIJrVa_baddie-backside {
  width: 2rem;
  height: 2rem;
  backface-visibility: hidden;
  transform-style: preserve-3d;
  background-color: #888;
  border-radius: 50%;
  position: absolute;
  top: 0;
}

.rIJrVa_baddie-beast .rIJrVa_active-games .rIJrVa_active-game .rIJrVa_middle .rIJrVa_flip .rIJrVa_beast-side {
  transform: translateZ(-2px)rotateY(-180deg);
}

.rIJrVa_baddie-beast .rIJrVa_active-games .rIJrVa_active-game .rIJrVa_middle .rIJrVa_flip .rIJrVa_beast-backside {
  transform: translateZ(-2px);
}

.rIJrVa_baddie-beast .rIJrVa_active-games .rIJrVa_active-game .rIJrVa_middle .rIJrVa_flip .rIJrVa_baddie-side {
  transform: translateZ(2px);
}

.rIJrVa_baddie-beast .rIJrVa_active-games .rIJrVa_active-game .rIJrVa_middle .rIJrVa_flip .rIJrVa_baddie-backside {
  transform: translateZ(2px)rotateY(180deg);
}

.rIJrVa_baddie-beast .rIJrVa_active-games .rIJrVa_active-game .rIJrVa_middle .rIJrVa_flip:after {
  content: "";
  height: 2rem;
  width: 4px;
  background-color: #888;
  position: absolute;
  transform: rotateY(90deg)translateZ(.8rem);
}

.rIJrVa_baddie-beast .rIJrVa_active-games .rIJrVa_active-game .rIJrVa_a {
  grid-area: rIJrVa_a;
}

.rIJrVa_baddie-beast .rIJrVa_active-games .rIJrVa_active-game .rIJrVa_b {
  grid-area: rIJrVa_b;
  display: flex;
}

.rIJrVa_baddie-beast .rIJrVa_active-games .rIJrVa_active-game .rIJrVa_b button {
  width: 100%;
}

.rIJrVa_baddie-beast .rIJrVa_active-games .rIJrVa_active-game .rIJrVa_c {
  grid-area: rIJrVa_c;
}

.rIJrVa_baddie-beast .rIJrVa_active-games .rIJrVa_active-game .rIJrVa_participant {
  height: 100%;
  align-items: center;
  gap: .5rem;
  display: flex;
}

.rIJrVa_baddie-beast .rIJrVa_active-games .rIJrVa_active-game .rIJrVa_participant img {
  height: 100%;
}

.rIJrVa_baddie-beast .rIJrVa_active-games .rIJrVa_active-game .rIJrVa_participant:last-child {
  justify-content: end;
}

.rIJrVa_baddie-beast .rIJrVa_history-games {
  flex-direction: column;
  gap: .5rem;
  display: flex;
}

@media screen and (min-width: 480px) {
  .rIJrVa_baddie-beast .rIJrVa_history-games {
    gap: 1rem;
  }
}

.rIJrVa_baddie-beast .rIJrVa_history-games .rIJrVa_title {
  align-items: center;
  gap: 1rem;
  font-size: 22px;
  font-weight: 700;
  display: flex;
}

.rIJrVa_baddie-beast .rIJrVa_history-games .rIJrVa_title .rIJrVa_my-games-toggle {
  -webkit-user-select: none;
  user-select: none;
  cursor: pointer;
  align-items: center;
  gap: .5rem;
  font-size: 14px;
  font-weight: normal;
  display: flex;
}

.rIJrVa_baddie-beast .rIJrVa_history-games .rIJrVa_title .rIJrVa_my-games-toggle .rIJrVa_toggle {
  pointer-events: none;
}

.rIJrVa_baddie-beast .rIJrVa_history-games .rIJrVa_list {
  grid-template-columns: 1fr;
  gap: .5rem;
  display: grid;
}

@media screen and (min-width: 480px) {
  .rIJrVa_baddie-beast .rIJrVa_history-games .rIJrVa_list {
    grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
    gap: 1rem;
  }
}

.rIJrVa_baddie-beast .rIJrVa_history-games .rIJrVa_list .rIJrVa_title {
  font-size: 22px;
  font-weight: 700;
}

.rIJrVa_baddie-beast .rIJrVa_history-games .rIJrVa_list .rIJrVa_history {
  background: linear-gradient(270deg, #fff0 0%, #fff1 100%);
  border-radius: .5rem;
  flex-direction: column;
  justify-content: space-between;
  gap: 1rem;
  padding: .5rem;
  animation-name: rIJrVa_show;
  animation-duration: 1s;
  display: flex;
  position: relative;
  overflow: hidden;
}

@media screen and (min-width: 480px) {
  .rIJrVa_baddie-beast .rIJrVa_history-games .rIJrVa_list .rIJrVa_history {
    padding: 1rem;
  }
}

.rIJrVa_baddie-beast .rIJrVa_history-games .rIJrVa_list .rIJrVa_history .rIJrVa_info {
  height: 100%;
  flex-direction: column;
  justify-content: space-between;
  gap: 1rem;
  font-size: 14px;
  font-weight: 600;
  display: flex;
}

.rIJrVa_baddie-beast .rIJrVa_history-games .rIJrVa_list .rIJrVa_history .rIJrVa_info .rIJrVa_username {
  width: -moz-fit-content;
  width: fit-content;
  align-items: center;
  gap: .5rem;
  font-size: 16px;
  font-weight: 600;
  display: flex;
}

.rIJrVa_baddie-beast .rIJrVa_history-games .rIJrVa_list .rIJrVa_history .rIJrVa_info .rIJrVa_username.rIJrVa_won, .rIJrVa_baddie-beast .rIJrVa_history-games .rIJrVa_list .rIJrVa_history .rIJrVa_info .rIJrVa_username.rIJrVa_lost {
  color: #fff;
  border-radius: .5rem;
  padding: .1rem .4rem;
}

.rIJrVa_baddie-beast .rIJrVa_history-games .rIJrVa_list .rIJrVa_history .rIJrVa_info .rIJrVa_username.rIJrVa_won {
  background-color: #2ed57380;
}

.rIJrVa_baddie-beast .rIJrVa_history-games .rIJrVa_list .rIJrVa_history .rIJrVa_info .rIJrVa_username.rIJrVa_lost {
  background-color: #ff4e5f80;
}

.rIJrVa_baddie-beast .rIJrVa_history-games .rIJrVa_list .rIJrVa_history .rIJrVa_info .rIJrVa_username img {
  height: 10px;
}

.rIJrVa_baddie-beast .rIJrVa_history-games .rIJrVa_list .rIJrVa_history .rIJrVa_badge {
  width: 3rem;
  position: absolute;
  top: .5rem;
  right: .5rem;
}

@media screen and (min-width: 480px) {
  .rIJrVa_baddie-beast .rIJrVa_history-games .rIJrVa_list .rIJrVa_history .rIJrVa_badge {
    top: 1rem;
    right: 1rem;
  }
}

.rIJrVa_baddie-beast .rIJrVa_history-games .rIJrVa_list .rIJrVa_history .rIJrVa_badge img {
  width: 3rem;
}

.rIJrVa_baddie-beast .rIJrVa_history-games .rIJrVa_list .rIJrVa_history .rIJrVa_time-ago {
  width: 3rem;
  opacity: .6;
  justify-content: center;
  font-size: 11px;
  display: flex;
  position: absolute;
  bottom: .5rem;
  right: .5rem;
}

@media screen and (min-width: 480px) {
  .rIJrVa_baddie-beast .rIJrVa_history-games .rIJrVa_list .rIJrVa_history .rIJrVa_time-ago {
    bottom: 1rem;
    right: 1rem;
  }
}

.rIJrVa_baddie-beast .rIJrVa_history-games .rIJrVa_list .rIJrVa_history.rIJrVa_beast .rIJrVa_badge {
  filter: drop-shadow(0 0 .25rem #55c5e355);
}

.rIJrVa_baddie-beast .rIJrVa_history-games .rIJrVa_list .rIJrVa_history.rIJrVa_baddie .rIJrVa_badge {
  filter: drop-shadow(0 0 .25rem #da44ff55);
}

.rIJrVa_baddie-beast .rIJrVa_multi-button {
  width: 100%;
  box-sizing: border-box;
  height: 2.5rem;
  background-color: #343434;
  border-radius: .5rem;
  gap: .5rem;
  padding: .3rem;
  display: flex;
}

@media screen and (min-width: 768px) {
  .rIJrVa_baddie-beast .rIJrVa_multi-button {
    height: 3rem;
  }
}

.rIJrVa_baddie-beast .rIJrVa_multi-button > button {
  height: 100%;
  flex: 1;
  transition: all .3s;
}

.rIJrVa_baddie-beast .rIJrVa_multi-button > button img {
  height: 100%;
}

.rIJrVa_baddie-beast .rIJrVa_multi-button > button.rIJrVa_selected {
  color: #000;
  background-color: #fff;
}

.rIJrVa_baddie-beast .rIJrVa_multi-button > button:hover {
  background-color: #fff !important;
}

.rIJrVa_baddie-beast .rIJrVa_multi-button > button:hover:not(.rIJrVa_selected) {
  background-color: #fff7 !important;
}

.rIJrVa_baddie-beast .rIJrVa_profile {
  cursor: pointer;
}

.rIJrVa_baddie-beast .rIJrVa_profile:hover {
  text-decoration: underline;
}

.rIJrVa_confirm-join {
  align-items: center;
  display: flex;
}

.rIJrVa_confirm-join img {
  margin: 0 .5rem;
  width: 1rem !important;
}

@keyframes rIJrVa_show {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes rIJrVa_flip-beast {
  0% {
    transform: rotateY(0)scale(1);
  }

  50% {
    transform: rotateY(900deg)scale(2);
  }

  100% {
    transform: rotateY(1980deg)scale(1);
  }
}

@keyframes rIJrVa_flip-baddie {
  0% {
    transform: rotateY(0)scale(1);
  }

  50% {
    transform: rotateY(900deg)scale(2);
  }

  100% {
    transform: rotateY(1800deg)scale(1);
  }
}

.V-NDTq_bombz {
  max-width: 1200px;
  -ms-overflow-style: none;
  scrollbar-width: none;
  flex-direction: column;
  flex: 1;
  gap: 1rem;
  padding: .5rem;
  display: flex;
  position: relative;
  overflow-y: auto;
}

.V-NDTq_bombz::-webkit-scrollbar {
  display: none;
}

@media screen and (min-width: 768px) {
  .V-NDTq_bombz {
    padding: 0;
  }
}

.V-NDTq_bombz .V-NDTq_game-container {
  border-radius: .5rem;
  flex-direction: column;
  flex: none;
  display: flex;
  position: relative;
  overflow: hidden;
}

.V-NDTq_bombz .V-NDTq_game-container .V-NDTq_game {
  background-color: #2f2f2f;
  border-radius: .5rem;
  flex-direction: column-reverse;
  display: flex;
}

.V-NDTq_bombz .V-NDTq_game-container .V-NDTq_game .V-NDTq_loading {
  -webkit-backdrop-filter: blur(.5rem);
  backdrop-filter: blur(.5rem);
}

@media screen and (min-width: 1024px) {
  .V-NDTq_bombz .V-NDTq_game-container .V-NDTq_game {
    flex-direction: row;
  }
}

.V-NDTq_bombz .V-NDTq_settings {
  flex-direction: column;
  justify-content: space-between;
  gap: .5rem;
  padding: .5rem;
  display: flex;
}

@media screen and (min-width: 1024px) {
  .V-NDTq_bombz .V-NDTq_settings {
    gap: 1rem;
    padding: 1rem;
  }
}

.V-NDTq_bombz .V-NDTq_settings .V-NDTq_setting {
  flex-direction: column;
  gap: .5rem;
  display: flex;
}

@media screen and (min-width: 480px) {
  .V-NDTq_bombz .V-NDTq_settings .V-NDTq_setting {
    gap: 1rem;
  }
}

.V-NDTq_bombz .V-NDTq_settings .V-NDTq_setting .V-NDTq_tabs {
  display: flex;
}

.V-NDTq_bombz .V-NDTq_settings .V-NDTq_setting .V-NDTq_title {
  font-size: 1rem;
  font-weight: 700;
}

.V-NDTq_bombz .V-NDTq_settings .V-NDTq_setting .V-NDTq_content {
  flex: 1;
  align-items: end;
  display: flex;
}

.V-NDTq_bombz .V-NDTq_settings .V-NDTq_setting .V-NDTq_content.V-NDTq_parameters {
  flex-direction: column;
  gap: .5rem;
  display: flex;
}

.V-NDTq_bombz .V-NDTq_settings .V-NDTq_setting .V-NDTq_content.V-NDTq_parameters .V-NDTq_separator {
  padding-top: 1rem;
}

.V-NDTq_bombz .V-NDTq_settings .V-NDTq_setting .V-NDTq_content.V-NDTq_parameters .V-NDTq_parameter {
  width: 100%;
  min-width: 270px;
  flex-direction: column;
  flex: 1;
  display: flex;
}

.V-NDTq_bombz .V-NDTq_settings .V-NDTq_setting .V-NDTq_content.V-NDTq_parameters .V-NDTq_parameter .V-NDTq_name {
  justify-content: space-between;
  font-size: 14px;
  font-weight: 500;
  display: flex;
}

.V-NDTq_bombz .V-NDTq_settings .V-NDTq_setting .V-NDTq_content.V-NDTq_parameters .V-NDTq_parameter .V-NDTq_name .V-NDTq_all-in {
  cursor: pointer;
  text-decoration: underline;
}

.V-NDTq_bombz .V-NDTq_settings .V-NDTq_setting .V-NDTq_content.V-NDTq_parameters .V-NDTq_parameter .V-NDTq_name .V-NDTq_label img, .V-NDTq_bombz .V-NDTq_settings .V-NDTq_setting .V-NDTq_content.V-NDTq_parameters .V-NDTq_parameter .V-NDTq_name .V-NDTq_label svg {
  width: 10px;
  height: 10px;
  margin: 0 .2rem;
}

.V-NDTq_bombz .V-NDTq_settings .V-NDTq_setting .V-NDTq_content.V-NDTq_parameters .V-NDTq_parameter .V-NDTq_input {
  box-sizing: border-box;
  height: 2.5rem;
  background-color: #444;
  border-radius: .5rem;
  align-items: center;
  gap: .5rem;
  padding: .5rem;
  display: flex;
}

.V-NDTq_bombz .V-NDTq_settings .V-NDTq_setting .V-NDTq_content.V-NDTq_parameters .V-NDTq_parameter .V-NDTq_input img, .V-NDTq_bombz .V-NDTq_settings .V-NDTq_setting .V-NDTq_content.V-NDTq_parameters .V-NDTq_parameter .V-NDTq_input svg {
  height: 1rem;
  opacity: .8;
}

.V-NDTq_bombz .V-NDTq_settings .V-NDTq_setting .V-NDTq_content.V-NDTq_parameters .V-NDTq_parameter .V-NDTq_input input {
  width: 0;
  background-color: #0000;
  outline: 0;
  flex: 1;
  padding: 0;
  font-size: 1rem;
  font-weight: 500;
}

.V-NDTq_bombz .V-NDTq_settings .V-NDTq_setting .V-NDTq_content.V-NDTq_parameters .V-NDTq_parameter .V-NDTq_input input[type="number"] {
  -moz-appearance: textfield;
}

.V-NDTq_bombz .V-NDTq_settings .V-NDTq_setting .V-NDTq_content.V-NDTq_parameters .V-NDTq_parameter .V-NDTq_input input[type="number"]::-webkit-outer-spin-button, .V-NDTq_bombz .V-NDTq_settings .V-NDTq_setting .V-NDTq_content.V-NDTq_parameters .V-NDTq_parameter .V-NDTq_input input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.V-NDTq_bombz .V-NDTq_settings .V-NDTq_setting .V-NDTq_content.V-NDTq_parameters .V-NDTq_parameter .V-NDTq_input .V-NDTq_increment {
  border-radius: .25rem;
  flex-direction: row-reverse;
  display: flex;
  overflow: hidden;
}

@media screen and (min-width: 768px) {
  .V-NDTq_bombz .V-NDTq_settings .V-NDTq_setting .V-NDTq_content.V-NDTq_parameters .V-NDTq_parameter .V-NDTq_input .V-NDTq_increment {
    flex-direction: column;
  }
}

.V-NDTq_bombz .V-NDTq_settings .V-NDTq_setting .V-NDTq_content.V-NDTq_parameters .V-NDTq_parameter .V-NDTq_input .V-NDTq_increment button {
  width: 1rem;
  border-radius: 0;
  padding: 0;
}

.V-NDTq_bombz .V-NDTq_settings .V-NDTq_setting .V-NDTq_content.V-NDTq_parameters .V-NDTq_parameter .V-NDTq_input button {
  height: 100%;
  background-color: #2f2f2f;
  font-weight: 600;
}

.V-NDTq_bombz .V-NDTq_settings .V-NDTq_setting .V-NDTq_content.V-NDTq_parameters .V-NDTq_parameter .V-NDTq_input button:hover {
  background-color: #252525;
}

@media screen and (min-width: 768px) {
  .V-NDTq_bombz .V-NDTq_settings .V-NDTq_setting .V-NDTq_content.V-NDTq_parameters .V-NDTq_parameter .V-NDTq_input button {
    line-height: 1rem;
  }
}

.V-NDTq_bombz .V-NDTq_settings .V-NDTq_setting .V-NDTq_content.V-NDTq_parameters .V-NDTq_parameter .V-NDTq_input button.V-NDTq_infinity {
  width: 1rem;
  border-radius: .25rem;
}

.V-NDTq_bombz .V-NDTq_settings .V-NDTq_setting .V-NDTq_content.V-NDTq_parameters .V-NDTq_parameter .V-NDTq_input button.V-NDTq_infinity svg {
  height: .8rem;
}

.V-NDTq_bombz .V-NDTq_settings .V-NDTq_setting .V-NDTq_content.V-NDTq_parameters .V-NDTq_clean-program {
  width: 100%;
  height: 2rem;
}

.V-NDTq_bombz .V-NDTq_settings .V-NDTq_setting.V-NDTq_create {
  position: relative;
}

.V-NDTq_bombz .V-NDTq_settings .V-NDTq_setting.V-NDTq_create .V-NDTq_confetti {
  pointer-events: none;
  justify-content: center;
  align-items: center;
  margin: auto;
  display: flex;
  position: absolute;
  inset: 0;
}

.V-NDTq_bombz .V-NDTq_viewer {
  max-height: 500px;
  background: linear-gradient(#343434 0% 35%, #3f3f3f 100%);
  flex: 1;
  display: flex;
}

.V-NDTq_bombz .V-NDTq_viewer canvas {
  aspect-ratio: 1 / 1;
  flex: 1;
  display: flex;
  width: 100% !important;
  height: 100% !important;
}

.V-NDTq_bombz .V-NDTq_history-games {
  flex-direction: column;
  gap: .5rem;
  display: flex;
}

@media screen and (min-width: 480px) {
  .V-NDTq_bombz .V-NDTq_history-games {
    gap: 1rem;
  }
}

.V-NDTq_bombz .V-NDTq_history-games .V-NDTq_title {
  align-items: center;
  gap: 1rem;
  font-size: 22px;
  font-weight: 700;
  display: flex;
}

.V-NDTq_bombz .V-NDTq_history-games .V-NDTq_title .V-NDTq_my-games-toggle {
  -webkit-user-select: none;
  user-select: none;
  cursor: pointer;
  align-items: center;
  gap: .5rem;
  font-size: 14px;
  font-weight: normal;
  display: flex;
}

.V-NDTq_bombz .V-NDTq_history-games .V-NDTq_title .V-NDTq_my-games-toggle .V-NDTq_toggle {
  pointer-events: none;
}

.V-NDTq_bombz .V-NDTq_history-games .V-NDTq_list {
  grid-template-columns: 1fr;
  gap: .5rem;
  display: grid;
}

@media screen and (min-width: 480px) {
  .V-NDTq_bombz .V-NDTq_history-games .V-NDTq_list {
    grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
    gap: 1rem;
  }
}

.V-NDTq_bombz .V-NDTq_history-games .V-NDTq_list .V-NDTq_title {
  font-size: 22px;
  font-weight: 700;
}

.V-NDTq_bombz .V-NDTq_history-games .V-NDTq_list .V-NDTq_history {
  background-color: #2f2f2f;
  border-radius: .5rem;
  flex-direction: column;
  justify-content: space-between;
  gap: 1rem;
  padding: .5rem;
  animation-name: V-NDTq_show;
  animation-duration: 1s;
  display: flex;
  position: relative;
  overflow: hidden;
}

@media screen and (min-width: 480px) {
  .V-NDTq_bombz .V-NDTq_history-games .V-NDTq_list .V-NDTq_history {
    padding: 1rem;
  }
}

.V-NDTq_bombz .V-NDTq_history-games .V-NDTq_list .V-NDTq_history .V-NDTq_profile {
  cursor: pointer;
}

.V-NDTq_bombz .V-NDTq_history-games .V-NDTq_list .V-NDTq_history .V-NDTq_profile:hover {
  text-decoration: underline;
}

.V-NDTq_bombz .V-NDTq_history-games .V-NDTq_list .V-NDTq_history .V-NDTq_info {
  height: 100%;
  flex-direction: column;
  justify-content: space-between;
  gap: 1rem;
  font-size: 14px;
  font-weight: 600;
  display: flex;
}

.V-NDTq_bombz .V-NDTq_history-games .V-NDTq_list .V-NDTq_history .V-NDTq_info .V-NDTq_username {
  width: -moz-fit-content;
  width: fit-content;
  align-items: center;
  gap: .5rem;
  font-size: 16px;
  font-weight: 600;
  display: flex;
}

.V-NDTq_bombz .V-NDTq_history-games .V-NDTq_list .V-NDTq_history .V-NDTq_info .V-NDTq_username.V-NDTq_won, .V-NDTq_bombz .V-NDTq_history-games .V-NDTq_list .V-NDTq_history .V-NDTq_info .V-NDTq_username.V-NDTq_lost {
  color: #fff;
  border-radius: .5rem;
  padding: .1rem .4rem;
}

.V-NDTq_bombz .V-NDTq_history-games .V-NDTq_list .V-NDTq_history .V-NDTq_info .V-NDTq_username.V-NDTq_won {
  background-color: #2ed57399;
}

.V-NDTq_bombz .V-NDTq_history-games .V-NDTq_list .V-NDTq_history .V-NDTq_info .V-NDTq_username.V-NDTq_lost {
  background-color: #ff4e5f99;
}

.V-NDTq_bombz .V-NDTq_history-games .V-NDTq_list .V-NDTq_history .V-NDTq_info .V-NDTq_username img {
  height: 1rem;
}

.V-NDTq_bombz .V-NDTq_history-games .V-NDTq_list .V-NDTq_history .V-NDTq_badge {
  width: 3rem;
  height: 2rem;
  justify-content: center;
  align-items: center;
  font-size: 1rem;
  display: flex;
  position: absolute;
  top: .5rem;
  right: .5rem;
}

@media screen and (min-width: 480px) {
  .V-NDTq_bombz .V-NDTq_history-games .V-NDTq_list .V-NDTq_history .V-NDTq_badge {
    top: 1rem;
    right: 1rem;
  }
}

.V-NDTq_bombz .V-NDTq_history-games .V-NDTq_list .V-NDTq_history .V-NDTq_badge img {
  height: 2rem;
}

.V-NDTq_bombz .V-NDTq_history-games .V-NDTq_list .V-NDTq_history .V-NDTq_time-ago {
  width: 3rem;
  opacity: .6;
  justify-content: center;
  font-size: 11px;
  display: flex;
  position: absolute;
  bottom: .5rem;
  right: .5rem;
}

@media screen and (min-width: 480px) {
  .V-NDTq_bombz .V-NDTq_history-games .V-NDTq_list .V-NDTq_history .V-NDTq_time-ago {
    bottom: 1rem;
    right: 1rem;
  }
}

.V-NDTq_bombz .V-NDTq_multi-button {
  width: 100%;
  box-sizing: border-box;
  height: 2rem;
  border-radius: .5rem;
  gap: .5rem;
  display: flex;
}

.V-NDTq_bombz .V-NDTq_multi-button > button {
  height: 100%;
  flex: 1;
  transition: all .3s;
}

.V-NDTq_bombz .V-NDTq_multi-button > button img {
  height: 100%;
}

.V-NDTq_bombz .V-NDTq_multi-button > button.V-NDTq_selected {
  background-color: #1e90ff;
}

.V-NDTq_bombz .V-NDTq_multi-button > button:hover {
  background-color: #1e90ff !important;
}

.V-NDTq_bombz .V-NDTq_multi-button > button:hover:not(.V-NDTq_selected) {
  background-color: #fff1 !important;
}

.V-NDTq_bombz .V-NDTq_multi-button > button > div {
  align-items: center;
  display: flex;
}

@keyframes V-NDTq_show {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.KLdcUW_pay-table-overlay {
  background-color: #3333;
  padding: 1rem;
  font-family: Baloo Thambi !important;
}

.KLdcUW_pay-table-overlay .KLdcUW_pay-table {
  width: 100vw;
  height: 100vh;
  max-width: min(700px, 100vw - 2rem);
  max-height: min(500px, 100vh - 2rem);
  box-sizing: border-box;
  background-color: #2f2f2f;
  border-radius: 1rem;
  flex-direction: column;
  gap: 1rem;
  padding: 2rem 1rem 1rem;
  font-size: 1rem;
  display: flex;
  position: relative;
  overflow: hidden;
  box-shadow: 0 8px 24px #1f1f1f;
}

.KLdcUW_pay-table-overlay .KLdcUW_pay-table:before {
  content: "";
  z-index: 1;
  width: 33%;
  height: 6px;
  background: #1e90ff;
  border-radius: 6px;
  margin: auto;
  position: absolute;
  top: -3px;
  left: 0;
  right: 0;
  box-shadow: 0 -2px 8px 2px #1e90ff99, 0 -4px 17px 5px #1e90ff4d;
}

.KLdcUW_pay-table-overlay .KLdcUW_pay-table .KLdcUW_close-button {
  height: auto;
  padding: 0;
  font-size: 1.5rem;
  position: absolute;
  top: 1rem;
  right: 1rem;
}

.KLdcUW_pay-table-overlay .KLdcUW_pay-table .KLdcUW_table {
  grid-template-columns: 1fr;
  gap: 1rem;
  font-size: 1.5rem;
  display: grid;
}

@media screen and (min-width: 768px) {
  .KLdcUW_pay-table-overlay .KLdcUW_pay-table .KLdcUW_table {
    grid-template-columns: 1fr 1fr;
    font-size: 2rem;
  }
}

.KLdcUW_pay-table-overlay .KLdcUW_pay-table .KLdcUW_table .KLdcUW_element {
  align-items: center;
  gap: 1rem;
  display: flex;
}

.KLdcUW_pay-table-overlay .KLdcUW_pay-table .KLdcUW_table .KLdcUW_element .KLdcUW_symbols {
  gap: .2rem;
  display: flex;
}

.KLdcUW_pay-table-overlay .KLdcUW_pay-table .KLdcUW_table .KLdcUW_element .KLdcUW_symbols img {
  height: 1.5rem;
}

@media screen and (min-width: 768px) {
  .KLdcUW_pay-table-overlay .KLdcUW_pay-table .KLdcUW_table .KLdcUW_element .KLdcUW_symbols img {
    height: 2rem;
  }
}

.xA-hWa_slots {
  max-width: 1200px;
  -ms-overflow-style: none;
  scrollbar-width: none;
  flex-direction: column;
  flex: 1;
  gap: 1rem;
  padding: .5rem;
  display: flex;
  position: relative;
  overflow-y: auto;
}

.xA-hWa_slots::-webkit-scrollbar {
  display: none;
}

@media screen and (min-width: 768px) {
  .xA-hWa_slots {
    padding: 0;
  }
}

.xA-hWa_slots .xA-hWa_game-container {
  border-radius: .5rem;
  flex-direction: column;
  flex: none;
  display: flex;
  position: relative;
  overflow: hidden;
}

.xA-hWa_slots .xA-hWa_game-container .xA-hWa_game {
  background-color: #2f2f2f;
  border-radius: .5rem;
  flex-direction: column-reverse;
  display: flex;
}

.xA-hWa_slots .xA-hWa_game-container .xA-hWa_game .xA-hWa_loading {
  -webkit-backdrop-filter: blur(.5rem);
  backdrop-filter: blur(.5rem);
}

@media screen and (min-width: 1024px) {
  .xA-hWa_slots .xA-hWa_game-container .xA-hWa_game {
    flex-direction: row;
  }
}

.xA-hWa_slots .xA-hWa_settings {
  flex-direction: column;
  justify-content: space-between;
  gap: .5rem;
  padding: .5rem;
  display: flex;
}

@media screen and (min-width: 1024px) {
  .xA-hWa_slots .xA-hWa_settings {
    gap: 1rem;
    padding: 1rem;
  }
}

.xA-hWa_slots .xA-hWa_settings .xA-hWa_setting {
  flex-direction: column;
  gap: .5rem;
  display: flex;
}

@media screen and (min-width: 480px) {
  .xA-hWa_slots .xA-hWa_settings .xA-hWa_setting {
    gap: 1rem;
  }
}

.xA-hWa_slots .xA-hWa_settings .xA-hWa_setting .xA-hWa_tabs {
  display: flex;
}

.xA-hWa_slots .xA-hWa_settings .xA-hWa_setting .xA-hWa_title {
  justify-content: space-between;
  align-items: center;
  font-size: 1rem;
  font-weight: 700;
  display: flex;
}

.xA-hWa_slots .xA-hWa_settings .xA-hWa_setting .xA-hWa_title .xA-hWa_sound {
  cursor: pointer;
}

.xA-hWa_slots .xA-hWa_settings .xA-hWa_setting .xA-hWa_content {
  flex: 1;
  display: flex;
}

.xA-hWa_slots .xA-hWa_settings .xA-hWa_setting .xA-hWa_content.xA-hWa_parameters {
  flex-direction: column;
  gap: .5rem;
  display: flex;
}

.xA-hWa_slots .xA-hWa_settings .xA-hWa_setting .xA-hWa_content.xA-hWa_parameters .xA-hWa_separator {
  padding-top: 1rem;
}

.xA-hWa_slots .xA-hWa_settings .xA-hWa_setting .xA-hWa_content.xA-hWa_parameters a {
  color: #e8e8e8;
  font-size: 1rem;
}

.xA-hWa_slots .xA-hWa_settings .xA-hWa_setting .xA-hWa_content.xA-hWa_parameters .xA-hWa_parameter {
  width: 100%;
  min-width: 270px;
  flex-direction: column;
  flex: 1;
  display: flex;
}

.xA-hWa_slots .xA-hWa_settings .xA-hWa_setting .xA-hWa_content.xA-hWa_parameters .xA-hWa_parameter .xA-hWa_name {
  justify-content: space-between;
  font-size: 14px;
  font-weight: 500;
  display: flex;
}

.xA-hWa_slots .xA-hWa_settings .xA-hWa_setting .xA-hWa_content.xA-hWa_parameters .xA-hWa_parameter .xA-hWa_name .xA-hWa_all-in {
  cursor: pointer;
  text-decoration: underline;
}

.xA-hWa_slots .xA-hWa_settings .xA-hWa_setting .xA-hWa_content.xA-hWa_parameters .xA-hWa_parameter .xA-hWa_name .xA-hWa_label img, .xA-hWa_slots .xA-hWa_settings .xA-hWa_setting .xA-hWa_content.xA-hWa_parameters .xA-hWa_parameter .xA-hWa_name .xA-hWa_label svg {
  width: 10px;
  height: 10px;
  margin: 0 .2rem;
}

.xA-hWa_slots .xA-hWa_settings .xA-hWa_setting .xA-hWa_content.xA-hWa_parameters .xA-hWa_parameter .xA-hWa_input {
  box-sizing: border-box;
  height: 2.5rem;
  background-color: #444;
  border-radius: .5rem;
  align-items: center;
  gap: .5rem;
  padding: .5rem;
  display: flex;
}

.xA-hWa_slots .xA-hWa_settings .xA-hWa_setting .xA-hWa_content.xA-hWa_parameters .xA-hWa_parameter .xA-hWa_input img, .xA-hWa_slots .xA-hWa_settings .xA-hWa_setting .xA-hWa_content.xA-hWa_parameters .xA-hWa_parameter .xA-hWa_input svg {
  height: 1rem;
  opacity: .8;
}

.xA-hWa_slots .xA-hWa_settings .xA-hWa_setting .xA-hWa_content.xA-hWa_parameters .xA-hWa_parameter .xA-hWa_input input {
  width: 0;
  background-color: #0000;
  outline: 0;
  flex: 1;
  padding: 0;
  font-size: 1rem;
  font-weight: 500;
}

.xA-hWa_slots .xA-hWa_settings .xA-hWa_setting .xA-hWa_content.xA-hWa_parameters .xA-hWa_parameter .xA-hWa_input input[type="number"] {
  -moz-appearance: textfield;
}

.xA-hWa_slots .xA-hWa_settings .xA-hWa_setting .xA-hWa_content.xA-hWa_parameters .xA-hWa_parameter .xA-hWa_input input[type="number"]::-webkit-outer-spin-button, .xA-hWa_slots .xA-hWa_settings .xA-hWa_setting .xA-hWa_content.xA-hWa_parameters .xA-hWa_parameter .xA-hWa_input input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.xA-hWa_slots .xA-hWa_settings .xA-hWa_setting .xA-hWa_content.xA-hWa_parameters .xA-hWa_parameter .xA-hWa_input .xA-hWa_increment {
  border-radius: .25rem;
  flex-direction: row-reverse;
  display: flex;
  overflow: hidden;
}

@media screen and (min-width: 768px) {
  .xA-hWa_slots .xA-hWa_settings .xA-hWa_setting .xA-hWa_content.xA-hWa_parameters .xA-hWa_parameter .xA-hWa_input .xA-hWa_increment {
    flex-direction: column;
  }
}

.xA-hWa_slots .xA-hWa_settings .xA-hWa_setting .xA-hWa_content.xA-hWa_parameters .xA-hWa_parameter .xA-hWa_input .xA-hWa_increment button {
  width: 1rem;
  border-radius: 0;
  padding: 0;
}

.xA-hWa_slots .xA-hWa_settings .xA-hWa_setting .xA-hWa_content.xA-hWa_parameters .xA-hWa_parameter .xA-hWa_input button {
  height: 100%;
  background-color: #2f2f2f;
  font-weight: 600;
}

.xA-hWa_slots .xA-hWa_settings .xA-hWa_setting .xA-hWa_content.xA-hWa_parameters .xA-hWa_parameter .xA-hWa_input button:hover {
  background-color: #252525;
}

@media screen and (min-width: 768px) {
  .xA-hWa_slots .xA-hWa_settings .xA-hWa_setting .xA-hWa_content.xA-hWa_parameters .xA-hWa_parameter .xA-hWa_input button {
    line-height: 1rem;
  }
}

.xA-hWa_slots .xA-hWa_settings .xA-hWa_setting .xA-hWa_content.xA-hWa_parameters .xA-hWa_parameter .xA-hWa_input button.xA-hWa_infinity {
  width: 1rem;
  border-radius: .25rem;
}

.xA-hWa_slots .xA-hWa_settings .xA-hWa_setting .xA-hWa_content.xA-hWa_parameters .xA-hWa_parameter .xA-hWa_input button.xA-hWa_infinity svg {
  height: .8rem;
}

.xA-hWa_slots .xA-hWa_settings .xA-hWa_setting .xA-hWa_content.xA-hWa_parameters .xA-hWa_clean-program {
  width: 100%;
  height: 2rem;
}

.xA-hWa_slots .xA-hWa_settings .xA-hWa_setting.xA-hWa_create {
  position: relative;
}

.xA-hWa_slots .xA-hWa_settings .xA-hWa_setting.xA-hWa_create .xA-hWa_confetti {
  pointer-events: none;
  justify-content: center;
  align-items: center;
  margin: auto;
  display: flex;
  position: absolute;
  inset: 0;
}

.xA-hWa_slots .xA-hWa_settings .xA-hWa_setting.xA-hWa_create .xA-hWa_label img, .xA-hWa_slots .xA-hWa_settings .xA-hWa_setting.xA-hWa_create .xA-hWa_label svg {
  height: 10px;
}

.xA-hWa_slots .xA-hWa_viewer {
  max-height: 500px;
  background: linear-gradient(#343434 0% 35%, #3f3f3f 100%);
  flex: 1;
  display: flex;
}

.xA-hWa_slots .xA-hWa_viewer canvas {
  aspect-ratio: 1 / 1;
  flex: 1;
  display: flex;
  width: 100% !important;
  height: 100% !important;
}

.xA-hWa_slots .xA-hWa_history-games {
  flex-direction: column;
  gap: .5rem;
  display: flex;
}

@media screen and (min-width: 480px) {
  .xA-hWa_slots .xA-hWa_history-games {
    gap: 1rem;
  }
}

.xA-hWa_slots .xA-hWa_history-games .xA-hWa_title {
  align-items: center;
  gap: 1rem;
  font-size: 22px;
  font-weight: 700;
  display: flex;
}

.xA-hWa_slots .xA-hWa_history-games .xA-hWa_title .xA-hWa_my-games-toggle {
  -webkit-user-select: none;
  user-select: none;
  cursor: pointer;
  align-items: center;
  gap: .5rem;
  font-size: 14px;
  font-weight: normal;
  display: flex;
}

.xA-hWa_slots .xA-hWa_history-games .xA-hWa_title .xA-hWa_my-games-toggle .xA-hWa_toggle {
  pointer-events: none;
}

.xA-hWa_slots .xA-hWa_history-games .xA-hWa_list {
  grid-template-columns: 1fr;
  gap: .5rem;
  display: grid;
}

@media screen and (min-width: 480px) {
  .xA-hWa_slots .xA-hWa_history-games .xA-hWa_list {
    grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
    gap: 1rem;
  }
}

.xA-hWa_slots .xA-hWa_history-games .xA-hWa_list .xA-hWa_title {
  font-size: 22px;
  font-weight: 700;
}

.xA-hWa_slots .xA-hWa_history-games .xA-hWa_list .xA-hWa_history {
  background-color: #2f2f2f;
  border-radius: .5rem;
  flex-direction: column;
  justify-content: space-between;
  gap: 1rem;
  padding: .5rem;
  animation-name: xA-hWa_show;
  animation-duration: 1s;
  display: flex;
  position: relative;
  overflow: hidden;
}

@media screen and (min-width: 480px) {
  .xA-hWa_slots .xA-hWa_history-games .xA-hWa_list .xA-hWa_history {
    padding: 1rem;
  }
}

.xA-hWa_slots .xA-hWa_history-games .xA-hWa_list .xA-hWa_history .xA-hWa_profile {
  cursor: pointer;
}

.xA-hWa_slots .xA-hWa_history-games .xA-hWa_list .xA-hWa_history .xA-hWa_profile:hover {
  text-decoration: underline;
}

.xA-hWa_slots .xA-hWa_history-games .xA-hWa_list .xA-hWa_history .xA-hWa_info {
  height: 100%;
  flex-direction: column;
  justify-content: space-between;
  gap: 1rem;
  font-size: 14px;
  font-weight: 600;
  display: flex;
}

.xA-hWa_slots .xA-hWa_history-games .xA-hWa_list .xA-hWa_history .xA-hWa_info .xA-hWa_username {
  width: -moz-fit-content;
  width: fit-content;
  align-items: center;
  gap: .5rem;
  font-size: 16px;
  font-weight: 600;
  display: flex;
}

.xA-hWa_slots .xA-hWa_history-games .xA-hWa_list .xA-hWa_history .xA-hWa_info .xA-hWa_username.xA-hWa_won, .xA-hWa_slots .xA-hWa_history-games .xA-hWa_list .xA-hWa_history .xA-hWa_info .xA-hWa_username.xA-hWa_lost {
  color: #fff;
  border-radius: .5rem;
  padding: .1rem .4rem;
}

.xA-hWa_slots .xA-hWa_history-games .xA-hWa_list .xA-hWa_history .xA-hWa_info .xA-hWa_username.xA-hWa_won {
  background-color: #2ed57399;
}

.xA-hWa_slots .xA-hWa_history-games .xA-hWa_list .xA-hWa_history .xA-hWa_info .xA-hWa_username.xA-hWa_lost {
  background-color: #ff4e5f99;
}

.xA-hWa_slots .xA-hWa_history-games .xA-hWa_list .xA-hWa_history .xA-hWa_info .xA-hWa_username img {
  height: 1rem;
}

.xA-hWa_slots .xA-hWa_history-games .xA-hWa_list .xA-hWa_history .xA-hWa_badge {
  width: 3rem;
  height: 2rem;
  justify-content: center;
  align-items: center;
  font-size: 1rem;
  display: flex;
  position: absolute;
  top: .5rem;
  right: .5rem;
}

@media screen and (min-width: 480px) {
  .xA-hWa_slots .xA-hWa_history-games .xA-hWa_list .xA-hWa_history .xA-hWa_badge {
    top: 1rem;
    right: 1rem;
  }
}

.xA-hWa_slots .xA-hWa_history-games .xA-hWa_list .xA-hWa_history .xA-hWa_badge img {
  height: 2rem;
}

.xA-hWa_slots .xA-hWa_history-games .xA-hWa_list .xA-hWa_history .xA-hWa_time-ago {
  width: 3rem;
  opacity: .6;
  justify-content: center;
  font-size: 11px;
  display: flex;
  position: absolute;
  bottom: .5rem;
  right: .5rem;
}

@media screen and (min-width: 480px) {
  .xA-hWa_slots .xA-hWa_history-games .xA-hWa_list .xA-hWa_history .xA-hWa_time-ago {
    bottom: 1rem;
    right: 1rem;
  }
}

.xA-hWa_slots .xA-hWa_multi-button {
  width: 100%;
  box-sizing: border-box;
  height: 2rem;
  border-radius: .5rem;
  gap: .5rem;
  display: flex;
}

.xA-hWa_slots .xA-hWa_multi-button > button {
  height: 100%;
  flex: 1;
  transition: all .3s;
}

.xA-hWa_slots .xA-hWa_multi-button > button img {
  height: 100%;
}

.xA-hWa_slots .xA-hWa_multi-button > button.xA-hWa_selected {
  background-color: #1e90ff;
}

.xA-hWa_slots .xA-hWa_multi-button > button:hover {
  background-color: #1e90ff !important;
}

.xA-hWa_slots .xA-hWa_multi-button > button:hover:not(.xA-hWa_selected) {
  background-color: #fff1 !important;
}

.xA-hWa_slots .xA-hWa_multi-button > button > div {
  align-items: center;
  display: flex;
}

@keyframes xA-hWa_show {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.e21-Aq_loading-overlay {
  z-index: 100;
  width: 100%;
  height: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
}

.e21-Aq_loading-overlay.e21-Aq_blur {
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
}

.e21-Aq_loading-overlay svg {
  color: #1e90ff;
}

.NkyHqG_textarea {
  box-sizing: border-box;
  resize: none;
  color: #e8e8e8;
  background-color: #343434;
  border: none;
  border-radius: .5rem;
  justify-content: center;
  align-items: center;
  padding: .5rem 1rem;
  font-family: Baloo Thambi;
  font-size: 1rem;
  display: flex;
}

.NkyHqG_textarea:focus {
  outline: 1px solid #1e90ff;
}

.McHiCW_chat {
  flex-direction: column;
  flex: 1;
  gap: 1rem;
  display: flex;
  position: relative;
  overflow: hidden;
}

.McHiCW_chat .McHiCW_header {
  height: 4rem;
  justify-content: center;
  align-items: center;
  gap: .5rem;
  padding: 0 1rem;
  display: flex;
}

.McHiCW_chat .McHiCW_header > div {
  flex: 1;
  align-items: center;
  display: flex;
}

.McHiCW_chat .McHiCW_header .McHiCW_title {
  font-size: 1rem;
}

.McHiCW_chat .McHiCW_header .McHiCW_connected {
  color: #2ed573;
  height: 100%;
  flex: 2;
  justify-content: center;
  align-items: center;
  gap: .5rem;
  font-size: 14px;
  font-weight: 600;
  display: flex;
  position: relative;
  overflow: hidden;
}

.McHiCW_chat .McHiCW_header .McHiCW_connected:before {
  content: "";
  width: 50%;
  height: 6px;
  background: #69cf55;
  border-radius: 6px;
  margin: auto;
  position: absolute;
  bottom: -3px;
  left: 0;
  right: 0;
  box-shadow: 0 -2px 17px 4px #49ce6699, 0 -4px 35px 11px #3cd36e4d;
}

.McHiCW_chat .McHiCW_header .McHiCW_close {
  justify-content: end;
  font-size: 1rem;
}

.McHiCW_chat .McHiCW_header .McHiCW_close svg {
  cursor: pointer;
}

.McHiCW_chat .McHiCW_main {
  min-width: 306px;
  flex-direction: column;
  flex: 1;
  gap: 1rem;
  display: flex;
  position: relative;
  overflow: hidden;
}

.McHiCW_chat .McHiCW_main .McHiCW_messages {
  height: 100px;
  -ms-overflow-style: none;
  scrollbar-width: none;
  flex-direction: column;
  flex: 1;
  gap: .5rem;
  padding: 0 1rem;
  display: flex;
  position: relative;
  overflow: auto;
}

.McHiCW_chat .McHiCW_main .McHiCW_messages::-webkit-scrollbar {
  display: none;
}

.McHiCW_chat .McHiCW_main .McHiCW_messages .McHiCW_message {
  display: flex;
  position: relative;
  transform: translateZ(0);
}

.McHiCW_chat .McHiCW_main .McHiCW_messages .McHiCW_message.McHiCW_tip .McHiCW_details {
  background: linear-gradient(260deg, #ffd83499 0%, #343434 100%);
}

.McHiCW_chat .McHiCW_main .McHiCW_messages .McHiCW_message img {
  height: 10px;
}

.McHiCW_chat .McHiCW_main .McHiCW_messages .McHiCW_message .McHiCW_profile {
  cursor: pointer;
}

.McHiCW_chat .McHiCW_main .McHiCW_messages .McHiCW_message .McHiCW_profile:hover {
  text-decoration: underline;
}

.McHiCW_chat .McHiCW_main .McHiCW_messages .McHiCW_message .McHiCW_reply-to {
  z-index: 0;
  height: 3rem;
  background-color: #444;
  border-radius: .5rem;
  gap: .5rem;
  padding: 0 .5rem;
  font-size: 14px;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  right: .5rem;
}

.McHiCW_chat .McHiCW_main .McHiCW_messages .McHiCW_message .McHiCW_reply-to .McHiCW_text {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.McHiCW_chat .McHiCW_main .McHiCW_messages .McHiCW_message .McHiCW_reply-to svg {
  width: .5rem;
  padding-top: .25rem;
}

.McHiCW_chat .McHiCW_main .McHiCW_messages .McHiCW_message .McHiCW_details {
  z-index: 1;
  background-color: #343434;
  border-radius: .5rem;
  flex: 1;
  gap: .5rem;
  padding: .5rem;
  display: flex;
}

.McHiCW_chat .McHiCW_main .McHiCW_messages .McHiCW_message .McHiCW_details.McHiCW_space-top {
  margin-top: 1.2rem;
}

.McHiCW_chat .McHiCW_main .McHiCW_messages .McHiCW_message .McHiCW_details .McHiCW_pfp {
  width: 2.5rem;
  height: 2.5rem;
}

.McHiCW_chat .McHiCW_main .McHiCW_messages .McHiCW_message .McHiCW_details .McHiCW_pfp img {
  width: 2.5rem;
  height: 2.5rem;
  box-sizing: border-box;
  border-radius: 2.5rem;
}

.McHiCW_chat .McHiCW_main .McHiCW_messages .McHiCW_message .McHiCW_details .McHiCW_pfp:hover {
  cursor: pointer;
}

.McHiCW_chat .McHiCW_main .McHiCW_messages .McHiCW_message .McHiCW_details .McHiCW_content {
  flex-direction: column;
  flex: 1;
  display: flex;
}

.McHiCW_chat .McHiCW_main .McHiCW_messages .McHiCW_message .McHiCW_details .McHiCW_content .McHiCW_info {
  justify-content: space-between;
  align-items: center;
  font-size: 1rem;
  font-weight: 700;
  display: flex;
}

.McHiCW_chat .McHiCW_main .McHiCW_messages .McHiCW_message .McHiCW_details .McHiCW_content .McHiCW_info .McHiCW_tools {
  gap: .4rem;
  font-size: 12px;
  display: flex;
}

.McHiCW_chat .McHiCW_main .McHiCW_messages .McHiCW_message .McHiCW_details .McHiCW_content .McHiCW_info .McHiCW_tools .McHiCW_tool {
  cursor: pointer;
  opacity: .7;
}

.McHiCW_chat .McHiCW_main .McHiCW_messages .McHiCW_message .McHiCW_details .McHiCW_content .McHiCW_info .McHiCW_tools .McHiCW_tool:hover {
  opacity: 1;
}

.McHiCW_chat .McHiCW_main .McHiCW_messages .McHiCW_message .McHiCW_details .McHiCW_content .McHiCW_info .McHiCW_username {
  color: #e8e8e8b3;
  align-items: center;
  gap: .3rem;
  display: flex;
}

.McHiCW_chat .McHiCW_main .McHiCW_messages .McHiCW_message .McHiCW_details .McHiCW_content .McHiCW_info .McHiCW_username .McHiCW_role {
  color: #fff;
  border-radius: .5rem;
  padding: 0 .3rem;
  font-size: 11px;
}

.McHiCW_chat .McHiCW_main .McHiCW_messages .McHiCW_message .McHiCW_details .McHiCW_content .McHiCW_info .McHiCW_username .McHiCW_role.McHiCW_administrator {
  background-color: #ce8605cc;
}

.McHiCW_chat .McHiCW_main .McHiCW_messages .McHiCW_message .McHiCW_details .McHiCW_content .McHiCW_info .McHiCW_username .McHiCW_role.McHiCW_moderator {
  background-color: #2ed573cc;
}

.McHiCW_chat .McHiCW_main .McHiCW_messages .McHiCW_message .McHiCW_details .McHiCW_content .McHiCW_info .McHiCW_username .McHiCW_role.McHiCW_streamer {
  background-color: #2f2f2fcc;
}

.McHiCW_chat .McHiCW_main .McHiCW_messages .McHiCW_message .McHiCW_details .McHiCW_content .McHiCW_text {
  word-break: break-word;
  font-size: 1rem;
  font-weight: 600;
}

.McHiCW_chat .McHiCW_main .McHiCW_actions {
  align-items: center;
  padding: 0 1rem 1rem;
  display: flex;
  position: relative;
}

.McHiCW_chat .McHiCW_main .McHiCW_actions .McHiCW_connect {
  width: 100%;
}

.McHiCW_chat .McHiCW_main .McHiCW_actions .McHiCW_reply-to {
  z-index: 0;
  height: 3rem;
  background-color: #444;
  border-radius: .5rem;
  justify-content: space-between;
  padding: 0 .5rem;
  font-size: 1rem;
  display: flex;
  position: absolute;
  top: -1.5rem;
  left: 1rem;
  right: 1.5rem;
}

.McHiCW_chat .McHiCW_main .McHiCW_actions .McHiCW_reply-to svg {
  cursor: pointer;
  padding-top: .25rem;
}

.McHiCW_chat .McHiCW_main .McHiCW_actions form {
  z-index: 1;
  flex: 1;
}

.McHiCW_chat .McHiCW_main .McHiCW_actions form button {
  height: calc(100% - 2rem);
  padding: 0 .5rem;
  font-size: .8rem;
  position: absolute;
  top: .5rem;
  bottom: 0;
  right: 1.5rem;
}

.McHiCW_chat .McHiCW_main .McHiCW_actions form textarea {
  width: 100%;
  height: 2.5rem;
  flex: 1;
  padding-right: 2.5rem;
  overflow: hidden;
}

.dImszG_connect-modal-overlay {
  background-color: #3333;
  padding: 1rem;
  font-family: Baloo Thambi !important;
}

.dImszG_connect-modal-overlay .dImszG_connect-modal {
  width: 100vw;
  height: 100vh;
  max-width: min(700px, 100vw - 2rem);
  max-height: min(500px, 100vh - 2rem);
  box-sizing: border-box;
  background-color: #2f2f2f;
  border-radius: 1rem;
  flex-direction: column;
  gap: 1rem;
  padding: 1rem;
  display: flex;
  box-shadow: 0 8px 24px #1f1f1f;
}

.dImszG_connect-modal-overlay .dImszG_connect-modal img {
  width: auto;
}

.dImszG_connect-modal-overlay .dImszG_connect-modal .dImszG_wallet {
  height: 100%;
  justify-content: center;
  align-items: center;
  font-size: 32px;
  display: flex;
}

.dImszG_connect-modal-overlay .dImszG_connect-modal .dImszG_wallet .dImszG_install-wallet {
  text-align: center;
  max-width: 300px;
  flex-direction: column;
  gap: 1rem;
  font-family: Riffic;
  font-size: 32px;
  display: flex;
}

.dImszG_connect-modal-overlay .dImszG_connect-modal .dImszG_wallet .dImszG_install-wallet small {
  font-size: 18px;
}

.dImszG_connect-modal-overlay .dImszG_connect-modal .dImszG_wallet .dImszG_install-wallet a {
  color: #1e90ff;
  text-decoration: none;
}

.dImszG_connect-modal-overlay .dImszG_connect-modal .dImszG_wallet .dImszG_form {
  width: 100%;
  max-width: 350px;
  text-align: center;
  flex-direction: column;
  gap: 1rem;
  margin: 4rem;
  display: flex;
}

.dImszG_connect-modal-overlay .dImszG_connect-modal .dImszG_wallet .dImszG_form .dImszG_purple {
  color: #1e90ff;
}

.dImszG_connect-modal-overlay .dImszG_connect-modal .dImszG_wallet .dImszG_form button {
  width: 100%;
  text-align: center;
  justify-content: center;
  font-family: Baloo Thambi;
  font-size: 18px;
  font-weight: 400;
}

.dImszG_connect-modal-overlay .dImszG_connect-modal .dImszG_wallet .dImszG_form .dImszG_change-button {
  margin-top: 1rem;
}

.dImszG_connect-modal-overlay .dImszG_connect-modal .dImszG_wallet .dImszG_form .dImszG_solana-button {
  background-color: #1e90ff;
  border-radius: .5rem;
}

.dImszG_connect-modal-overlay .dImszG_ledger-toggle {
  -webkit-user-select: none;
  user-select: none;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  gap: .5rem;
  font-size: 18px;
  font-weight: normal;
  display: flex;
}

.dImszG_connect-modal-overlay .dImszG_ledger-toggle .dImszG_toggle {
  pointer-events: none;
}

.ubbMBW_profile-modal-overlay {
  background-color: #3333;
  padding: 1rem;
  font-family: Baloo Thambi !important;
}

.ubbMBW_profile-modal-overlay .ubbMBW_profile-modal {
  width: 100vw;
  height: 100vh;
  max-width: min(700px, 100vw - 2rem);
  max-height: min(500px, 100vh - 2rem);
  box-sizing: border-box;
  background-color: #2f2f2f;
  border-radius: 1rem;
  flex-direction: column;
  gap: 1rem;
  padding: 2rem 1rem 1rem;
  font-size: 1rem;
  display: flex;
  position: relative;
  overflow: hidden;
  box-shadow: 0 8px 24px #1f1f1f;
}

.ubbMBW_profile-modal-overlay .ubbMBW_profile-modal:before {
  content: "";
  z-index: 1;
  width: 33%;
  height: 6px;
  background: #1e90ff;
  border-radius: 6px;
  margin: auto;
  position: absolute;
  top: -3px;
  left: 0;
  right: 0;
  box-shadow: 0 -2px 8px 2px #1e90ff99, 0 -4px 17px 5px #1e90ff4d;
}

.ubbMBW_profile-modal-overlay .ubbMBW_profile-modal .ubbMBW_close-button {
  height: auto;
  padding: 0;
  font-size: 1.5rem;
  position: absolute;
  top: 1rem;
  right: 1rem;
}

.ubbMBW_profile-modal-overlay .ubbMBW_profile-modal .ubbMBW_top {
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
  display: flex;
}

.ubbMBW_profile-modal-overlay .ubbMBW_profile-modal .ubbMBW_top .ubbMBW_user {
  align-items: center;
  gap: 1rem;
  font-size: 1.25rem;
  font-weight: bold;
  display: flex;
}

.ubbMBW_profile-modal-overlay .ubbMBW_profile-modal .ubbMBW_top .ubbMBW_user .ubbMBW_pfp {
  width: 5rem;
  height: 5rem;
  border-radius: 5rem;
  overflow: hidden;
}

.ubbMBW_profile-modal-overlay .ubbMBW_profile-modal .ubbMBW_top .ubbMBW_user .ubbMBW_pfp img {
  width: 100%;
}

.ubbMBW_profile-modal-overlay .ubbMBW_profile-modal .ubbMBW_top .ubbMBW_tip {
  height: 2rem;
  color: #b94700;
  white-space: nowrap;
  background: linear-gradient(260.09deg, #ffd834 4.24%, #fff4b1 97.3%);
  gap: 1rem;
  font-family: baloo thambi;
  font-weight: bold;
}

.ubbMBW_profile-modal-overlay .ubbMBW_profile-modal .ubbMBW_top .ubbMBW_tip:hover {
  cursor: pointer;
  box-shadow: 0 0 0 1px #b94700;
}

.ubbMBW_profile-modal-overlay .ubbMBW_profile-modal .ubbMBW_top .ubbMBW_tip img {
  height: 100%;
}

.ubbMBW_profile-modal-overlay .ubbMBW_profile-modal .ubbMBW_stats-container {
  flex-direction: column;
  gap: 1rem;
  display: flex;
  overflow-y: auto;
}

.ubbMBW_profile-modal-overlay .ubbMBW_profile-modal .ubbMBW_stats-container .ubbMBW_grouped {
  border-radius: .5rem;
  flex-wrap: wrap;
  gap: 1rem;
  display: flex;
}

.ubbMBW_profile-modal-overlay .ubbMBW_profile-modal .ubbMBW_stats-container .ubbMBW_grouped.ubbMBW_unified-bg {
  background: #444;
}

.ubbMBW_profile-modal-overlay .ubbMBW_profile-modal .ubbMBW_stats-container .ubbMBW_grouped .ubbMBW_stats {
  background: #444;
  border-radius: .5rem;
  flex-direction: column;
  flex: 1;
  padding: 1rem;
  display: flex;
}

.ubbMBW_profile-modal-overlay .ubbMBW_profile-modal .ubbMBW_stats-container .ubbMBW_grouped .ubbMBW_stats .ubbMBW_name {
  opacity: .7;
  white-space: nowrap;
}

.ubbMBW_profile-modal-overlay .ubbMBW_profile-modal .ubbMBW_stats-container .ubbMBW_grouped .ubbMBW_stats .ubbMBW_value {
  align-items: center;
  gap: .5rem;
  font-size: 1.25rem;
  font-weight: bold;
  display: flex;
}

.ubbMBW_profile-modal-overlay .ubbMBW_profile-modal .ubbMBW_stats-container .ubbMBW_grouped .ubbMBW_stats .ubbMBW_value svg {
  font-size: 1rem;
}

.ubbMBW_profile-modal-overlay .ubbMBW_profile-modal .ubbMBW_stats-container .ubbMBW_grouped .ubbMBW_stats .ubbMBW_value img {
  height: 1rem;
}

.ubbMBW_profile-modal-overlay .ubbMBW_profile-modal .ubbMBW_title {
  margin-top: auto;
  font-size: 1.5rem;
  font-weight: bold;
}

.UUm_xW_consent-overlay {
  position: absolute !important;
}

.UUm_xW_consent-overlay .UUm_xW_consent {
  font-family: Baloo Thambi;
  overflow-y: auto;
}

.UUm_xW_consent-overlay .UUm_xW_consent p {
  white-space: pre-line;
}

.UUm_xW_consent-overlay .UUm_xW_buttons {
  width: 100%;
  display: flex;
}

.UUm_xW_consent-overlay .UUm_xW_buttons button {
  flex: 1;
}

.UUm_xW_consent-overlay .UUm_xW_buttons button:first-child {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.UUm_xW_consent-overlay .UUm_xW_buttons button:last-child {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.UUm_xW_container-consent {
  overflow: hidden;
}

.ZseqRq_alert {
  background-color: #fff1;
  border-left: .5rem solid gray;
  border-radius: .5rem;
  align-items: center;
  gap: 2rem;
  padding: 1rem;
  font-size: smaller;
  display: flex;
}

.ZseqRq_alert .ZseqRq_content {
  flex: 1;
}

.ZseqRq_alert .ZseqRq_content img {
  max-width: 10px;
  max-height: 10px;
  padding: 0 .2rem;
}

.ZseqRq_alert .ZseqRq_close {
  cursor: pointer;
}

.ZseqRq_alert.ZseqRq_success {
  color: #2ed573;
  border-left-color: #2ed573;
}

.ZseqRq_alert.ZseqRq_info {
  color: #3bb8fa;
  border-left-color: #3bb8fa;
}

.ZseqRq_alert.ZseqRq_warning {
  color: #ce8605;
  border-left-color: #ce8605;
}

.ZseqRq_alert.ZseqRq_danger {
  color: #ff4e5f;
  border-left-color: #ff4e5f;
}

.m3fvuG_coupons {
  height: 100%;
  flex-direction: column;
  gap: 1rem;
  display: flex;
}

.m3fvuG_coupons .m3fvuG_form {
  flex-direction: column;
  display: flex;
}

.m3fvuG_coupons .m3fvuG_form .m3fvuG_claim-container {
  flex-direction: column;
  align-items: center;
  gap: .5rem;
  margin-top: 1rem;
  display: flex;
}

@media screen and (min-width: 480px) {
  .m3fvuG_coupons .m3fvuG_form .m3fvuG_claim-container {
    flex-direction: row;
  }
}

.m3fvuG_coupons .m3fvuG_form .m3fvuG_claim-container .m3fvuG_claim {
  align-items: center;
  display: flex;
}

.m3fvuG_coupons .m3fvuG_form .m3fvuG_claim-container .m3fvuG_claim img {
  width: 10px;
  height: 10px;
}

.m3fvuG_coupons .m3fvuG_form .m3fvuG_claim-container .m3fvuG_claim small {
  opacity: .5;
}

.m3fvuG_coupons .m3fvuG_form .m3fvuG_claim-container .m3fvuG_claim button {
  height: auto;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  padding: .25rem .5rem;
}

.m3fvuG_coupons .m3fvuG_form .m3fvuG_claim-container .m3fvuG_claim .m3fvuG_value {
  background-color: #242424;
  border-top-left-radius: .5rem;
  border-bottom-left-radius: .5rem;
  padding: .25rem .5rem;
}

.m3fvuG_coupons .m3fvuG_form .m3fvuG_name:not(:first-child) {
  margin-top: 1rem;
}

.m3fvuG_coupons .m3fvuG_form .m3fvuG_entry {
  display: flex;
}

.m3fvuG_coupons .m3fvuG_form .m3fvuG_entry input {
  min-width: 0;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: .5rem;
  flex: 1;
}

.m3fvuG_coupons .m3fvuG_form .m3fvuG_entry button {
  min-width: 6rem;
  height: 100%;
  border-top-left-radius: 0;
  border-top-right-radius: .5rem;
  border-bottom-left-radius: 0;
}

.m3fvuG_coupons .m3fvuG_form .m3fvuG_used-by {
  font-size: .8rem;
}

.m3fvuG_coupons .m3fvuG_form .m3fvuG_used-by a {
  color: #e8e8e8;
}

.FLuwzW_referral-modal-overlay {
  background-color: #3333;
  padding: 1rem;
  font-size: 1rem;
  font-family: Baloo Thambi !important;
}

.FLuwzW_referral-modal-overlay .FLuwzW_referral-modal {
  width: 100vw;
  height: 100vh;
  max-width: min(700px, 100vw - 2rem);
  max-height: min(500px, 100vh - 2rem);
  box-sizing: border-box;
  background-color: #2f2f2f;
  border-radius: 1rem;
  flex-direction: column;
  gap: .25rem;
  padding: 1rem;
  display: flex;
  box-shadow: 0 8px 24px #1f1f1f;
}

.FLuwzW_referral-modal-overlay .FLuwzW_referral-modal .FLuwzW_list {
  overflow: auto;
}

.FLuwzW_referral-modal-overlay .FLuwzW_referral-modal .FLuwzW_list img {
  height: 10px;
}

.QrjaKa_deposit {
  height: 100%;
  flex-direction: column;
  justify-content: space-between;
  gap: 1rem;
  display: flex;
}

.QrjaKa_deposit .QrjaKa_content {
  flex-direction: column;
  gap: 1rem;
  display: flex;
}

.QrjaKa_deposit .QrjaKa_content .QrjaKa_form {
  grid-template-columns: 1fr;
  gap: 1rem;
  display: grid;
}

@media screen and (min-width: 480px) {
  .QrjaKa_deposit .QrjaKa_content .QrjaKa_form {
    grid-template-columns: 1fr 1fr;
  }
}

.QrjaKa_deposit .QrjaKa_content .QrjaKa_form .QrjaKa_input-icon {
  flex: 1;
  align-items: center;
  display: flex;
  position: relative;
}

.QrjaKa_deposit .QrjaKa_content .QrjaKa_form .QrjaKa_input-icon .QrjaKa_clickable {
  cursor: pointer;
}

.QrjaKa_deposit .QrjaKa_content .QrjaKa_form .QrjaKa_input-icon button {
  width: 3rem;
  height: calc(100% - 1rem);
  position: absolute;
  top: .5rem;
  bottom: 0;
  right: .5rem;
}

.QrjaKa_deposit .QrjaKa_content .QrjaKa_form .QrjaKa_input-icon .QrjaKa_left {
  align-items: center;
  display: flex;
  position: absolute;
  left: .5rem;
}

.QrjaKa_deposit .QrjaKa_content .QrjaKa_form .QrjaKa_input-icon svg, .QrjaKa_deposit .QrjaKa_content .QrjaKa_form .QrjaKa_input-icon img {
  width: 1rem;
  height: 1rem;
}

.QrjaKa_deposit .QrjaKa_content .QrjaKa_form .QrjaKa_input-icon svg.QrjaKa_selector, .QrjaKa_deposit .QrjaKa_content .QrjaKa_form .QrjaKa_input-icon img.QrjaKa_selector {
  width: .4em;
  height: .75em;
  opacity: .8;
  margin-right: .2em;
}

.QrjaKa_deposit .QrjaKa_content .QrjaKa_form .QrjaKa_input-icon input {
  width: 100%;
  flex: 1;
  padding-left: 2.5rem;
  padding-right: 2.5rem;
}

.QrjaKa_deposit .QrjaKa_content .QrjaKa_form .QrjaKa_input-icon.QrjaKa_to-amount {
  cursor: not-allowed;
  opacity: .5;
}

.QrjaKa_deposit .QrjaKa_content .QrjaKa_form .QrjaKa_input-icon.QrjaKa_to-amount input {
  pointer-events: none;
}

.QrjaKa_deposit .QrjaKa_content .QrjaKa_special-offer {
  color: #fff;
  background: #e9a200;
  border-radius: .5rem;
  align-items: center;
  gap: .5rem;
  padding: .5rem;
  font-family: Baloo Thambi;
  font-size: 1rem;
  animation: 1s forwards QrjaKa_appear;
  display: flex;
}

.QrjaKa_deposit .QrjaKa_content .QrjaKa_special-offer .QrjaKa_title {
  font-weight: 700;
}

.QrjaKa_deposit .QrjaKa_content .QrjaKa_special-offer .QrjaKa_text {
  flex: 1;
}

.QrjaKa_deposit .QrjaKa_content .QrjaKa_special-offer .QrjaKa_text svg {
  opacity: .8;
}

.QrjaKa_deposit .QrjaKa_content .QrjaKa_special-offer .QrjaKa_text a {
  color: #fff;
  padding-left: 1rem;
}

.QrjaKa_deposit .QrjaKa_content .QrjaKa_special-offer .QrjaKa_action button {
  height: 2rem;
  background-color: #e5a413;
}

.QrjaKa_deposit .QrjaKa_content .QrjaKa_special-offer .QrjaKa_action button:hover {
  background-color: #c78c0c;
}

._50dmgW_history {
  height: 100%;
  flex-direction: column;
  justify-content: space-between;
  gap: 1rem;
  display: flex;
}

._50dmgW_history ._50dmgW_content {
  height: 100%;
  grid-template-rows: 1fr 1fr;
  grid-template-columns: auto;
  gap: 2rem;
  display: grid;
  overflow: hidden;
}

@media screen and (min-width: 480px) {
  ._50dmgW_history ._50dmgW_content {
    grid-template-rows: auto;
    grid-template-columns: 1fr 1fr;
  }
}

._50dmgW_history ._50dmgW_content ._50dmgW_column {
  flex-direction: column;
  gap: .5rem;
  display: flex;
  overflow: hidden;
}

._50dmgW_history ._50dmgW_content ._50dmgW_column ._50dmgW_header {
  text-align: center;
}

._50dmgW_history ._50dmgW_content ._50dmgW_column ._50dmgW_list {
  grid-template-columns: 1fr 1fr auto;
  display: grid;
  overflow-y: auto;
}

._50dmgW_history ._50dmgW_content ._50dmgW_column ._50dmgW_list img {
  width: auto;
  height: auto;
  max-height: 1rem;
}

.Z6UaYG_withdraw {
  height: 100%;
  flex-direction: column;
  justify-content: space-between;
  gap: 1rem;
  display: flex;
}

.Z6UaYG_withdraw .Z6UaYG_content {
  flex-direction: column;
  gap: 1rem;
  display: flex;
}

.Z6UaYG_withdraw .Z6UaYG_content .Z6UaYG_form {
  grid-template-columns: 1fr;
  gap: 1rem;
  display: grid;
}

@media screen and (min-width: 480px) {
  .Z6UaYG_withdraw .Z6UaYG_content .Z6UaYG_form {
    grid-template-columns: 1fr 1fr;
  }
}

.Z6UaYG_withdraw .Z6UaYG_content .Z6UaYG_form .Z6UaYG_input-icon {
  flex: 1;
  align-items: center;
  display: flex;
  position: relative;
}

.Z6UaYG_withdraw .Z6UaYG_content .Z6UaYG_form .Z6UaYG_input-icon .Z6UaYG_clickable {
  cursor: pointer;
}

.Z6UaYG_withdraw .Z6UaYG_content .Z6UaYG_form .Z6UaYG_input-icon button {
  width: 3rem;
  height: calc(100% - 1rem);
  position: absolute;
  top: .5rem;
  bottom: 0;
  right: .5rem;
}

.Z6UaYG_withdraw .Z6UaYG_content .Z6UaYG_form .Z6UaYG_input-icon .Z6UaYG_left {
  align-items: center;
  display: flex;
  position: absolute;
  left: .5rem;
}

.Z6UaYG_withdraw .Z6UaYG_content .Z6UaYG_form .Z6UaYG_input-icon svg, .Z6UaYG_withdraw .Z6UaYG_content .Z6UaYG_form .Z6UaYG_input-icon img {
  width: 1rem;
  height: 1rem;
}

.Z6UaYG_withdraw .Z6UaYG_content .Z6UaYG_form .Z6UaYG_input-icon svg.Z6UaYG_selector, .Z6UaYG_withdraw .Z6UaYG_content .Z6UaYG_form .Z6UaYG_input-icon img.Z6UaYG_selector {
  width: .4em;
  height: .75em;
  opacity: .8;
  margin-right: .2em;
}

.Z6UaYG_withdraw .Z6UaYG_content .Z6UaYG_form .Z6UaYG_input-icon input {
  width: 100%;
  flex: 1;
  padding-left: 2.5rem;
  padding-right: 2.5rem;
}

.Z6UaYG_withdraw .Z6UaYG_content .Z6UaYG_form .Z6UaYG_input-icon.Z6UaYG_to-amount {
  cursor: not-allowed;
  opacity: .5;
}

.Z6UaYG_withdraw .Z6UaYG_content .Z6UaYG_form .Z6UaYG_input-icon.Z6UaYG_to-amount input {
  pointer-events: none;
}

.xJHZ_q_wallet-overlay {
  background-color: #3333;
  padding: 1rem;
  font-family: Baloo Thambi !important;
}

.xJHZ_q_wallet-overlay .xJHZ_q_wallet {
  width: 100vw;
  height: 100vh;
  max-width: min(700px, 100vw - 2rem);
  max-height: min(500px, 100vh - 2rem);
  box-sizing: border-box;
  background-color: #2f2f2f;
  border-radius: 1rem;
  flex-direction: column;
  gap: 1rem;
  padding: 1rem;
  display: flex;
  box-shadow: 0 8px 24px #1f1f1f;
}

.xJHZ_q_wallet-overlay .xJHZ_q_wallet .xJHZ_q_menu {
  max-width: 100%;
  scrollbar-width: none;
  gap: 1rem;
  display: flex;
  overflow-x: auto;
}

.xJHZ_q_wallet-overlay .xJHZ_q_wallet .xJHZ_q_menu::-webkit-scrollbar {
  display: none;
}

.xJHZ_q_wallet-overlay .xJHZ_q_wallet .xJHZ_q_menu button {
  text-transform: uppercase;
  box-sizing: border-box;
  white-space: nowrap;
  border-bottom: 2px solid #0000;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
  position: relative;
}

.xJHZ_q_wallet-overlay .xJHZ_q_wallet .xJHZ_q_menu button:before {
  content: "";
  width: 50%;
  height: 4px;
  opacity: 0;
  background: #1e90ff;
  border-radius: 4px;
  margin: auto;
  transition: all .3s;
  position: absolute;
  bottom: -4px;
  left: 0;
  right: 0;
}

.xJHZ_q_wallet-overlay .xJHZ_q_wallet .xJHZ_q_menu button.xJHZ_q_active {
  background-color: #343434;
}

.xJHZ_q_wallet-overlay .xJHZ_q_wallet .xJHZ_q_menu button.xJHZ_q_active:before {
  opacity: 1;
  box-shadow: 0 -1px 10px 2px #1e90ff66, 0 -2px 20px 5px #1e90ff33;
}

.xJHZ_q_wallet-overlay .xJHZ_q_wallet .xJHZ_q_content {
  flex: 1;
  font-size: 1rem;
  position: relative;
  overflow: hidden;
}

.xJHZ_q_wallet-overlay .xJHZ_q_wallet .xJHZ_q_content .xJHZ_q_consent {
  font-size: 1rem;
}

.tb19nW_dashboard {
  color: #e8e8e8;
  width: 100%;
  font-family: Baloo Thambi;
  font-size: 20px;
  display: flex;
  position: relative;
}

.tb19nW_dashboard.tb19nW_player-view {
  height: 100vh;
}

.tb19nW_dashboard.tb19nW_player-view .tb19nW_menu, .tb19nW_dashboard.tb19nW_player-view .tb19nW_container .tb19nW_toolbar {
  display: none;
}

.tb19nW_dashboard.tb19nW_player-view .tb19nW_container .tb19nW_content {
  max-height: 100vh;
  padding: 0;
  overflow: hidden;
}

.tb19nW_dashboard .tb19nW_menu {
  width: 100vw;
  height: 100vh;
  z-index: 101;
  visibility: hidden;
  opacity: 0;
  -webkit-backdrop-filter: blur(1rem);
  backdrop-filter: blur(1rem);
  background-color: #222c;
  justify-content: center;
  transition: all .3s;
  display: flex;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
}

@media screen and (min-width: 768px) {
  .tb19nW_dashboard .tb19nW_menu {
    z-index: 16;
  }
}

@media screen and (max-width: 768px) {
  .tb19nW_dashboard .tb19nW_menu.tb19nW_opened, .tb19nW_dashboard .tb19nW_menu.tb19nW_opened .tb19nW_menu-content {
    opacity: 1;
    visibility: visible;
  }
}

.tb19nW_dashboard .tb19nW_menu .tb19nW_menu-content {
  visibility: hidden;
  opacity: 0;
  width: 80vw;
  max-width: 350px;
  flex-direction: column;
  align-items: center;
  padding: .5rem;
  transition: all .3s;
  display: flex;
}

.tb19nW_dashboard .tb19nW_menu .tb19nW_menu-content .tb19nW_logo {
  opacity: .7;
  text-align: center;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: .75rem;
  display: flex;
}

.tb19nW_dashboard .tb19nW_menu .tb19nW_menu-content .tb19nW_logo img {
  width: 3rem;
  filter: invert();
}

.tb19nW_dashboard .tb19nW_menu .tb19nW_menu-content .tb19nW_logo a {
  color: inherit;
  text-decoration: none;
}

.tb19nW_dashboard .tb19nW_menu .tb19nW_menu-content .tb19nW_items {
  width: 100%;
  flex-direction: column;
  flex: 1;
  gap: 1rem;
  padding-top: 2rem;
  display: flex;
}

.tb19nW_dashboard .tb19nW_menu .tb19nW_menu-content .tb19nW_item {
  height: 4rem;
  box-sizing: border-box;
  color: #e8e8e8;
  border-radius: .5rem;
  align-items: center;
  padding: 1rem 2rem;
  text-decoration: none;
  transition: all .3s;
  display: flex;
  position: relative;
  overflow: hidden;
}

.tb19nW_dashboard .tb19nW_menu .tb19nW_menu-content .tb19nW_item:before {
  content: "";
  width: 6px;
  height: 50%;
  border-radius: 6px;
  margin: auto;
  transition: all .3s;
  position: absolute;
  top: 0;
  bottom: 0;
  left: -3px;
}

.tb19nW_dashboard .tb19nW_menu .tb19nW_menu-content .tb19nW_item:hover:not(.tb19nW_disabled):not(.tb19nW_active) {
  background-color: #2f2f2f;
}

.tb19nW_dashboard .tb19nW_menu .tb19nW_menu-content .tb19nW_item.tb19nW_disabled {
  cursor: not-allowed;
  opacity: .5;
}

.tb19nW_dashboard .tb19nW_menu .tb19nW_menu-content .tb19nW_item.tb19nW_active {
  background-color: #2f2f2f;
}

.tb19nW_dashboard .tb19nW_menu .tb19nW_menu-content .tb19nW_item.tb19nW_active:before {
  content: "";
  width: 6px;
  height: 50%;
  background-color: #1e90ff;
  border-radius: 6px;
  margin: auto;
  position: absolute;
  top: 0;
  bottom: 0;
  left: -3px;
  box-shadow: 2px 0 10px 4px #1e90ff66, 4px 0 35px 11px #1e90ff33;
}

.tb19nW_dashboard .tb19nW_menu .tb19nW_menu-content .tb19nW_item.tb19nW_profile {
  margin-top: auto;
}

.tb19nW_dashboard .tb19nW_menu .tb19nW_menu-content .tb19nW_item .tb19nW_icon {
  flex: 1;
  justify-content: center;
  align-items: center;
  display: flex;
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .tb19nW_dashboard .tb19nW_menu .tb19nW_menu-content .tb19nW_item .tb19nW_icon {
    width: auto;
  }
}

.tb19nW_dashboard .tb19nW_menu .tb19nW_menu-content .tb19nW_item .tb19nW_pfp {
  height: 100%;
  flex: 1;
  justify-content: center;
  align-items: center;
  display: flex;
}

.tb19nW_dashboard .tb19nW_menu .tb19nW_menu-content .tb19nW_item .tb19nW_pfp img {
  height: 100%;
  border-radius: 100%;
}

.tb19nW_dashboard .tb19nW_menu .tb19nW_menu-content .tb19nW_item .tb19nW_text {
  flex: 1;
  font-size: 1.05rem;
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .tb19nW_dashboard .tb19nW_menu .tb19nW_menu-content .tb19nW_item {
    justify-content: center;
  }
}

@media screen and (min-width: 768px) {
  .tb19nW_dashboard .tb19nW_menu .tb19nW_menu-content {
    width: auto;
    min-width: 3rem;
    max-width: 250px;
    background-color: #242424;
  }

  .tb19nW_dashboard .tb19nW_menu .tb19nW_menu-content .tb19nW_item .tb19nW_text {
    width: 0;
    opacity: 0;
    flex: 0;
    transition: width .3s, margin-left .3s, opacity .3s;
  }

  .tb19nW_dashboard .tb19nW_menu .tb19nW_menu-content .tb19nW_item .tb19nW_icon, .tb19nW_dashboard .tb19nW_menu .tb19nW_menu-content .tb19nW_item .tb19nW_pfp {
    width: 1rem;
    flex: 0;
  }

  .tb19nW_dashboard .tb19nW_menu .tb19nW_menu-content:hover {
    max-width: 250px;
  }

  .tb19nW_dashboard .tb19nW_menu .tb19nW_menu-content:hover .tb19nW_item .tb19nW_text {
    opacity: 1;
    width: 60px;
    flex: 0;
    margin-left: 2rem;
    transition: width .3s .3s, margin-left .3s .3s, opacity .3s .4s;
  }

  .tb19nW_dashboard .tb19nW_menu {
    width: auto;
    opacity: 1;
    position: sticky;
  }

  .tb19nW_dashboard .tb19nW_menu .tb19nW_menu-content {
    visibility: visible;
    opacity: 1;
  }
}

.tb19nW_dashboard .tb19nW_container {
  max-width: 100vw;
  min-height: 100vh;
  flex-direction: column;
  flex: 1;
  align-items: center;
  margin: 0 auto;
  display: flex;
}

.tb19nW_dashboard .tb19nW_container.tb19nW_limited-height {
  max-height: 100vh;
  overflow: hidden;
}

.tb19nW_dashboard .tb19nW_container.tb19nW_limited-height .tb19nW_toolbar {
  height: 2rem;
  padding: .5rem;
}

.tb19nW_dashboard .tb19nW_container.tb19nW_limited-height .tb19nW_toolbar .tb19nW_menu-button svg {
  font-size: 1rem;
}

.tb19nW_dashboard .tb19nW_container.tb19nW_limited-height .tb19nW_toolbar .tb19nW_actions {
  right: .5rem;
}

@media screen and (min-width: 768px) {
  .tb19nW_dashboard .tb19nW_container.tb19nW_limited-height .tb19nW_toolbar {
    height: 4rem;
    padding: 1rem;
  }

  .tb19nW_dashboard .tb19nW_container.tb19nW_limited-height .tb19nW_toolbar .tb19nW_actions {
    right: 1rem;
  }

  .tb19nW_dashboard .tb19nW_container.tb19nW_limited-height .tb19nW_toolbar .tb19nW_menu-button svg {
    font-size: 2rem;
  }
}

.tb19nW_dashboard .tb19nW_container .tb19nW_toolbar {
  z-index: 2;
  width: 100%;
  height: 4rem;
  box-sizing: border-box;
  background-color: #2f2f2f;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  display: flex;
  position: sticky;
  top: 0;
}

.tb19nW_dashboard .tb19nW_container .tb19nW_toolbar .tb19nW_title {
  visibility: hidden;
  align-items: center;
  gap: 1rem;
  font-size: larger;
  font-weight: 600;
  display: flex;
}

@media screen and (min-width: 768px) {
  .tb19nW_dashboard .tb19nW_container .tb19nW_toolbar .tb19nW_title {
    visibility: visible;
  }
}

.tb19nW_dashboard .tb19nW_container .tb19nW_toolbar .tb19nW_title .tb19nW_help {
  cursor: pointer;
  opacity: .4;
  font-size: 1rem;
}

.tb19nW_dashboard .tb19nW_container .tb19nW_toolbar .tb19nW_title .tb19nW_help:hover {
  opacity: 1;
}

.tb19nW_dashboard .tb19nW_container .tb19nW_toolbar .tb19nW_actions {
  display: flex;
  position: absolute;
  right: 1rem;
}

.tb19nW_dashboard .tb19nW_container .tb19nW_toolbar .tb19nW_actions .tb19nW_action {
  height: auto;
  border-radius: .5rem;
  justify-content: center;
  align-items: center;
  gap: .75rem;
  padding: 0 .75rem;
  font-size: 1.25rem;
  display: flex;
}

.tb19nW_dashboard .tb19nW_container .tb19nW_toolbar .tb19nW_actions .tb19nW_action.tb19nW_sound button {
  height: 100%;
}

.tb19nW_dashboard .tb19nW_container .tb19nW_toolbar .tb19nW_actions .tb19nW_action.tb19nW_yooh {
  color: #b94700;
  background: linear-gradient(260.09deg, #ffd834 4.24%, #fff4b1 97.3%);
  font-weight: bold;
}

.tb19nW_dashboard .tb19nW_container .tb19nW_toolbar .tb19nW_actions .tb19nW_action.tb19nW_yooh:hover {
  cursor: pointer;
  box-shadow: 0 0 0 1px #b94700;
}

.tb19nW_dashboard .tb19nW_container .tb19nW_toolbar .tb19nW_actions .tb19nW_action.tb19nW_yooh .tb19nW_separator {
  height: 55%;
  border: 1px solid #b94700;
}

.tb19nW_dashboard .tb19nW_container .tb19nW_toolbar .tb19nW_actions .tb19nW_action.tb19nW_yooh .tb19nW_yooh-container {
  justify-content: center;
  align-items: center;
  gap: .5rem;
  display: flex;
}

@media screen and (min-width: 768px) {
  .tb19nW_dashboard .tb19nW_container .tb19nW_toolbar .tb19nW_actions .tb19nW_action.tb19nW_yooh .tb19nW_yooh-container {
    gap: .75rem;
  }
}

.tb19nW_dashboard .tb19nW_container .tb19nW_toolbar .tb19nW_actions .tb19nW_action.tb19nW_yooh img {
  height: 1rem;
}

@media screen and (min-width: 768px) {
  .tb19nW_dashboard .tb19nW_container .tb19nW_toolbar .tb19nW_actions .tb19nW_action.tb19nW_yooh img {
    height: 1.25rem;
  }
}

.tb19nW_dashboard .tb19nW_container .tb19nW_toolbar .tb19nW_actions .tb19nW_action.tb19nW_yooh .tb19nW_wallet {
  height: 1rem;
}

@media screen and (min-width: 768px) {
  .tb19nW_dashboard .tb19nW_container .tb19nW_toolbar .tb19nW_actions .tb19nW_action.tb19nW_yooh .tb19nW_wallet {
    height: 1.15rem;
  }
}

.tb19nW_dashboard .tb19nW_container .tb19nW_toolbar .tb19nW_actions .tb19nW_action.tb19nW_yaah {
  color: #4b299a;
  background: linear-gradient(104.68deg, #e4d9fd .46%, #c8c0da 96.39%);
  margin-left: .5rem;
  font-weight: bold;
}

@media screen and (min-width: 768px) {
  .tb19nW_dashboard .tb19nW_container .tb19nW_toolbar .tb19nW_actions .tb19nW_action.tb19nW_yaah {
    margin-left: .75rem;
  }
}

.tb19nW_dashboard .tb19nW_container .tb19nW_toolbar .tb19nW_actions .tb19nW_action.tb19nW_yaah img {
  height: 1rem;
}

@media screen and (min-width: 768px) {
  .tb19nW_dashboard .tb19nW_container .tb19nW_toolbar .tb19nW_actions .tb19nW_action.tb19nW_yaah img {
    height: 1.25rem;
  }
}

.tb19nW_dashboard .tb19nW_container .tb19nW_toolbar .tb19nW_actions .tb19nW_action.tb19nW_chat-action {
  background: #3331;
  margin-left: .5rem;
  font-weight: bold;
}

@media screen and (min-width: 768px) {
  .tb19nW_dashboard .tb19nW_container .tb19nW_toolbar .tb19nW_actions .tb19nW_action.tb19nW_chat-action {
    margin-left: .75rem;
  }
}

.tb19nW_dashboard .tb19nW_container .tb19nW_toolbar .tb19nW_actions .tb19nW_action.tb19nW_chat-action:hover {
  cursor: pointer;
  box-shadow: 0 0 0 1px #3333;
}

.tb19nW_dashboard .tb19nW_container .tb19nW_toolbar .tb19nW_actions .tb19nW_action.tb19nW_connect {
  font-size: 1rem;
}

@media screen and (min-width: 768px) {
  .tb19nW_dashboard .tb19nW_container .tb19nW_toolbar .tb19nW_actions .tb19nW_action.tb19nW_connect {
    height: 2rem;
  }
}

.tb19nW_dashboard .tb19nW_container .tb19nW_toolbar .tb19nW_actions .tb19nW_action img {
  height: 1rem;
}

.tb19nW_dashboard .tb19nW_container .tb19nW_toolbar .tb19nW_actions .tb19nW_chat-action-container {
  display: flex;
}

@media screen and (min-width: 1200px) {
  .tb19nW_dashboard .tb19nW_container .tb19nW_toolbar .tb19nW_actions .tb19nW_chat-action-container {
    transition: opacity .3s, width .6s;
  }

  .tb19nW_dashboard .tb19nW_container .tb19nW_toolbar .tb19nW_actions .tb19nW_chat-action-container.tb19nW_show {
    width: 60px;
  }

  .tb19nW_dashboard .tb19nW_container .tb19nW_toolbar .tb19nW_actions .tb19nW_chat-action-container.tb19nW_hidden {
    width: 0;
    opacity: 0;
    pointer-events: none;
  }
}

.tb19nW_dashboard .tb19nW_container .tb19nW_toolbar .tb19nW_menu-button {
  color: #e8e8e8;
}

.tb19nW_dashboard .tb19nW_container .tb19nW_toolbar .tb19nW_menu-button.tb19nW_invisible {
  visibility: hidden;
}

@media screen and (min-width: 768px) {
  .tb19nW_dashboard .tb19nW_container .tb19nW_toolbar .tb19nW_menu-button {
    display: none;
  }
}

.tb19nW_dashboard .tb19nW_container .tb19nW_content {
  width: 100%;
  box-sizing: border-box;
  flex: 1;
  justify-content: center;
  padding: 1rem;
  display: flex;
}

.tb19nW_dashboard .tb19nW_chat-container {
  z-index: 17;
  width: 0;
  height: 100%;
  opacity: 0;
  max-height: 100vh;
  transition: opacity .3s;
  position: fixed;
  top: 0;
  right: 0;
  overflow: hidden;
}

.tb19nW_dashboard .tb19nW_chat-container.tb19nW_open {
  opacity: 1;
  width: 324px;
  max-width: 100%;
}

@media screen and (min-width: 1200px) {
  .tb19nW_dashboard .tb19nW_chat-container {
    height: auto;
    transition: opacity .3s, width .6s;
    position: sticky;
  }

  .tb19nW_dashboard .tb19nW_chat-container.tb19nW_open {
    transition: opacity 3s, width 1s;
  }
}

.tb19nW_dashboard .tb19nW_chat-container .tb19nW_chat-animated-container {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  background-color: #242424;
  flex: 1;
  display: flex;
}

.tb19nW_dashboard .tb19nW_testing {
  color: #ff4e5f;
  font-size: 1rem;
  position: absolute;
  bottom: 1rem;
  right: 1rem;
}

@keyframes tb19nW_chat-opening {
  from {
    width: 0;
  }

  to {
    width: 100%;
  }
}

.n1LLDG_app-card {
  z-index: 1;
  width: 100%;
  perspective: 1000px;
  border-radius: 1rem;
  position: relative;
}

.n1LLDG_app-card__cover {
  z-index: 1;
  width: 100%;
  height: 100%;
  perspective-origin: 50%;
  transform-style: preserve-3d;
  transform-origin: top;
  will-change: transform;
  background-image: linear-gradient(120deg, #65f69d 0%, #fda085 100%);
  background-size: cover;
  border-radius: 1rem;
  transition: transform .35s ease-in-out;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
  transform: skewX(.001deg);
}

.n1LLDG_app-card__cover:after {
  content: "";
  z-index: 100;
  width: 100%;
  height: 120%;
  will-change: transform;
  background: linear-gradient(226deg, #fff6 0% 35%, #fff3 42%, #fff0 60%);
  border-radius: 1rem;
  transition: transform .65s cubic-bezier(.18, .9, .58, 1);
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  transform: translateY(-20%);
}

.n1LLDG_app-card__cover .n1LLDG_badge {
  z-index: 1;
  height: 1rem;
  color: #fff;
  border-radius: 2rem;
  align-items: center;
  gap: .5rem;
  padding: .25rem .5rem;
  font-family: Baloo Thambi;
  font-size: 1rem;
  font-weight: 600;
  display: flex;
  position: absolute;
  bottom: .5rem;
  left: .5rem;
}

.n1LLDG_app-card__cover .n1LLDG_badge img {
  height: 1rem;
}

.n1LLDG_app-card__cover .n1LLDG_badge.n1LLDG_luck {
  background: linear-gradient(93.29deg, #3ca909 12.08%, #358510 91.15%);
  border: 2px solid #8dd86a;
  box-shadow: 0 0 3.67453px #00000026;
}

.n1LLDG_app-card__cover .n1LLDG_badge.n1LLDG_skill {
  background: linear-gradient(93.78deg, #ffb00d 29.7%, #de9600 92.7%);
  border: 2px solid #fbdfa7;
  box-shadow: 0 0 3.67453px #00000026;
}

.n1LLDG_app-card__cover .n1LLDG_badge.n1LLDG_label {
  opacity: .7;
}

.n1LLDG_app-card:hover .n1LLDG_app-card__cover {
  transform: rotateX(7deg)translateY(-6px);
}

.n1LLDG_app-card:hover .n1LLDG_app-card__cover:after {
  transform: translateY(0%);
}

.n1LLDG_app-card:before {
  content: "";
  width: 90%;
  height: 90%;
  will-change: opacity;
  transform-origin: top;
  background: #00000080;
  border-radius: 1rem;
  transition: transform .35s ease-in-out, opacity .5s ease-in-out;
  display: block;
  position: absolute;
  top: 5%;
  left: 5%;
  transform: skewX(.001deg);
  box-shadow: 0 6px 12px 12px #0006;
}

.n1LLDG_app-card:hover:before {
  opacity: .6;
  transform: rotateX(7deg)translateY(-6px)scale(1.05);
}

.n1LLDG_app-card .n1LLDG_text {
  width: 100%;
  background-color: red;
  position: absolute;
}

.Gr_mfq_games {
  color: #333;
  max-width: 1200px;
  flex: 1;
  font-family: Riffic;
  font-size: 32px;
  display: flex;
}

.Gr_mfq_games .Gr_mfq_grid {
  grid-gap: 2rem;
  width: 100%;
  height: -moz-fit-content;
  height: fit-content;
  grid-template-columns: 1fr 1fr;
  display: grid;
}

.Gr_mfq_games .Gr_mfq_grid > * {
  width: 100%;
  aspect-ratio: 1 / 1;
  cursor: pointer;
  margin: 0 auto;
}

@media screen and (min-width: 768px) {
  .Gr_mfq_games .Gr_mfq_grid {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media screen and (min-width: 1200px) {
  .Gr_mfq_games .Gr_mfq_grid {
    grid-template-columns: repeat(4, 1fr);
  }
}

.Gr_mfq_games .Gr_mfq_player {
  width: 100%;
  height: 100%;
  max-width: 480px;
}

.Gr_mfq_games .Gr_mfq_player canvas {
  max-width: 100%;
  max-height: 100%;
}

.XLrUjG_classic-leaderboard {
  width: 100%;
  height: 100%;
  -webkit-backdrop-filter: blur(.5rem);
  backdrop-filter: blur(.5rem);
  box-sizing: border-box;
  color: #e8e8e8;
  background: #fff8;
  gap: 1rem;
  padding: 1rem;
  display: flex;
  position: absolute;
}

.XLrUjG_classic-leaderboard .XLrUjG_leaderboard {
  flex-direction: column;
  flex: 1;
  gap: 1rem;
  display: flex;
}

.XLrUjG_classic-leaderboard .XLrUjG_leaderboard .XLrUjG_rank {
  color: #fff;
  text-align: center;
  background-color: #512da8;
  border-radius: .5rem;
  padding: .5rem;
  font-weight: normal;
}

.XLrUjG_classic-leaderboard .XLrUjG_leaderboard .XLrUjG_leaders {
  flex: 1;
}

.XLrUjG_classic-leaderboard .XLrUjG_leaderboard .XLrUjG_leaders .XLrUjG_leaders-grid {
  grid-template-columns: auto 1fr auto;
  align-items: center;
  gap: .5rem;
  display: grid;
  overflow-y: auto;
}

.XLrUjG_classic-leaderboard .XLrUjG_leaderboard .XLrUjG_leaders .XLrUjG_leaders-grid .XLrUjG_header {
  font-style: italic;
}

.XLrUjG_classic-leaderboard .XLrUjG_leaderboard .XLrUjG_leaders .XLrUjG_leaders-grid .XLrUjG_you {
  color: #ce8605;
}

.XLrUjG_classic-leaderboard .XLrUjG_leaderboard .XLrUjG_actions {
  display: flex;
}

.XLrUjG_classic-leaderboard .XLrUjG_leaderboard .XLrUjG_actions .XLrUjG_exit-button {
  color: #333;
  flex: 1;
}

.XLrUjG_classic-leaderboard .XLrUjG_leaderboard .XLrUjG_actions .XLrUjG_exit-button:hover:not(:disabled), .XLrUjG_classic-leaderboard .XLrUjG_leaderboard .XLrUjG_actions .XLrUjG_exit-button.XLrUjG_selected {
  background-color: #3331 !important;
}

.Y1RsFq_position-absolute {
  position: absolute !important;
}

.Y1RsFq_bubble-create-room-modal {
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  text-align: center;
  color: #333;
  background-color: #fffa;
  flex-direction: column;
  gap: 1rem;
  padding: 1rem;
  font-size: 1rem;
  display: flex;
}

.Y1RsFq_bubble-create-room-modal input {
  color: #666;
  background-color: #fff;
}

.Y1RsFq_bubble-create-room-modal .Y1RsFq_form {
  flex: 1;
  display: flex;
  overflow-y: auto;
}

.Y1RsFq_bubble-create-room-modal .Y1RsFq_form .Y1RsFq_required {
  color: #ff4e5f;
}

.Y1RsFq_bubble-create-room-modal .Y1RsFq_form .Y1RsFq_grid {
  height: -moz-fit-content;
  height: fit-content;
  text-align: left;
  flex: 1;
  grid-template-columns: auto;
  place-items: center start;
  gap: 1rem;
  display: grid;
}

.Y1RsFq_bubble-create-room-modal .Y1RsFq_form .Y1RsFq_grid > input, .Y1RsFq_bubble-create-room-modal .Y1RsFq_form .Y1RsFq_grid > button {
  width: 100%;
}

.Y1RsFq_bubble-create-room-modal .Y1RsFq_form .Y1RsFq_grid .Y1RsFq_multi-button {
  width: 100%;
  display: flex;
}

.Y1RsFq_bubble-create-room-modal .Y1RsFq_form .Y1RsFq_grid .Y1RsFq_multi-button > button {
  background-color: #0000;
  flex: 1;
  color: #333 !important;
}

.Y1RsFq_bubble-create-room-modal .Y1RsFq_form .Y1RsFq_grid .Y1RsFq_multi-button > button:hover:not(:disabled), .Y1RsFq_bubble-create-room-modal .Y1RsFq_form .Y1RsFq_grid .Y1RsFq_multi-button > button.Y1RsFq_selected {
  background-color: #3331 !important;
}

.Y1RsFq_bubble-create-room-modal .Y1RsFq_form .Y1RsFq_grid .Y1RsFq_multi-button > button img {
  height: 100%;
}

.Y1RsFq_bubble-create-room-modal .Y1RsFq_form .Y1RsFq_grid .Y1RsFq_multi-button > button:not(:only-child):not(:first-child):not(:last-child) {
  border-radius: 0;
}

.Y1RsFq_bubble-create-room-modal .Y1RsFq_form .Y1RsFq_grid .Y1RsFq_multi-button > button:not(:only-child):first-child {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.Y1RsFq_bubble-create-room-modal .Y1RsFq_form .Y1RsFq_grid .Y1RsFq_multi-button > button:not(:only-child):last-child {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.Y1RsFq_bubble-create-room-modal .Y1RsFq_actions {
  gap: 1rem;
  display: flex;
}

.Y1RsFq_bubble-create-room-modal .Y1RsFq_actions .Y1RsFq_cancel-button {
  color: #333;
}

.Y1RsFq_bubble-create-room-modal .Y1RsFq_actions .Y1RsFq_cancel-button:hover:not(:disabled), .Y1RsFq_bubble-create-room-modal .Y1RsFq_actions .Y1RsFq_cancel-button.Y1RsFq_selected {
  background-color: #3331 !important;
}

.Y1RsFq_bubble-create-room-modal .Y1RsFq_actions .Y1RsFq_create-room-button {
  flex: 1;
}

._8GjMhW_bubble-lobby {
  width: 100%;
  height: 100%;
  -webkit-backdrop-filter: blur(.5rem);
  backdrop-filter: blur(.5rem);
  box-sizing: border-box;
  color: #333;
  background: #fff8;
  gap: 1rem;
  padding: 1rem;
  display: flex;
  position: absolute;
}

._8GjMhW_bubble-lobby ._8GjMhW_room-list {
  flex-direction: column;
  flex: 1;
  gap: .5rem;
  display: flex;
}

._8GjMhW_bubble-lobby ._8GjMhW_room-list input {
  color: #666;
  background-color: #fff;
}

._8GjMhW_bubble-lobby ._8GjMhW_room-list h2 {
  text-align: center;
  margin-top: 0;
  font-weight: normal;
}

._8GjMhW_bubble-lobby ._8GjMhW_room-list ._8GjMhW_status {
  text-align: center;
}

._8GjMhW_bubble-lobby ._8GjMhW_room-list ._8GjMhW_rooms {
  flex: 1;
  overflow-y: auto;
}

._8GjMhW_bubble-lobby ._8GjMhW_room-list ._8GjMhW_rooms ._8GjMhW_rooms-grid {
  grid-template-columns: 1fr auto auto auto;
  align-items: center;
  gap: .5rem;
  display: grid;
}

._8GjMhW_bubble-lobby ._8GjMhW_room-list ._8GjMhW_rooms ._8GjMhW_rooms-grid ._8GjMhW_header {
  font-style: italic;
}

._8GjMhW_bubble-lobby ._8GjMhW_room-list ._8GjMhW_actions {
  gap: 1rem;
  display: flex;
}

._8GjMhW_bubble-lobby ._8GjMhW_room-list ._8GjMhW_actions ._8GjMhW_exit-button {
  color: #333;
}

._8GjMhW_bubble-lobby ._8GjMhW_room-list ._8GjMhW_actions ._8GjMhW_exit-button:hover:not(:disabled), ._8GjMhW_bubble-lobby ._8GjMhW_room-list ._8GjMhW_actions ._8GjMhW_exit-button._8GjMhW_selected {
  background-color: #3331 !important;
}

._8GjMhW_bubble-lobby ._8GjMhW_room-list ._8GjMhW_actions ._8GjMhW_create-room-button {
  flex: 1;
}

.VE809G_bubble-room-details {
  flex-direction: column;
  flex: 1;
  gap: 1rem;
  display: flex;
}

.VE809G_bubble-room-details .VE809G_room-info {
  color: #fff;
  background-color: #512da8;
  border-radius: .5rem;
  justify-content: space-between;
  padding: .5rem;
  display: flex;
}

.VE809G_bubble-room-details .VE809G_players {
  flex-direction: column;
  flex: 1;
  display: flex;
  overflow-y: auto;
}

.VE809G_bubble-room-details .VE809G_players .VE809G_player {
  display: flex;
}

.VE809G_bubble-room-details .VE809G_players .VE809G_player .VE809G_player-name {
  flex: 1;
}

.VE809G_bubble-room-details .VE809G_timer {
  text-align: center;
}

.VE809G_bubble-room-details .VE809G_actions {
  gap: 1rem;
  display: flex;
}

.VE809G_bubble-room-details .VE809G_actions .VE809G_exit-button {
  color: #333;
}

.VE809G_bubble-room-details .VE809G_actions .VE809G_exit-button:hover:not(:disabled), .VE809G_bubble-room-details .VE809G_actions .VE809G_exit-button.VE809G_selected {
  background-color: #3331 !important;
}

.VE809G_bubble-room-details .VE809G_actions .VE809G_ready-button {
  flex: 1;
}

.WG1yXa_player {
  width: 100%;
  height: 100%;
  margin: auto;
  position: relative;
}

.WG1yXa_player.WG1yXa_desktop {
  max-width: max(25vw, 480px);
  width: auto;
  aspect-ratio: 960 / 1198;
}

.WG1yXa_player canvas {
  max-width: 100%;
  max-height: 100%;
  aspect-ratio: unset;
  width: 100% !important;
  height: 100% !important;
}

.WG1yXa_player .WG1yXa_screen-size {
  text-align: center;
  padding: 1rem;
}

.WG1yXa_player .WG1yXa_game {
  width: 100%;
  height: 100%;
}

.WG1yXa_player .WG1yXa_game.WG1yXa_hidden {
  visibility: hidden;
}

.a9xSlW_home {
  max-width: 1200px;
  flex-direction: column;
  flex: 1;
  gap: 1rem;
  font-family: Riffic;
  font-size: 32px;
  display: flex;
}

.a9xSlW_home .a9xSlW_banner {
  height: 20rem;
  border-radius: 1rem;
  position: relative;
  overflow: hidden;
}

.a9xSlW_home .a9xSlW_banner .a9xSlW_slide {
  width: 100%;
  height: 100%;
  opacity: 0;
  background-position: 100%;
  background-size: cover;
  animation: 1s forwards a9xSlW_appear;
}

.a9xSlW_home .a9xSlW_banner .a9xSlW_description {
  z-index: 1;
  color: #fff;
  max-width: 500px;
  height: 100%;
  box-sizing: border-box;
  text-align: center;
  opacity: 0;
  flex-direction: column;
  justify-content: end;
  gap: .5rem;
  padding: 1rem;
  animation: 1s forwards a9xSlW_appear;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}

.a9xSlW_home .a9xSlW_banner .a9xSlW_description .a9xSlW_title {
  text-shadow: 0 0 3px #000c;
  font-size: 2rem;
  font-weight: 700;
}

.a9xSlW_home .a9xSlW_banner .a9xSlW_description .a9xSlW_title img {
  height: 2rem;
  padding-right: .5rem;
}

.a9xSlW_home .a9xSlW_banner .a9xSlW_description .a9xSlW_text {
  text-shadow: 0 0 3px #000c;
  font-size: 1rem;
}

.a9xSlW_home .a9xSlW_banner .a9xSlW_description .a9xSlW_text img {
  height: .8rem;
}

@media screen and (min-width: 480px) {
  .a9xSlW_home .a9xSlW_banner .a9xSlW_description {
    text-align: left;
    justify-content: center;
    padding: 1rem 3rem;
  }

  .a9xSlW_home .a9xSlW_banner .a9xSlW_description .a9xSlW_title {
    font-size: 2.5rem;
  }

  .a9xSlW_home .a9xSlW_banner .a9xSlW_description .a9xSlW_text {
    display: block;
  }

  .a9xSlW_home .a9xSlW_banner .a9xSlW_description .a9xSlW_cta {
    max-width: 200px;
    font-weight: 700;
  }
}

.a9xSlW_home .a9xSlW_banner .a9xSlW_cta {
  background-color: #e5a413;
}

.a9xSlW_home .a9xSlW_banner .a9xSlW_cta:hover {
  background-color: #c78c0c;
}

.a9xSlW_home .a9xSlW_banner .a9xSlW_shadow {
  width: 100%;
  height: 100%;
  opacity: 0;
  background: linear-gradient(90deg, #28282899 0%, #0000 85.45%);
  animation: 1s forwards a9xSlW_appear;
  position: absolute;
  top: 0;
}

.a9xSlW_home .a9xSlW_repetitive-quest {
  opacity: 0;
  background-image: url("https://storage.googleapis.com/hub-cdn/hub/images/boat.jpeg");
  background-position: center;
  background-size: cover;
  border-radius: .5rem;
  flex-direction: column;
  font-family: Baloo Thambi;
  animation: 1s .7s forwards a9xSlW_appear;
  display: flex;
  overflow: hidden;
}

@media screen and (min-width: 768px) {
  .a9xSlW_home .a9xSlW_repetitive-quest {
    flex-direction: row;
  }
}

.a9xSlW_home .a9xSlW_repetitive-quest .a9xSlW_info {
  text-align: center;
  flex: 1;
  align-items: center;
  gap: 1rem;
  padding: 1rem;
  font-size: 1rem;
  position: relative;
}

@media screen and (min-width: 768px) {
  .a9xSlW_home .a9xSlW_repetitive-quest .a9xSlW_info {
    text-align: left;
  }
}

.a9xSlW_home .a9xSlW_repetitive-quest .a9xSlW_info .a9xSlW_name {
  z-index: 1;
  text-shadow: 0 0 3px #000c;
  font-family: Riffic;
  font-size: 1.25rem;
  position: relative;
}

.a9xSlW_home .a9xSlW_repetitive-quest .a9xSlW_info .a9xSlW_description {
  text-shadow: 0 0 3px #000c;
}

.a9xSlW_home .a9xSlW_repetitive-quest .a9xSlW_info .a9xSlW_description img {
  width: 10px;
  height: 10px;
}

.a9xSlW_home .a9xSlW_repetitive-quest .a9xSlW_info .a9xSlW_description a {
  color: #e8e8e8;
  padding-left: 1rem;
}

.a9xSlW_home .a9xSlW_repetitive-quest .a9xSlW_info .a9xSlW_details {
  flex-wrap: wrap;
  align-items: center;
  gap: .5rem;
  padding-top: 1rem;
  display: flex;
}

.a9xSlW_home .a9xSlW_repetitive-quest .a9xSlW_info .a9xSlW_details button {
  height: auto;
  background-color: #343434;
  gap: .5rem;
  padding: .25rem .5rem;
}

.a9xSlW_home .a9xSlW_repetitive-quest .a9xSlW_info .a9xSlW_details button svg {
  height: .8rem;
}

.a9xSlW_home .a9xSlW_repetitive-quest .a9xSlW_info .a9xSlW_details button:hover {
  background-color: #2f2f2f;
}

.a9xSlW_home .a9xSlW_repetitive-quest .a9xSlW_info .a9xSlW_details .a9xSlW_detail {
  white-space: nowrap;
  border-radius: .5rem;
  align-items: center;
  gap: .25rem;
  padding: .25rem .5rem;
  font-size: .75rem;
  display: flex;
}

.a9xSlW_home .a9xSlW_repetitive-quest .a9xSlW_date {
  width: auto;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  font-size: 1rem;
  display: flex;
}

.a9xSlW_home .a9xSlW_repetitive-quest .a9xSlW_date > div {
  font-size: 1rem;
}

.a9xSlW_home .a9xSlW_special-offer {
  opacity: 0;
  background: #444;
  border-radius: .5rem;
  align-items: center;
  gap: .5rem;
  padding: .5rem;
  font-family: Baloo Thambi;
  font-size: 1rem;
  animation: 1s .7s forwards a9xSlW_appear;
  display: flex;
}

.a9xSlW_home .a9xSlW_special-offer .a9xSlW_title {
  font-weight: 700;
}

.a9xSlW_home .a9xSlW_special-offer .a9xSlW_text {
  flex: 1;
}

.a9xSlW_home .a9xSlW_special-offer .a9xSlW_text img, .a9xSlW_home .a9xSlW_special-offer .a9xSlW_text svg {
  height: 10px;
}

.a9xSlW_home .a9xSlW_special-offer .a9xSlW_text a {
  color: #e8e8e8;
  padding-left: 1rem;
}

.a9xSlW_home .a9xSlW_special-offer .a9xSlW_action button {
  height: 2rem;
  background-color: #e5a413;
}

.a9xSlW_home .a9xSlW_special-offer .a9xSlW_action button:hover {
  background-color: #c78c0c;
}

.a9xSlW_home .a9xSlW_special-offer .a9xSlW_action .a9xSlW_date {
  width: auto;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: .25rem;
  font-size: 1rem;
  display: flex;
}

.a9xSlW_home .a9xSlW_special-offer .a9xSlW_action .a9xSlW_date > div {
  font-size: 1rem;
}

.a9xSlW_home .a9xSlW_have-fun {
  opacity: 0;
  align-items: center;
  gap: 1rem;
  margin-top: 1rem;
  font-size: 1.5rem;
  font-weight: 700;
  animation: 1s .7s forwards a9xSlW_appear;
  display: flex;
}

.a9xSlW_home .a9xSlW_grid {
  grid-gap: 2rem;
  width: 100%;
  height: -moz-fit-content;
  height: fit-content;
  opacity: 0;
  grid-template-columns: 1fr 1fr;
  animation: 1s .7s forwards a9xSlW_appear;
  display: grid;
}

.a9xSlW_home .a9xSlW_grid > * {
  width: 100%;
  aspect-ratio: 1 / 1;
  cursor: pointer;
  margin: 0 auto;
}

@media screen and (min-width: 768px) {
  .a9xSlW_home .a9xSlW_grid {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media screen and (min-width: 1200px) {
  .a9xSlW_home .a9xSlW_grid {
    grid-template-columns: repeat(4, 1fr);
  }
}

@keyframes a9xSlW_appear {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

._7m9WEq_twitter-link-button * {
  color: #00acee;
}

._7m9WEq_twitter-link-button:disabled * {
  color: #88deff;
}

.WfzcRq_profile {
  max-width: 1200px;
  flex-direction: column;
  flex: 1;
  gap: 1rem;
  display: flex;
}

.WfzcRq_profile .WfzcRq_settings {
  background-color: #242424;
  border-radius: .5rem;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  padding: 1rem;
  display: flex;
}

@media screen and (min-width: 768px) {
  .WfzcRq_profile .WfzcRq_settings {
    flex-direction: row;
  }
}

.WfzcRq_profile .WfzcRq_settings .WfzcRq_preview {
  line-height: 0;
  position: relative;
}

.WfzcRq_profile .WfzcRq_settings .WfzcRq_preview .WfzcRq_change {
  justify-content: center;
  align-items: center;
  display: flex;
  position: absolute;
  bottom: -.5rem;
  right: -.5rem;
}

.WfzcRq_profile .WfzcRq_settings .WfzcRq_preview .WfzcRq_change button {
  height: auto;
  background-color: #ff4e5f;
  border: 4px solid #242424;
  border-radius: 2rem;
  padding: .5rem;
}

.WfzcRq_profile .WfzcRq_settings .WfzcRq_preview img {
  max-height: 150px;
  border-radius: .5rem;
}

.WfzcRq_profile .WfzcRq_settings .WfzcRq_username {
  align-items: center;
  gap: .5rem;
  font-size: 2rem;
  font-weight: bold;
  display: flex;
}

.WfzcRq_profile .WfzcRq_settings .WfzcRq_username svg {
  cursor: pointer;
  font-size: 1rem;
}

.WfzcRq_profile .WfzcRq_settings .WfzcRq_wallet-address {
  word-break: break-all;
}

.WfzcRq_profile .WfzcRq_settings .WfzcRq_middle {
  flex-direction: column;
  flex: 1;
  display: flex;
}

.WfzcRq_profile .WfzcRq_title {
  margin-top: 1rem;
  font-size: 1.5rem;
  font-weight: bold;
}

.WfzcRq_profile .WfzcRq_twitter {
  border: 1px dashed #1d9bf0;
  border-radius: .5rem;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  padding: 1rem;
  font-size: 1rem;
  display: flex;
}

@media screen and (min-width: 768px) {
  .WfzcRq_profile .WfzcRq_twitter {
    flex-direction: row;
  }
}

.WfzcRq_profile .WfzcRq_twitter .WfzcRq_text {
  flex: 1;
}

.WfzcRq_profile .WfzcRq_stats-container {
  flex-direction: column;
  gap: 1rem;
  display: flex;
  overflow-y: auto;
}

.WfzcRq_profile .WfzcRq_stats-container .WfzcRq_grouped {
  border-radius: .5rem;
  flex-wrap: wrap;
  gap: 1rem;
  display: flex;
}

.WfzcRq_profile .WfzcRq_stats-container .WfzcRq_grouped.WfzcRq_unified-bg {
  background: #242424;
}

.WfzcRq_profile .WfzcRq_stats-container .WfzcRq_grouped .WfzcRq_stats {
  background: #242424;
  border-radius: .5rem;
  flex-direction: column;
  flex: 1;
  padding: 1rem;
  display: flex;
}

.WfzcRq_profile .WfzcRq_stats-container .WfzcRq_grouped .WfzcRq_stats .WfzcRq_name {
  opacity: .7;
  white-space: nowrap;
}

.WfzcRq_profile .WfzcRq_stats-container .WfzcRq_grouped .WfzcRq_stats .WfzcRq_value {
  align-items: center;
  gap: .5rem;
  font-size: 1.25rem;
  font-weight: bold;
  display: flex;
}

.WfzcRq_profile .WfzcRq_stats-container .WfzcRq_grouped .WfzcRq_stats .WfzcRq_value svg {
  font-size: 1rem;
}

.WfzcRq_profile .WfzcRq_stats-container .WfzcRq_grouped .WfzcRq_stats .WfzcRq_value img {
  height: 1rem;
}

.tick {
  box-sizing: border-box;
  -webkit-user-select: none;
  user-select: none;
  cursor: default;
  z-index: 1;
  line-height: 1.4;
  position: relative;
}

.tick * {
  box-sizing: inherit;
}

.tick [data-view] {
  max-width: 100%;
}

.tick span[data-view] {
  display: inline-block;
}

.tick [data-layout~="pad"] {
  margin: -.25em;
}

.tick [data-layout~="pad"] > * {
  margin: .25em;
}

.tick [data-layout~="horizontal"] {
  flex-direction: row;
  justify-content: center;
  display: flex;
}

.tick [data-layout~="horizontal"][data-layout~="baseline"] {
  align-items: baseline;
}

.tick [data-layout~="horizontal"][data-layout~="center"] {
  justify-content: center;
}

.tick [data-layout~="horizontal"][data-layout~="right"] {
  justify-content: flex-end;
}

.tick [data-layout~="horizontal"][data-layout~="left"] {
  justify-content: flex-start;
}

.tick [data-layout~="horizontal"][data-layout~="fill"], .tick [data-layout~="horizontal"][data-layout~="stretch"] {
  flex-wrap: nowrap;
  align-content: stretch;
}

.tick [data-layout~="horizontal"][data-layout~="fill"] > *, .tick [data-layout~="horizontal"][data-layout~="stretch"] > * {
  width: 100%;
  flex: 1 0 0;
}

.tick [data-layout~="horizontal"][data-layout~="multi-line"] {
  flex-wrap: wrap;
}

.tick [data-layout~="horizontal"][data-layout~="fit"] {
  white-space: nowrap;
  flex-wrap: nowrap;
  place-content: center flex-start;
  display: inline-flex;
}

.tick [data-layout~="vertical"] {
  flex-direction: column;
  align-items: center;
  display: flex;
}

.tick [data-layout~="vertical"][data-layout~="top"] {
  justify-content: flex-start;
}

.tick [data-layout~="vertical"][data-layout~="bottom"] {
  min-height: 100%;
  justify-content: flex-end;
}

.tick [data-layout~="vertical"][data-layout~="middle"] {
  min-height: 100%;
  justify-content: center;
}

.tick [data-layout~="vertical"][data-layout~="left"] {
  align-items: flex-start;
}

.tick [data-layout~="vertical"][data-layout~="right"] {
  align-items: flex-end;
}

.tick [data-layout~="vertical"][data-layout~="center"] {
  text-align: center;
}

.tick [data-layout~="vertical"][data-layout~="fill"], .tick [data-layout~="vertical"][data-layout~="stretch"] {
  min-height: 100%;
  align-items: stretch;
}

.tick [data-layout~="vertical"][data-layout~="fill"] > *, .tick [data-layout~="vertical"][data-layout~="stretch"] > * {
  flex: 1 0 0;
}

.tick [data-layout~="vertical"] > * + * {
  margin-top: .5em;
}

.tick [data-layout~="overlay"] {
  position: relative;
}

.tick [data-layout~="overlay"] > * {
  margin: 0;
}

.tick [data-layout~="overlay"][data-layout~="center"] {
  text-align: center;
}

.tick [data-layout~="overlay"][data-layout~="left"] {
  text-align: left;
}

.tick [data-layout~="overlay"][data-layout~="right"] {
  text-align: right;
}

.tick [data-layout~="overlay"] > [data-overlay="fill"], .tick [data-layout~="overlay"] > [data-overlay="stretch"] {
  position: absolute;
  inset: 0;
}

.tick [data-layout~="overlay"] > [data-overlay="center"] {
  justify-content: center;
  align-items: center;
  display: flex;
  position: absolute;
  inset: 0;
}

.tick-flip {
  text-align: center;
  position: relative;
}

.tick-flip * {
  border-radius: inherit;
  white-space: pre;
  letter-spacing: inherit;
  text-indent: inherit;
}

.tick-flip-front {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.tick-flip-back {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.tick-flip-spacer {
  visibility: hidden;
  display: block;
}

.tick-flip-shadow {
  position: absolute;
  inset: 1px;
  color: #0000 !important;
  background: none !important;
}

.tick-flip-shadow-top {
  bottom: calc(50% - 1px);
}

.tick-flip-shadow-bottom {
  top: calc(50% + 1px);
}

.tick-flip-card-shadow {
  height: .5em;
  opacity: 0;
  transform-origin: 0 100%;
  z-index: 0;
  background-color: #0000;
  border-radius: 0;
  position: absolute;
  bottom: .125em;
  left: .15em;
  right: .15em;
  box-shadow: 0 .125em .25em #00000080, 0 .125em .5em #000000bf;
}

.tick-flip-card {
  z-index: 1;
  width: 100%;
  height: 100%;
  perspective: 4em;
  position: absolute;
  top: 0;
  left: 0;
}

.tick-flip-panel-back, .tick-flip-panel-front {
  width: 100%;
  height: 51%;
  backface-visibility: hidden;
  transform-style: preserve-3d;
  position: absolute;
  left: 0;
}

.tick-flip-panel-back-text, .tick-flip-panel-front-text {
  height: 100%;
  position: absolute;
  top: 0;
  left: -1px;
  right: -1px;
  overflow: hidden;
}

.tick-flip-panel-text-wrapper {
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}

.tick-flip-panel-back-text .tick-flip-panel-text-wrapper {
  height: 200%;
  top: -100%;
}

.tick-flip-panel-front {
  transform-origin: bottom;
  z-index: 2;
  top: 0;
  box-shadow: inset 0 1px #ffffff0d;
}

.tick-flip-panel-back {
  transform-origin: top;
  z-index: 1;
  top: 50%;
  box-shadow: inset 0 -1px #0000001a;
}

.tick-flip-panel-back:after {
  z-index: 1;
  content: "";
  width: 100%;
  height: 100%;
  background-image: linear-gradient(#0000004d 1px, #00000026 0, #0000 30%);
  position: absolute;
  top: 0;
  left: 0;
}

.tick-flip-panel-back-shadow {
  z-index: 2;
}

.tick-flip-panel-back-highlight {
  z-index: 3;
}

.tick-flip-panel-back-highlight, .tick-flip-panel-back-shadow {
  border-bottom-left-radius: inherit;
  border-bottom-right-radius: inherit;
}

.tick-flip-panel-back-highlight, .tick-flip-panel-back-shadow, .tick-flip-panel-front-shadow {
  opacity: 0;
  position: absolute;
  inset: 0;
}

.tick-flip-panel-front-shadow {
  background-image: linear-gradient(0deg, #000c, #0000004d);
}

.tick-flip-panel-back-shadow {
  background-image: linear-gradient(#000000b3, #00000080);
}

.tick-flip-panel-back-highlight {
  background-image: linear-gradient(#ffffff26, #ffffff4d);
}

.tick [data-style*="shadow:inner"], .tick [data-style*="shadow:inner"] .tick-flip-card-shadow, .tick [data-style*="shadow:none"] .tick-flip-card-shadow, .tick [data-style*="shadow:none"] .tick-flip-panel-back, .tick [data-style*="shadow:none"] .tick-flip-panel-front, .tick [data-style*="shadow:none"] .tick-flip-shadow {
  box-shadow: none;
}

.tick [data-style*="shadow:none"] .tick-flip-back:after, .tick [data-style*="shadow:none"] .tick-flip-panel-back-shadow, .tick [data-style*="shadow:none"] .tick-flip-panel-back-text:after, .tick [data-style*="shadow:none"] .tick-flip-panel-front-shadow {
  background-image: none;
}

.tick [data-style*="rounded:none"] {
  border-radius: 0;
}

.tick [data-style*="rounded:panels"] .tick-flip-front, .tick [data-style*="rounded:panels"] .tick-flip-shadow-bottom {
  border-bottom-left-radius: inherit;
  border-bottom-right-radius: inherit;
}

.tick [data-style*="rounded:panels"] .tick-flip-back, .tick [data-style*="rounded:panels"] .tick-flip-panel-back:after, .tick [data-style*="rounded:panels"] .tick-flip-shadow-top {
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
}

.tick-flip {
  min-width: 1.125em;
  letter-spacing: .25em;
  text-indent: .25em;
  border-radius: .125em;
  margin-left: .0625em;
  margin-right: .0625em;
}

.tick-flip-panel {
  color: #edebeb;
  background-color: #333232;
}

.tick-flip-shadow {
  box-shadow: 0 .125em .3125em #00000040, 0 .02125em .06125em #00000040;
}

.lPIxpa_flip {
  font-size: 1.6rem;
  position: relative;
}

.lPIxpa_flip .lPIxpa_tick-label {
  font-size: 1rem;
}

.cboUfa_rev-share {
  max-width: 1200px;
  flex-direction: column;
  flex: 1;
  gap: 1rem;
  display: flex;
  position: relative;
}

.cboUfa_rev-share .cboUfa_repetitive-quests {
  flex-direction: column;
  gap: 1rem;
  display: flex;
}

@media screen and (min-width: 768px) {
  .cboUfa_rev-share .cboUfa_repetitive-quests {
    flex-direction: row;
  }
}

.cboUfa_rev-share .cboUfa_repetitive-quests .cboUfa_repetitive-quest {
  flex: 1;
}

.cboUfa_rev-share .cboUfa_repetitive-quest {
  opacity: 0;
  background-position: center;
  background-size: cover;
  border-radius: .5rem;
  flex-direction: column;
  font-family: Baloo Thambi;
  animation: 1s .5s forwards cboUfa_appear;
  display: flex;
  position: relative;
  overflow: hidden;
}

@media screen and (min-width: 768px) {
  .cboUfa_rev-share .cboUfa_repetitive-quest {
    flex-direction: row;
  }
}

.cboUfa_rev-share .cboUfa_repetitive-quest.cboUfa_daily-reward {
  background-image: url("https://storage.googleapis.com/hub-cdn/hub/images/boat.jpeg");
}

.cboUfa_rev-share .cboUfa_repetitive-quest.cboUfa_deposit-offer {
  background-image: url("https://cdn.piratesquadnft.com/games/baddie-beast/marketing/bsl.jpg");
}

.cboUfa_rev-share .cboUfa_repetitive-quest.cboUfa_deposit-offer button {
  background-color: #e5a413;
}

.cboUfa_rev-share .cboUfa_repetitive-quest.cboUfa_deposit-offer button:hover {
  background-color: #c78c0c;
}

.cboUfa_rev-share .cboUfa_repetitive-quest .cboUfa_bg {
  background-color: #fff1;
  position: absolute;
  inset: 0;
}

.cboUfa_rev-share .cboUfa_repetitive-quest .cboUfa_info {
  text-align: center;
  flex: 1;
  align-items: center;
  gap: 1rem;
  padding: 1rem;
  font-size: 1rem;
  position: relative;
}

@media screen and (min-width: 768px) {
  .cboUfa_rev-share .cboUfa_repetitive-quest .cboUfa_info {
    text-align: left;
  }
}

.cboUfa_rev-share .cboUfa_repetitive-quest .cboUfa_info .cboUfa_name {
  z-index: 1;
  text-shadow: 0 0 3px #000c;
  font-family: Riffic;
  font-size: 1.25rem;
  position: relative;
}

.cboUfa_rev-share .cboUfa_repetitive-quest .cboUfa_info .cboUfa_name img {
  height: 1rem;
}

.cboUfa_rev-share .cboUfa_repetitive-quest .cboUfa_info .cboUfa_description {
  text-shadow: 0 0 3px #000c;
}

.cboUfa_rev-share .cboUfa_repetitive-quest .cboUfa_info .cboUfa_description img {
  width: 10px;
  height: 10px;
}

.cboUfa_rev-share .cboUfa_repetitive-quest .cboUfa_info .cboUfa_description a {
  color: #e8e8e8;
  padding-left: 1rem;
}

.cboUfa_rev-share .cboUfa_repetitive-quest .cboUfa_info .cboUfa_details {
  flex-wrap: wrap;
  align-items: center;
  gap: .5rem;
  padding-top: 1rem;
  display: flex;
}

.cboUfa_rev-share .cboUfa_repetitive-quest .cboUfa_info .cboUfa_details button {
  height: auto;
  background-color: #343434;
  gap: .5rem;
  padding: .25rem .5rem;
}

.cboUfa_rev-share .cboUfa_repetitive-quest .cboUfa_info .cboUfa_details button svg {
  height: .8rem;
}

.cboUfa_rev-share .cboUfa_repetitive-quest .cboUfa_info .cboUfa_details button:hover {
  background-color: #2f2f2f;
}

.cboUfa_rev-share .cboUfa_repetitive-quest .cboUfa_info .cboUfa_details .cboUfa_detail {
  white-space: nowrap;
  border-radius: .5rem;
  align-items: center;
  gap: .25rem;
  padding: .25rem .5rem;
  font-size: .75rem;
  display: flex;
}

.cboUfa_rev-share .cboUfa_repetitive-quest .cboUfa_date {
  width: auto;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  font-size: 1rem;
  display: flex;
}

.cboUfa_rev-share .cboUfa_repetitive-quest .cboUfa_date > div {
  font-size: 1rem;
}

.cboUfa_rev-share .cboUfa_grouped {
  opacity: 0;
  border-radius: .5rem;
  flex-wrap: wrap;
  gap: 1rem;
  animation: 1s forwards cboUfa_appear;
  display: flex;
}

.cboUfa_rev-share .cboUfa_grouped.cboUfa_unified-bg {
  background: #242424;
}

.cboUfa_rev-share .cboUfa_grouped .cboUfa_stats {
  background: #242424;
  border-radius: .5rem;
  flex-direction: column;
  flex: 1;
  padding: 1rem;
  display: flex;
}

.cboUfa_rev-share .cboUfa_grouped .cboUfa_stats .cboUfa_name {
  white-space: nowrap;
  align-items: center;
  gap: .5rem;
  display: flex;
}

.cboUfa_rev-share .cboUfa_grouped .cboUfa_stats .cboUfa_name button {
  height: auto;
  padding: 0 .5rem;
}

.cboUfa_rev-share .cboUfa_grouped .cboUfa_stats .cboUfa_value {
  align-items: center;
  gap: .5rem;
  font-size: 1.25rem;
  font-weight: bold;
  display: flex;
}

.cboUfa_rev-share .cboUfa_grouped .cboUfa_stats .cboUfa_value.cboUfa_flip > div {
  margin: 0 -.5rem;
  font-size: 1rem;
}

.cboUfa_rev-share .cboUfa_grouped .cboUfa_stats .cboUfa_value svg {
  font-size: 1rem;
}

.cboUfa_rev-share .cboUfa_grouped .cboUfa_stats .cboUfa_value img {
  height: 1rem;
}

.cboUfa_rev-share .cboUfa_grid {
  grid-gap: 2rem;
  width: 100%;
  min-height: 3rem;
  width: 100%;
  height: -moz-fit-content;
  height: fit-content;
  grid-template-columns: 1fr;
  display: grid;
}

.cboUfa_rev-share .cboUfa_grid > * {
  width: 100%;
  aspect-ratio: 1 / 1;
  cursor: pointer;
  margin: 0 auto;
}

@media screen and (min-width: 480px) {
  .cboUfa_rev-share .cboUfa_grid {
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  }
}

.cboUfa_rev-share .cboUfa_grid .cboUfa_nft {
  opacity: 0;
  max-width: 300px;
  justify-content: center;
  align-items: center;
  animation: 1s forwards cboUfa_appear;
  display: flex;
}

@keyframes cboUfa_appear {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.JUhIPG_daily-leaderboard-overlay {
  background-color: #3333;
  padding: 1rem;
  font-size: 1rem;
  font-family: Baloo Thambi !important;
}

.JUhIPG_daily-leaderboard-overlay .JUhIPG_daily-leaderboard {
  width: 100vw;
  height: 100vh;
  max-width: min(700px, 100vw - 2rem);
  max-height: min(500px, 100vh - 2rem);
  box-sizing: border-box;
  background-color: #2f2f2f;
  border-radius: 1rem;
  flex-direction: column;
  gap: 1rem;
  padding: 1rem;
  display: flex;
  position: relative;
  overflow: hidden;
  box-shadow: 0 8px 24px #1f1f1f;
}

.JUhIPG_daily-leaderboard-overlay .JUhIPG_daily-leaderboard:before {
  content: "";
  z-index: 1;
  width: 33%;
  height: 6px;
  background: #2ed573;
  border-radius: 6px;
  margin: auto;
  position: absolute;
  top: -3px;
  left: 0;
  right: 0;
  box-shadow: 0 -2px 8px 2px #2ed57399, 0 -4px 17px 5px #2ed5734d;
}

.JUhIPG_daily-leaderboard-overlay .JUhIPG_daily-leaderboard .JUhIPG_title {
  text-align: center;
  padding: 0 1rem;
  font-family: Riffic;
  font-size: 1.5rem;
  position: relative;
}

.JUhIPG_daily-leaderboard-overlay .JUhIPG_daily-leaderboard .JUhIPG_title .JUhIPG_close-button {
  height: 100%;
  padding: 0;
  font-size: 1.5rem;
  position: absolute;
  top: 0;
  right: 0;
}

.JUhIPG_daily-leaderboard-overlay .JUhIPG_daily-leaderboard .JUhIPG_countdown {
  visibility: hidden;
  justify-content: center;
  align-items: center;
  display: flex;
}

.JUhIPG_daily-leaderboard-overlay .JUhIPG_daily-leaderboard .JUhIPG_countdown.JUhIPG_visible {
  visibility: visible;
}

.JUhIPG_daily-leaderboard-overlay .JUhIPG_daily-leaderboard .JUhIPG_countdown > div {
  max-width: 200px;
}

.JUhIPG_daily-leaderboard-overlay .JUhIPG_daily-leaderboard .JUhIPG_details {
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: .5rem;
  display: flex;
}

.JUhIPG_daily-leaderboard-overlay .JUhIPG_daily-leaderboard .JUhIPG_details img {
  width: 10px;
  height: 10px;
}

.JUhIPG_daily-leaderboard-overlay .JUhIPG_daily-leaderboard .JUhIPG_details .JUhIPG_detail {
  white-space: nowrap;
  background: #242424;
  border-radius: .5rem;
  align-items: center;
  gap: .25rem;
  padding: .25rem .5rem;
  font-size: .75rem;
  display: flex;
}

.JUhIPG_daily-leaderboard-overlay .JUhIPG_daily-leaderboard .JUhIPG_leaderboard {
  overflow-y: auto;
}

.JUhIPG_daily-leaderboard-overlay .JUhIPG_daily-leaderboard .JUhIPG_leaderboard table {
  width: 100%;
}

.JUhIPG_daily-leaderboard-overlay .JUhIPG_daily-leaderboard .JUhIPG_leaderboard table thead {
  background-color: #2f2f2f;
  position: sticky;
  top: 0;
}

.JUhIPG_daily-leaderboard-overlay .JUhIPG_daily-leaderboard .JUhIPG_leaderboard table th {
  text-align: left;
}

.JUhIPG_daily-leaderboard-overlay .JUhIPG_daily-leaderboard .JUhIPG_leaderboard table td {
  padding-bottom: .5rem;
}

.JUhIPG_daily-leaderboard-overlay .JUhIPG_daily-leaderboard .JUhIPG_leaderboard table td img {
  height: 10px;
  width: 10px;
}

.JUhIPG_daily-leaderboard-overlay .JUhIPG_daily-leaderboard .JUhIPG_leaderboard table .JUhIPG_progress {
  width: 40px;
  height: .5rem;
}

.JUhIPG_daily-leaderboard-overlay .JUhIPG_daily-leaderboard .JUhIPG_leaderboard table .JUhIPG_progress .JUhIPG_bar {
  height: 100%;
  background-color: #1e90ff;
}

.JUhIPG_daily-leaderboard-overlay .JUhIPG_daily-leaderboard .JUhIPG_leaderboard table .JUhIPG_progress .JUhIPG_bar.JUhIPG_top {
  background-color: #2ed573;
}

.JUhIPG_daily-leaderboard-overlay .JUhIPG_daily-leaderboard .JUhIPG_actions {
  margin-top: auto;
  display: flex;
}

.JUhIPG_daily-leaderboard-overlay .JUhIPG_daily-leaderboard .JUhIPG_actions .JUhIPG_refresh {
  flex: 1;
}

.JUhIPG_daily-leaderboard-overlay .JUhIPG_profile {
  cursor: pointer;
}

.JUhIPG_daily-leaderboard-overlay .JUhIPG_profile:hover {
  text-decoration: underline;
}

.b4KXTW_rewards {
  max-width: 1200px;
  opacity: 0;
  flex-direction: column;
  flex: 1;
  gap: 1rem;
  animation: 1s forwards b4KXTW_appear;
  display: flex;
  position: relative;
}

.b4KXTW_rewards .b4KXTW_deposit-offer {
  background-image: url("https://cdn.piratesquadnft.com/games/baddie-beast/marketing/bsl.jpg");
  background-position: center;
  background-size: cover;
  border-radius: .5rem;
  flex-direction: column;
  font-family: Baloo Thambi;
  display: flex;
  overflow: hidden;
}

@media screen and (min-width: 768px) {
  .b4KXTW_rewards .b4KXTW_deposit-offer {
    flex-direction: row;
  }
}

.b4KXTW_rewards .b4KXTW_deposit-offer button {
  background-color: #e5a413;
}

.b4KXTW_rewards .b4KXTW_deposit-offer button:hover {
  background-color: #c78c0c;
}

.b4KXTW_rewards .b4KXTW_deposit-offer .b4KXTW_info {
  text-align: center;
  flex: 1;
  align-items: center;
  gap: 1rem;
  padding: 1rem;
  font-size: 1rem;
  position: relative;
}

@media screen and (min-width: 768px) {
  .b4KXTW_rewards .b4KXTW_deposit-offer .b4KXTW_info {
    text-align: left;
  }
}

.b4KXTW_rewards .b4KXTW_deposit-offer .b4KXTW_info .b4KXTW_name {
  z-index: 1;
  text-shadow: 0 0 3px #000c;
  font-size: 1rem;
  font-weight: bold;
  position: relative;
}

@media screen and (min-width: 480px) {
  .b4KXTW_rewards .b4KXTW_deposit-offer .b4KXTW_info .b4KXTW_name {
    font-size: 1.25rem;
  }
}

.b4KXTW_rewards .b4KXTW_deposit-offer .b4KXTW_info .b4KXTW_name img {
  height: 1rem;
}

.b4KXTW_rewards .b4KXTW_deposit-offer .b4KXTW_info .b4KXTW_description {
  text-shadow: 0 0 3px #000c;
}

.b4KXTW_rewards .b4KXTW_deposit-offer .b4KXTW_info .b4KXTW_description img {
  width: 10px;
  height: 10px;
}

.b4KXTW_rewards .b4KXTW_deposit-offer .b4KXTW_info .b4KXTW_description a {
  color: #e8e8e8;
  padding-left: 1rem;
}

.b4KXTW_rewards .b4KXTW_deposit-offer .b4KXTW_info .b4KXTW_details {
  flex-wrap: wrap;
  align-items: center;
  gap: .5rem;
  padding-top: 1rem;
  display: flex;
}

.b4KXTW_rewards .b4KXTW_deposit-offer .b4KXTW_info .b4KXTW_details button {
  height: auto;
  background-color: #343434;
  gap: .5rem;
  padding: .25rem .5rem;
}

.b4KXTW_rewards .b4KXTW_deposit-offer .b4KXTW_info .b4KXTW_details button svg {
  height: .8rem;
}

.b4KXTW_rewards .b4KXTW_deposit-offer .b4KXTW_info .b4KXTW_details button:hover {
  background-color: #2f2f2f;
}

.b4KXTW_rewards .b4KXTW_deposit-offer .b4KXTW_info .b4KXTW_details .b4KXTW_detail {
  white-space: nowrap;
  border-radius: .5rem;
  align-items: center;
  gap: .25rem;
  padding: .25rem .5rem;
  font-size: .75rem;
  display: flex;
}

.b4KXTW_rewards .b4KXTW_deposit-offer .b4KXTW_date {
  width: auto;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  font-size: 1rem;
  display: flex;
}

.b4KXTW_rewards .b4KXTW_deposit-offer .b4KXTW_date > div {
  font-size: 1rem;
}

.b4KXTW_rewards .b4KXTW_repetitive-quests {
  grid-template-columns: 1fr;
  gap: .5rem;
  padding: 1rem 0;
  display: grid;
}

@media screen and (min-width: 768px) {
  .b4KXTW_rewards .b4KXTW_repetitive-quests {
    grid-template-columns: 1fr;
  }
}

.b4KXTW_rewards .b4KXTW_repetitive-quests img {
  height: 10px;
}

.b4KXTW_rewards .b4KXTW_repetitive-quests .b4KXTW_repetitive-quest {
  background-color: #2f2f2f;
  border-radius: .5rem;
  flex-direction: column;
  display: flex;
  overflow: hidden;
}

@media screen and (min-width: 768px) {
  .b4KXTW_rewards .b4KXTW_repetitive-quests .b4KXTW_repetitive-quest {
    flex-direction: row;
  }
}

.b4KXTW_rewards .b4KXTW_repetitive-quests .b4KXTW_repetitive-quest .b4KXTW_info {
  background-color: #3f3f3f;
  flex-direction: column;
  flex: 1;
  gap: .5rem;
  padding: 1rem;
  font-size: 1rem;
  display: flex;
  position: relative;
}

.b4KXTW_rewards .b4KXTW_repetitive-quests .b4KXTW_repetitive-quest .b4KXTW_info .b4KXTW_name {
  z-index: 1;
  font-size: 1rem;
  font-weight: bold;
  position: relative;
}

@media screen and (min-width: 480px) {
  .b4KXTW_rewards .b4KXTW_repetitive-quests .b4KXTW_repetitive-quest .b4KXTW_info .b4KXTW_name {
    font-size: 1.25rem;
  }
}

.b4KXTW_rewards .b4KXTW_repetitive-quests .b4KXTW_repetitive-quest .b4KXTW_info .b4KXTW_description .b4KXTW_tickets {
  opacity: .8;
  font-size: .85rem;
}

.b4KXTW_rewards .b4KXTW_repetitive-quests .b4KXTW_repetitive-quest .b4KXTW_info .b4KXTW_description .b4KXTW_tickets a {
  color: #e8e8e8;
}

.b4KXTW_rewards .b4KXTW_repetitive-quests .b4KXTW_repetitive-quest .b4KXTW_info .b4KXTW_details {
  flex-wrap: wrap;
  align-items: center;
  gap: .5rem;
  display: flex;
}

.b4KXTW_rewards .b4KXTW_repetitive-quests .b4KXTW_repetitive-quest .b4KXTW_info .b4KXTW_details button {
  height: auto;
  background-color: #343434;
  gap: .5rem;
  padding: .25rem .5rem;
}

.b4KXTW_rewards .b4KXTW_repetitive-quests .b4KXTW_repetitive-quest .b4KXTW_info .b4KXTW_details button svg {
  height: .8rem;
}

.b4KXTW_rewards .b4KXTW_repetitive-quests .b4KXTW_repetitive-quest .b4KXTW_info .b4KXTW_details button:hover {
  background-color: #2f2f2f;
}

.b4KXTW_rewards .b4KXTW_repetitive-quests .b4KXTW_repetitive-quest .b4KXTW_info .b4KXTW_details .b4KXTW_detail {
  white-space: nowrap;
  border-radius: .5rem;
  align-items: center;
  gap: .25rem;
  padding: .25rem .5rem;
  font-size: .75rem;
  display: flex;
}

.b4KXTW_rewards .b4KXTW_repetitive-quests .b4KXTW_repetitive-quest .b4KXTW_date {
  width: auto;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  font-size: 1rem;
  display: flex;
}

.b4KXTW_rewards .b4KXTW_progress-reward {
  background-color: #2f2f2f;
  border-radius: .5rem;
  flex-direction: column;
  gap: 1rem;
  padding: 1rem 1rem 1.5rem;
  display: flex;
  position: relative;
  overflow: hidden;
}

.b4KXTW_rewards .b4KXTW_progress-reward .b4KXTW_header {
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.b4KXTW_rewards .b4KXTW_progress-reward .b4KXTW_header .b4KXTW_name {
  font-size: 1rem;
  font-weight: bold;
}

@media screen and (min-width: 480px) {
  .b4KXTW_rewards .b4KXTW_progress-reward .b4KXTW_header .b4KXTW_name {
    font-size: 1.25rem;
  }
}

.b4KXTW_rewards .b4KXTW_progress-reward .b4KXTW_header button {
  height: auto;
  padding: 0 .5rem;
}

.b4KXTW_rewards .b4KXTW_progress-reward .b4KXTW_progress {
  align-items: center;
  gap: 1rem;
  padding-top: .5rem;
  font-size: 1rem;
  display: flex;
}

.b4KXTW_rewards .b4KXTW_progress-reward .b4KXTW_progress img, .b4KXTW_rewards .b4KXTW_progress-reward .b4KXTW_progress svg {
  height: 10px;
  margin-right: .2rem;
}

.b4KXTW_rewards .b4KXTW_progress-reward .b4KXTW_progress .b4KXTW_bar-container {
  height: 1rem;
  background-color: #242424;
  border-radius: 1rem;
  flex: 1;
  align-items: center;
  padding: 0 .2rem;
  display: flex;
  position: relative;
}

.b4KXTW_rewards .b4KXTW_progress-reward .b4KXTW_progress .b4KXTW_bar-container .b4KXTW_actual-value, .b4KXTW_rewards .b4KXTW_progress-reward .b4KXTW_progress .b4KXTW_bar-container .b4KXTW_next-value {
  text-align: center;
  font-size: .8rem;
  position: absolute;
  top: -1.3rem;
}

.b4KXTW_rewards .b4KXTW_progress-reward .b4KXTW_progress .b4KXTW_bar-container .b4KXTW_actual-value img, .b4KXTW_rewards .b4KXTW_progress-reward .b4KXTW_progress .b4KXTW_bar-container .b4KXTW_actual-value svg, .b4KXTW_rewards .b4KXTW_progress-reward .b4KXTW_progress .b4KXTW_bar-container .b4KXTW_next-value img, .b4KXTW_rewards .b4KXTW_progress-reward .b4KXTW_progress .b4KXTW_bar-container .b4KXTW_next-value svg {
  height: 8px;
}

@media screen and (min-width: 480px) {
  .b4KXTW_rewards .b4KXTW_progress-reward .b4KXTW_progress .b4KXTW_bar-container .b4KXTW_actual-value, .b4KXTW_rewards .b4KXTW_progress-reward .b4KXTW_progress .b4KXTW_bar-container .b4KXTW_next-value {
    font-size: 1rem;
  }

  .b4KXTW_rewards .b4KXTW_progress-reward .b4KXTW_progress .b4KXTW_bar-container .b4KXTW_actual-value img, .b4KXTW_rewards .b4KXTW_progress-reward .b4KXTW_progress .b4KXTW_bar-container .b4KXTW_actual-value svg, .b4KXTW_rewards .b4KXTW_progress-reward .b4KXTW_progress .b4KXTW_bar-container .b4KXTW_next-value img, .b4KXTW_rewards .b4KXTW_progress-reward .b4KXTW_progress .b4KXTW_bar-container .b4KXTW_next-value svg {
    height: 10px;
  }
}

.b4KXTW_rewards .b4KXTW_progress-reward .b4KXTW_progress .b4KXTW_bar-container .b4KXTW_next-value {
  right: 0;
}

.b4KXTW_rewards .b4KXTW_progress-reward .b4KXTW_progress .b4KXTW_bar-container .b4KXTW_top-info {
  text-align: center;
  white-space: nowrap;
  font-size: .8rem;
  position: absolute;
  top: -1.2rem;
  left: 0;
  right: 0;
}

.b4KXTW_rewards .b4KXTW_progress-reward .b4KXTW_progress .b4KXTW_bar-container .b4KXTW_top-info img, .b4KXTW_rewards .b4KXTW_progress-reward .b4KXTW_progress .b4KXTW_bar-container .b4KXTW_top-info svg {
  height: 8px;
}

.b4KXTW_rewards .b4KXTW_progress-reward .b4KXTW_progress .b4KXTW_bar-container .b4KXTW_bar {
  height: .6rem;
  width: 0;
  border-radius: .6rem;
  transition: all .5s;
  position: relative;
}

.b4KXTW_rewards .b4KXTW_progress-reward .b4KXTW_progress .b4KXTW_bar-container .b4KXTW_bar .b4KXTW_bg-container {
  width: 100%;
  height: 100%;
  border-radius: .6rem;
  overflow: hidden;
}

.b4KXTW_rewards .b4KXTW_progress-reward .b4KXTW_progress .b4KXTW_bar-container .b4KXTW_bar .b4KXTW_bg-container .b4KXTW_bg {
  width: calc(100% + 30px);
  height: 100%;
  background-image: linear-gradient(45deg, #b2a2cd 25%, #5f5370 25% 50%, #b2a2cd 50% 75%, #5f5370 75% 100%);
  background-size: 28.28px 28.28px;
  animation-name: b4KXTW_move-bg;
  animation-duration: 1.2s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}

.b4KXTW_rewards .b4KXTW_progress-reward .b4KXTW_progress .b4KXTW_bar-container .b4KXTW_bar .b4KXTW_bottom-info {
  text-align: center;
  white-space: nowrap;
  font-size: .8rem;
  position: absolute;
  bottom: -1.4rem;
  left: 0;
  right: 0;
}

.b4KXTW_rewards .b4KXTW_progress-reward .b4KXTW_progress .b4KXTW_bar-container .b4KXTW_bar .b4KXTW_bottom-info img, .b4KXTW_rewards .b4KXTW_progress-reward .b4KXTW_progress .b4KXTW_bar-container .b4KXTW_bar .b4KXTW_bottom-info svg {
  height: 8px;
}

@keyframes b4KXTW_appear {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes b4KXTW_move-bg {
  from {
    transform: translateX(-28.28px);
  }

  to {
    transform: translateX(0);
  }
}

/*# sourceMappingURL=index.3c956b3a.css.map */
